import { decodeOverviewPolylinePoints } from './map'

// Remove duplicated walk-only routes
export const filterRoutes = (data) => {
  // Routes that have only walk legs
  let walkRoutesCount = 0
  const routes = data.filter((route) => {
    // Current walk count for this route
    let walkLegCount = 0
    // Go through all the legs of a route
    for (const leg of route.legs) {
      if (leg.mode !== 'WALK') return true
      else walkLegCount = walkLegCount + 1
    }
    // If first walk only route
    if (walkRoutesCount === 0) {
      walkRoutesCount = 1
      return true
    }
    // Walk only route already added --> filter out
    return false
  })
  return routes
}

export const extractRoutePartCoords = (route) => {
  if (route && route.legs.length) {
    const parts: any = []
    route.legs.forEach(leg => {
      if (leg && leg.legGeometry && leg.legGeometry.points) {
        parts.push(decodeOverviewPolylinePoints(leg.legGeometry.points))
      }
    })
    return parts
  }
}

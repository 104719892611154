import React, { Component, useState } from 'react'
import Draggable from 'react-draggable'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

import DragIcon from '../../Assets/Icons/drag.svg'
import DragHorizontalIcon from '../../Assets/Icons/drag-horizontal.svg'

import BackIcon from '../../Assets/Icons/back.svg'
import MainMenuIcon from '../../Assets/Icons/main-menu.svg'
import LangFiIcon from '../../Assets/Icons/lang-fi.svg'
import LangEnIcon from '../../Assets/Icons/lang-en.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  container: {
    width: '7rem',
    background: Colors.appGrey,
    position: 'relative',
    paddingTop: '2.5rem',
    paddingBottom: '3rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  toggleContainer: {
    width: '2.5rem',
    background: Colors.appGrey,
    position: 'relative',
    paddingTop: '11.75rem',
    paddingBottom: '11.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContente: 'center',
    flexDirection: 'column'
  },
  navIcon: {
    height: '4.375rem',
    padding: '.5rem'
  },
  topHandle: {
    position: 'absolute',
    top: '-1.875rem',
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // cursor: 'grab'
  },
  bottomHandle: {
    position: 'absolute',
    bottom: '-1.875rem',
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // cursor: 'grab'
  },
  dragIcon: {
    height: '2rem'
  },
  dragContainer: {
    backgroundColor: Colors.appGreyDark,
    height: '3.75rem',
    width: '3.75rem',
    borderRadius: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  actionButton: {
    marginTop: '.25rem',
    marginBottom: '.25rem'
  },
  toggle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.appGrey
  },
  label: {
    marginTop: '-1rem',
    fontWeight: 800,
    fontSize: '1rem',
    color: Colors.white,
    textAlign: 'center',
    pointerEvents: 'none'
  }
}))


const NavigationBar = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const toHome = () => navigate('/')
  const toPrevious = () => navigate(-1)

  const toggleLocale = () => {
    props.setLocale(props.locale === 'fi' ? 'en' : 'fi')
  }

  const renderButtons = () => {
    return (
      <>
        <IconButton
          onClick={toPrevious}
          className={classes.actionButton}
          size='large'
        >
          <img src={BackIcon} className={classes.navIcon} alt='icon' />
        </IconButton>
        <div className={classes.label}>{t('back')}</div>
        <IconButton
          onClick={toHome}
          className={classes.actionButton}
          size='large'
        >
          <img src={MainMenuIcon} className={classes.navIcon} alt='icon' />
        </IconButton>
        <div className={classes.label}>{t('to_start')}</div>
        <IconButton
          onClick={toggleLocale}
          className={classes.actionButton}
          size='large'
        >
          {(props.locale === 'fi') ? (<img src={LangFiIcon} className={classes.navIcon} alt='icon' />) : null}
          {(props.locale === 'en') ? (<img src={LangEnIcon} className={classes.navIcon} alt='icon' />) : null}
        </IconButton>
        <div className={classes.label}>{t('language')}</div>
      </>
    )
  }

  const renderContent = () => {
    if (props.open) {
      return (
        <div className={classes.container} onTouchStart={props.onOpen}>
          {renderButtons()}
          <strong
            className={classes.topHandle}
          >
            <div className={classes.dragContainer}>
              <img src={DragIcon} className={classes.dragIcon} />
            </div>
          </strong>
          <strong
            className={classes.bottomHandle}
          >
            <div className={classes.dragContainer}>
              <img src={DragIcon} className={classes.dragIcon} />
            </div>
          </strong>
        </div>
      )
    }
    return (
      <div className={classes.toggleContainer} onClick={props.onOpen}>
      <div className={classes.toggle}>
        <img src={DragHorizontalIcon} className={classes.toggleIcon} />
      </div>
      </div>
    )
  }

  return (
    <Draggable
      bounds="body"
      handle="strong"
      defaultPosition={{ x: 0, y: 720 }}
      axis="y"
    >
      {renderContent()}
    </Draggable>
  )
}

export default NavigationBar

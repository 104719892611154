import makeStyles from '@mui/styles/makeStyles'
import { chunk } from 'lodash'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppHeader, AppContent, Button, TipListItem, Modal, SelectList } from '../Components'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
  },
  row: {
    padding: '0 1rem 1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    padding: '1rem',
    flex: 1
  },
  header: {
    padding: '1rem 2rem 2rem'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '3.125rem'
  },
  description: {
    fontSize: '2rem',
    color: Colors.content
  },
  buttonsRow: {
    paddingTop: '2rem',
    display: 'flex',
    flexDirection: 'row'
  },
  leftButton: {
    flex: 1,
    paddingRight: '1rem'
  },
  rightButton: {
    flex: 1,
    paddingLeft: '1rem'
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '8rem',
    paddingBottom: '2rem'
  }
}))

function TipsScreen() {
  const classes = useStyles()
  const { eventStore, appStore } = useStore()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [filtersVisible, setFiltersVisible] = useState(false)
  const [activeCategories, setActiveCategories] = useState([])

  const toggleFiltersVisible = () => setFiltersVisible(!filtersVisible)

  const toMap = () => null

  const clearFilters = () => {
    setActiveCategories([])
    setFiltersVisible(false)
  }

  useEffect(() => {
    eventStore.getTips(appStore.locale)
  }, [])

  const openItem = (item) => {
    eventStore.setTip(item)
    navigate(`/tips/${item.id}`)
  }

  const getItems = () => {
    let items = eventStore.tips

    if (activeCategories.length) {
      items = items.filter(item => {
        const cats = (item.categories || []).map(c => c.value)
        for (const activeCat of activeCategories) {
          if (cats.includes(activeCat)) return true
        }
        return false
      })
    }

    return items
  }

  const getCategories = () => (eventStore.eventCategories || []).map(cat => {
    return {
      name: cat.label,
      value: cat.value
    }
  })

  const renderList = () => {
    const chunks: any = chunk(getItems(), 2)

    return chunks.map((items, chunkIndex) => (
      <div className={classes.row} key={chunkIndex}>
        {items.map(item => (
          <React.Fragment key={item.id}>
            <div className={classes.column}>
              <TipListItem
                item={item}
                onOpen={() => openItem(item)}
              />
            </div>
            {chunkIndex === chunks.length - 1 && items.length === 1 && (
              <div className={classes.column} />
            )}
          </React.Fragment>
        ))}
      </div>
    ))
  }

  const renderFilters = () => {
    return (
      <Modal
        title={t('filter_search_results')}
        onCancel={clearFilters}
        onOk={toggleFiltersVisible}
        okText={t('view_results')}
        open={filtersVisible}
      >
        <SelectList
          items={getCategories()}
          values={activeCategories}
          onChange={setActiveCategories}
        />
      </Modal>
    )
  }


  const filterCountSuffix = activeCategories.length ? `(${activeCategories.length})` : ''

  return (
    <div className={classes.container}>
      <AppHeader title={t('tips')} />
      <AppContent>
        <div className={classes.header}>
          <h1 className={classes.title}>{t('tips_for_the_traveler')}</h1>
          <p className={classes.description}>{t('lorem_ipsum')}</p>
          {/*
          <div className={classes.buttonsRow}>
            <div className={classes.leftButton}>
              <Button
                text={`${t('action_filter')} ${filterCountSuffix}`.trim()}
                onClick={toggleFiltersVisible}
                outlined
                fullWidth
              />
            </div>
            <div className={classes.rightButton}>
              <Button
                text={t('view_on_map')}
                onClick={toMap}
                outlined
                fullWidth
              />
            </div>
          </div> */}
        </div>

        {renderList()}
        {renderFilters()}
      </AppContent>
    </div>
  )
}

export default observer(TipsScreen)

import React from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ButtonBase from '@mui/material/ButtonBase'
import makeStyles from '@mui/styles/makeStyles'
import { Button } from '../../Components'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles(() => ({
  paper: {
    position: 'relative',
    background: Colors.black,
    color: Colors.white,
    maxWidth: 'calc(67.5rem - 6rem)'
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: 'blur(10px)',
    background: 'rgba(38, 50, 56, .4)',
    zIndex: 999
  },
  outerContainer: {
    position: 'relative',
    alignSelf: 'center',
    padding: '4.5rem 0rem',
    zIndex: 999999
  },
  dialogContent: {
    alignSelf: 'center',
    width: '80vw',
    background: Colors.black,
    maxWidth: 'calc(67.5rem - 6rem)',
    padding: '1.25rem 4.5rem'
  },
  title: {
    margin: 0,
    fontSize: '2.25rem',
    fontWeight: 800,
    color: Colors.white,
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: '2rem',
    paddingBottom: '0.5rem'
  },
  infoText: {
    color: Colors.white70,
    fontSize: '2rem',
    textAlign: 'center',
    padding: '0 1rem 1rem'
  },
  codeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.appGreyDark,
    borderRadius: '0.625rem',
    padding: '3.25rem',
    marginTop: '1.5rem',
    width: '100%',
    height: '100%'
  },
  codeInnerContainer: {
    background: Colors.white,
    borderRadius: '0.625rem',
    width: '100%',
    height: '100%',
    padding: '3rem'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '4.5rem',
    width: '10rem',
    backgroundColor: Colors.black,
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeButtonText: {
    fontFamily: 'Manrope',
    fontSize: '2rem',
    fontWeight: 700,
    color: Colors.white
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    marginTop: '1rem'
  }
}))

export default function QrCodeModal(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const { open, onCancel, cancelText, title, code } = props

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      classes={{ paper: classes.paper }}
      BackdropComponent={() => <div className={classes.backdrop} />}
    >
      <div className={classes.outerContainer}>
        <DialogContent className={classes.dialogContent}>
          <DialogTitle className={classes.title}>{title}</DialogTitle>
          <div className={classes.infoText}>{t('scan_qr_code_info')}</div>
          <div className={classes.codeContainer}>
            <div className={classes.codeInnerContainer}>
              <QRCode renderAs='svg' value={code} size='100%' />
            </div>
          </div>
          <DialogActions className={classes.buttonsContainer}>
            <Button
              onClick={onCancel}
              text={cancelText || t('cancel')}
              fullWidth
              secondary
            />
          </DialogActions>
        </DialogContent>
        <ButtonBase className={classes.closeButton} onClick={onCancel}>
          <p className={classes.closeButtonText}>{t('close')}</p>
        </ButtonBase>
      </div>
    </Dialog>
  )
}

import { createTheme } from '@mui/material/styles'

export enum Colors {
  black = '#000000',
  black90 = 'rgba(0, 0, 0, .9)',
  black80 = 'rgba(0, 0, 0, .8)',
  black70 = 'rgba(0, 0, 0, .7)',
  black60 = 'rgba(0, 0, 0, .6)',
  black50 = 'rgba(0, 0, 0, .5)',
  black40 = 'rgba(0, 0, 0, .4)',
  black30 = 'rgba(0, 0, 0, .3)',
  black20 = 'rgba(0, 0, 0, .2)',
  black10 = 'rgba(0, 0, 0, .1)',
  black05 = 'rgba(0, 0, 0, .05)',
  white = '#ffffff',
  white90 = 'rgba(255, 255, 255, .9)',
  white80 = 'rgba(255, 255, 255, .8)',
  white70 = 'rgba(255, 255, 255, .7)',
  white60 = 'rgba(255, 255, 255, .6)',
  white50 = 'rgba(255, 255, 255, .5)',
  white40 = 'rgba(255, 255, 255, .4)',
  white30 = 'rgba(255, 255, 255, .3)',
  white20 = 'rgba(255, 255, 255, .2)',
  white10 = 'rgba(255, 255, 255, .1)',
  white05 = 'rgba(255, 255, 255, .05)',
  yellow = '#F4D240',
  transportRed = '#A4201C',
  transportBlue = '#1A4972',
  transparent = 'transparent',
  grey = '#C1C1C1',
  green = '#3EB973',
  brightGreen = '#5DDE2F',
  red = '#FF1744',
  red80 = 'rgba(255, 23, 68, 0.8',
  darkRed = '#C14E49',
  content = '#B3B3B3',
  appGrey = '#36464F',
  appGreyLight = '#3C474C',
  appGreyDark = '#263238',
  appGreyDark50 = 'rgba(38, 50, 56, .5)',
  appGreyDarkest = '#1B2327',
  tableHeaderBackground = '#374851',
  primaryBackground = '#00417D',
  placeholder = '#f0f0f0'
}

// A custom theme for this app
const Theme = createTheme({
  palette: {
    primary: {
      main: Colors.black
    },
    secondary: {
      main: Colors.black
    },
    error: {
      main: Colors.red
    },
    background: {
      default: Colors.white
    }
  }
})

export default Theme

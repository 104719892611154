import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    // cursor: 'pointer',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    height: '28rem',
    borderRadius: '.625rem'
  },
  content: {
    flex: 1,
    padding: '1rem'
  },
  titleContainer: {
    background: 'linear-gradient(360deg, #263238 0%, rgba(38, 50, 56, 0) 100%)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '2rem'
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '1.75rem',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  subtitle: {
    fontFamily: 'Manrope',
    fontSize: '1.25rem',
    color: Colors.white70
  }
}))

export default function MajorEventProgramItem(props) {
  const { item } = props

  const classes = useStyles()

  const getPhotoStyle = () => {
    let bgStyle = {}
    if (item?.photo) {
      bgStyle = { style: { backgroundImage: `url(${item.photo})` } }
    }
    return bgStyle
  }

  const renderTime = () => {
    const startTime = item?.startDate ? moment(item?.startDate) : null
    const endTime = item?.endDate ? moment(item?.endDate) : null
    if (startTime && endTime) {
      const times = `${startTime.format('HH.mm')}-${endTime.format('HH.mm')}`
      return <p className={classes.subtitle}>{times}</p>
    }
    return null
  }

  const renderTitle = () => {
    return (
      <div className={classes.titleContainer}>
        <div className={classes.title}>{item?.title ?? ''}</div>
        {renderTime()}
      </div>
    )
  }

  return (
    <div
      className={classes.container}
      {...getPhotoStyle()}
    >
      {renderTitle()}
    </div>
  )
}

import { makeAutoObservable } from 'mobx'
import Api from '../Services/Api'
import { clearRecycleData } from '../Utils/recycle'
import get from 'lodash/get'

export default class RecycleStore {
  rootStore
  loading = false

  recycles = []
  searchedRecycles = []
  currentRecycle = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setRecycles = (recycles) => { this.recycles = recycles }
  setSearchedRecycles = (recycles) => { this.searchedRecycles = recycles }
  setCurrentRecycle = (recycle) => { this.currentRecycle = recycle }
  setLoading = (loading) => { this.loading = loading }

  async getRecycles(lat, lng) {
    this.setLoading(true)
    try {
      const response = await Api.getRecycles()
      if (response.ok && get(response, 'data.results', []).length) {
        const recycles = clearRecycleData(response.data)
        this.setRecycles(recycles)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    // cursor: 'pointer',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    height: '28rem',
    borderRadius: '.625rem'
  },
  content: {
    flex: 1,
    padding: '1rem'
  },
  category: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    backgroundColor: Colors.appGrey,
    color: Colors.white,
    fontSize: '1rem',
    borderRadius: '.3125rem',
    padding: '1rem'
  },
  titleContainer: {
    background: 'linear-gradient(360deg, #263238 0%, rgba(38, 50, 56, 0) 100%)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '2rem'
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '1.75rem',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  subtitle: {
    fontFamily: 'Manrope',
    fontSize: '1.25rem',
    color: Colors.white70
  }
}))

export default function MajorEventListItem(props) {
  const { item } = props
  const { t } = useTranslation()

  const classes = useStyles()

  const getPhotoStyle = () => {
    const photo = get(item, 'photo')
    let bgStyle = {}
    if (photo) {
      bgStyle = {
        style: {
          backgroundImage: `url(${photo})`
        }
      }
    }
    return bgStyle
  }

  const onOpen = () => props.onOpen(item)

  const renderCategory = () => {
    const category = get(item, 'categories[0].label')
    if (category) {
      return <div className={classes.category}>{category}</div>
    }
    return null
  }

  const renderTitle = () => {
    const place = get(item, 'place', '')

    const startDateFieldValue = (item?.fields || []).find(field => field.fieldKey === 'event_start_date')?.value
    const endDateFieldValue = (item?.fields || []).find(field => field.fieldKey === 'event_end_date')?.value

    if (!startDateFieldValue || !endDateFieldValue) return null

    const startDate = moment(startDateFieldValue)
    const endDate = moment(endDateFieldValue)

    let eventTime = ''

    if (startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
      // Multi day event
      eventTime = `${startDate.format('DD.MM.YYYY')} - ${endDate.format('DD.MM.YYYY')}`
    } else {
      eventTime = `${startDate.format('DD.MM.YYYY HH.mm')} - ${endDate.format('HH.mm')}`
    }

    const subtitle = `${eventTime}`

    return (
      <div className={classes.titleContainer}>
        <div className={classes.title}>{get(item, 'name')}</div>
        <div className={classes.subtitle}>{subtitle}</div>
      </div>
    )
  }

  return (
    <div
      className={classes.container}
      onClick={onOpen}
      {...getPhotoStyle()}
    >
      {renderCategory()}
      {renderTitle()}
    </div>
  )
}

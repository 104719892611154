import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { Api } from '../Services'
import { EventMainEntityType, pickJanaFieldValue } from '../Utils/jana'

export default class MajorEventStore {
  rootStore

  showModule = false
  showInfo = false
  isOngoing = false
  events = []
  ongoingEvents = []
  upcomingEvents = []
  pastEvents = []
  event = null
  feedbacks = []
  mapFilters = { markerTypes: ['event_place', 'other'] }
  instructions = null
  loading = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setShowModule = (status) => { this.showModule = status }
  setShowInfo = (status) => { this.showInfo = status }
  setIsOngoing = (status) => { this.isOngoing = status }
  setEvents = (events) => { this.events = events }
  setOngoingEvents = (events) => { this.ongoingEvents = events }
  setUpcomingEvents = (events) => { this.upcomingEvents = events }
  setPastEvents = (events) => { this.pastEvents = events }
  setEvent = (event) => { this.event = event }
  setFeedbacks = (feedbacks) => { this.feedbacks = feedbacks }
  setMapFilters = (filters) => { this.mapFilters = filters }
  setInstructions = (instructions) => { this.instructions = instructions }
  setLoading = (loading) => { this.loading = loading }
  resetMapFilters = () => { this.mapFilters = { markerTypes: ['event_place', 'other'] } }

  async getEvents() {
    this.setLoading(true)
    try {
      const locale = this.rootStore.appStore?.locale ?? 'fi'
      const response = await Api.getMainEntities(
        { mainEntityTypes: Object.values(EventMainEntityType) },
        locale
      )
      if (response.ok) {
        const events = response?.data ?? []
        const ongoingEvents = []
        const upcomingEvents = []
        const pastEvents = []
        const now = moment()
        for (const event of events) {
          const start = pickJanaFieldValue(event, 'event_start_date') ?? null
          const end = pickJanaFieldValue(event, 'event_end_date') ?? null
          if (start && end) {
            if (moment(start).isBefore(now) && moment(end).isAfter(now)) {
              ongoingEvents.push({ ...event, ongoing: true })
            } else if (moment(end).isBefore(now)) {
              pastEvents.push({ ...event, past: true })
            } else {
              upcomingEvents.push(event)
            }
          }
        }
        this.setEvents(events)
        this.setOngoingEvents(ongoingEvents)
        this.setUpcomingEvents(upcomingEvents)
        this.setPastEvents(pastEvents)

        if (this.event) {
          const event = ongoingEvents?.find((item) => item?.id === this.event?.id) ??
          pastEvents?.find((item) => item?.id === this.event?.id) ??
          upcomingEvents?.find((item) => item?.id === this.event?.id) ??
          null
          if (event) {
            this.setEvent(event)
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getEvent(id) {
    this.setLoading(true)
    try {
      const locale = this.rootStore.appStore?.locale ?? 'fi'
      const response = await Api.getMainEntity(id, locale)
      if (response.ok) {
        const event = response?.data ?? null
        this.setEvent(event)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getFeedbacks(id) {
    this.setFeedbacks([])
    try {
      const response = await Api.getMainEntityFeedbacks(id)
      if (response.ok) {
        const feedbacks = response.data
        this.setFeedbacks(feedbacks)
      }
    } catch (e) {
      console.log(e)
    }
  }


  async getInstructions() {
    this.setLoading(true)
    try {
      const locale = this.rootStore.appStore?.locale ?? 'fi'
      const response = await Api.getEntityInfos(this.event.id, { lang: locale })
      if (response.ok) {
        const instructions = response.data ?? null
        this.setInstructions(instructions)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}

import i18next from 'i18next'
import { makeAutoObservable } from 'mobx'
import Api from '../Services/Api'

export default class AppStore {
  rootStore

  notification: any = null
  notificationUpdatedAt = 0
  weather = null
  locale = 'fi'
  localeInitialized = false
  configLoading = false

  // TODO: fetch screen location from API
  location = null
  screen = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  clearNotification = () => { this.notification = null }
  setWeather = (weather) => { this.weather = weather }
  setLocation = (location) => { this.location = location }
  setScreen = (screen) => { this.screen = screen }
  setConfigLoading = (configLoading) => { this.configLoading = configLoading }

  setLocale = (locale) => {
    this.locale = locale
    this.localeInitialized = true
    i18next.changeLanguage(locale)
  }

  async getConfig(screenId) {
    this.setConfigLoading(true)
    const response = await Api.getScreen(screenId)
    if (response.ok) {
      const screen: any = response.data
      const location = {
        latitude: parseFloat(screen.latitude),
        longitude: parseFloat(screen.longitude)
      }
      this.setLocation(location)
      this.setScreen(screen)
      this.setConfigLoading(false)
    } else {
      // Error happened, refresh in 30 seconds
      setTimeout(() => window.location.reload(), 30000)
    }

  }

  async getWeather () {
    const response = await Api.getWeather()
    if (response.ok) {
      this.setWeather(response.data)
    }
  }

  async setErrorOrSuccess(response, errorKey: any = true, successKey: any = true) {
    // Update timestamp
    this.notificationUpdatedAt = Date.now()

    if (response.ok) {
      if (typeof successKey === 'string' && successKey.length) {
        this.notification = { type: 'success', text: successKey }
      } else if (successKey === true) {
        this.notification = { type: 'success', text: 'generic_success' }
      } else {
        // this.notification = null
      }
    } else {
      if (typeof errorKey === 'string' && errorKey.length) {
        this.notification = { type: 'error', text: errorKey }
      } else if (errorKey === true) {
        // Detect network errors
        if (response.problem && ['NETWORK_ERROR', 'TIMEOUT_ERROR', 'CONNECTION_ERROR'].includes(response.problem)) {
          this.notification = { type: 'error', text: 'network_error' }
        } else if (response.status === 401) {
          this.notification = { type: 'error', text: 'unauthorized_error' }
        } else if (response.status === 403) {
          this.notification = { type: 'error', text: 'forbidden_error' }
        }
        else {
          this.notification = { type: 'error', text: 'generic_error' }
        }
      } else {
        // this.notification = null
      }
    }
  }

  setError(errorKey) {
    this.setErrorOrSuccess({ ok: false }, errorKey, false)
  }

  setSuccess(successKey) {
    this.setErrorOrSuccess({ ok: true }, false, successKey)
  }
}

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '12.5rem'
  },
  infoText: {
    fontSize: '1.75rem',
    color: Colors.white
  }
}))

function DataMessage (props) {
  const classes = useStyles()
  if (!props.data || !props.data.length) {
    return (
      <div className={classes.infoContainer}>
        <p className={classes.infoText}>{props.message}</p>
      </div>
    )
  }
  return null
}

export default DataMessage

import { makeAutoObservable } from 'mobx'
import Api from '../Services/Api'
import { compareItemDistances } from '../Utils/map'

export default class CultureStore {
  rootStore
  loading = false

  sights = []
  cultureRoutes = []

  sight = null
  cultureRoute = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setSights = (sights) => { this.sights = sights }
  setCultureRoutes = (cultureRoutes) => { this.cultureRoutes = cultureRoutes }

  setSight = (sight) => { this.sight = sight }
  setCultureRoute = (sight) => { this.cultureRoute = cultureRoute }
  setLoading = (loading) => { this.loading = loading  }

  async getSights() {
    this.setLoading(true)
    const response = await Api.getSights()
    if (response.ok) {
      const sights = response.data.sort((a, b) => {
        return compareItemDistances(this.rootStore.appStore.location, a, b)
      })
      this.setSights(sights)
    }
    this.setLoading(false)
  }

  async getCultureRoutes() {
    this.setLoading(true)
    const response = await Api.getCultureRoutes()
    if (response.ok) {
      this.setCultureRoutes(response.data)
    }
    this.setLoading(false)
  }
}

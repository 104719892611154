import React from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) =>({
  outerContainer: {
    border: 'none'
  },
  container: {
    border: 'none'
  },
  cell: {
    border: 'none',
    height: '5rem',
    padding: '0rem 1rem',
    backgroundColor: Colors.tableHeaderBackground,
    color: Colors.white,
    fontFamily: 'Manrope',
    fontSize: '1.625rem',
    fontWeight: 800
  }
}))

function TableHeader (props) {
  const classes = useStyles()

  const containerClass = classes.container

  return (
    <TableHead className={classes.outerContainer}>
      <TableRow className={containerClass}>
        {props.columnNames.map((columnName, index) => {
          return <TableCell key={index} className={classes.cell}>{columnName}</TableCell>
        })}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader

import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { Colors } from '../../Utils/theme'
import ArrowRightIcon from '../../Assets/Icons/arrow-right.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.appGreyDarkest,
    fontSize: '2.5rem',
    padding: '2rem'
  },
  oddContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.appGreyDark,
    fontSize: '2.5rem',
    padding: '2rem'
  },
  lineNumber: {
    color: Colors.white,
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: 1
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '9rem'
  },
  name: {
    display: 'flex',
    alignItems: 'center'
  },
  lineNumberOuterContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '8rem'
  },
  lineNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    backgroundColor: '#515B60',
    minHeight: '5rem',
    minWidth: '5rem',
    borderRadius: '.725rem'
  },
  arrowRight: {
    width: '3.75rem',
    marginRight: '2rem'
  },
  now: {
    color: Colors.brightGreen,
    fontWeight: 600
  },
  duration: {
    fontWeight: 600
  }
}))



export default function LineItem(props) {
  const { line } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const renderTime = () => {
    let textClass = classes.time
    const time = moment().startOf('day').add(line.realtimeDeparture, 's')
    const minutesUntilDeparture = time.diff(moment(), 'minutes')
    let timeString = time.format('HH:mm')
    if (minutesUntilDeparture > -1) {
      if (minutesUntilDeparture < 2) {
        timeString = t('now')
        textClass = classes.now
      } else if (minutesUntilDeparture < 10) {
        timeString = `${minutesUntilDeparture} min`
        textClass = classes.duration
      }
    }
    return (
      <div className={classes.timeContainer}>
        <div className={textClass}>{timeString}</div>
      </div>
    )

  }



  return (
    <div className={props.odd ? classes.oddContainer : classes.container}>
      {renderTime()}
      <div className={classes.lineNumberOuterContainer}>
        <div className={classes.lineNumberContainer}>
          <div className={classes.lineNumber}>{line?.lineShortName}</div>
        </div>
      </div>
      <img src={ArrowRightIcon} className={classes.arrowRight} />
      <div className={classes.name}>{line.headsign}</div>
    </div>
  )

}


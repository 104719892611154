import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import React, { useEffect, useState } from 'react'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'
import TrainIcon from '../Assets/Icons/train.svg'
import BusIcon from '../Assets/Icons/bus.svg'
import FlightIcon from '../Assets/Icons/flight.svg'
import { useTranslation } from 'react-i18next'
import {
  AppHeader,
  TrainTable,
  BusTable,
  FlightTable,
  ToggleTabs,
  AppContent
} from '../Components'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0rem 2.5rem'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '3.125rem',
    marginBottom: '1rem'
  },
  description: {
    fontSize: '2rem',
    color: Colors.content,
    marginBottom: '3rem'
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  tab: {
    maxWidth: 'none',
    flex: 1,
    fontWeight: 600,
    color: Colors.white,
    fontSize: '2rem',
    paddingTop: '.25rem',
    paddingBottom: '.5rem',
    display: 'flex',
    flexDirection: 'row',
    opacity: 0.7,
    borderBottom: `.25rem solid ${Colors.appGrey}`,
    '&:active': {
      color: Colors.white
    }
  },
  activeTab: {
    maxWidth: 'none',
    flex: 1,
    fontWeight: 800,
    color: `${Colors.white} !important`,
    fontSize: '2rem',
    paddingTop: '.25rem',
    paddingBottom: '.5rem',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `.25rem solid ${Colors.white}`
  },
  indicator: {
    height: 0
  },
  tabIcon: {
    height: '2.75rem',
    marginRight: '1.25rem'
  },
  trainTabs: {
    marginTop: '3rem'
  },
  tableContainer: {
    marginTop: '3rem'
  }
}))

const TRAIN_DIRECTION = {
  arrival: 'ARRIVAL',
  departure: 'DEPARTURE'
}

const FLIGHT_DIRECTION = {
  arrival: 'arr',
  departure: 'dep'
}

const TAB = {
  trains: 'trains',
  buses: 'buses',
  flights: 'flights'
}

const TABS = [
  {
    icon: TrainIcon,
    name: TAB.trains,
    value: TAB.trains
  },
  {
    icon: BusIcon,
    name: TAB.buses,
    value: TAB.buses
  },
  {
    icon: FlightIcon,
    name: TAB.flights,
    value: TAB.flights
  }
]

function TransportationScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { transportationStore, appStore } = useStore()

  const getDefaultTab = () => {
    if (appStore.screen && appStore.screen.name) {
      if (appStore.screen.name.toLowerCase().includes('linja-autoasema')) {
        return TAB.buses
      }
      /*
      if (
        appStore.screen.name.toLowerCase().includes('lentoasema') ||
        appStore.screen.name.toLowerCase().includes('lentokenttä')
      ) {
        return TAB.flights
      }
      */
    }
    return TAB.trains
  }

  const [trainsType, setTrainsType] = useState(TRAIN_DIRECTION.departure)
  const [flightsType, setFlightsType] = useState(FLIGHT_DIRECTION.departure)

  const [currentTab, setCurrentTab] = useState(getDefaultTab())


  useEffect(() => {
    transportationStore.getTrains(trainsType)
    transportationStore.getBuses()
    transportationStore.getFlights(flightsType)
  }, [])

  const handleSetTrainsType = (value) => {
    transportationStore.getTrains(value)
    setTrainsType(value)
  }

  const handleSetFlightsType = (value) => {
    transportationStore.getFlights(value)
    setFlightsType(value)
  }

  const handleTabChange = (e, index) => {
    const value = TABS[index].value

    if (value === TAB.trains) {
      transportationStore.getTrains(trainsType)
    }
    if (value === TAB.buses) {
      transportationStore.getBuses()
    }
    if (value === TAB.flights) {
      transportationStore.getFlights(flightsType)
    }

    setCurrentTab(value)
  }

  const renderTabs = () => {
    return (
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        classes={{ root: classes.tabsContainer, indicator: classes.indicator }}
      >
        {
          TABS.map((tab) => (
            <Tab
              key={tab.value}
              icon={<img src={tab.icon} className={classes.tabIcon} />}
              label={t(tab.name)}
              classes={{ root: currentTab === tab.value ? classes.activeTab : classes.tab }}
            />
          ))
        }
      </Tabs>
    )
  }

  const renderTrains = () => {
    return (
      <>
        <div className={classes.trainTabs}>
          <ToggleTabs
            items={[
              {
                name: t('arrivals'),
                value: TRAIN_DIRECTION.arrival
              },
              {
                name: t('departures'),
                value: TRAIN_DIRECTION.departure
              }
            ]}
            value={trainsType}
            onChange={handleSetTrainsType}
          />
        </div>
        <div className={classes.tableContainer}>
          <TrainTable
            items={transportationStore.trains}
            type={trainsType}
            loading={transportationStore.loading}
          />
        </div>
      </>
    )
  }

  const renderBuses = () => {
    return (
      <div className={classes.tableContainer}>
        <BusTable
          items={transportationStore.buses}
          loading={transportationStore.loading}
        />
      </div>
    )
  }

  const renderFlights = () => {
    return (
      <>
        <div className={classes.trainTabs}>
          <ToggleTabs
            items={[
              {
                name: t('arrivals'),
                value: FLIGHT_DIRECTION.arrival
              },
              {
                name: t('departures'),
                value: FLIGHT_DIRECTION.departure
              }
            ]}
            value={flightsType}
            onChange={handleSetFlightsType}
          />
        </div>
        <div className={classes.tableContainer}>
          <FlightTable
            items={transportationStore.flights}
            loading={transportationStore.loading}
            type={flightsType}
          />
        </div>
      </>
    )
  }

  const renderContent = () => {
    if (currentTab === TAB.trains) return renderTrains()
    if (currentTab === TAB.buses) return renderBuses()
    if (currentTab === TAB.flights) return renderFlights()
    return null
  }

  return (
    <>
      <AppHeader title={t('long_distance_transportation')} />
      <AppContent>
        <div className={classes.container}>
          <h1 className={classes.title}>{t('long_distance_transportation_title')}</h1>
          <p className={classes.description}>{t('long_distance_transportation_info')}</p>
          {renderTabs()}
          {renderContent()}
        </div>
      </AppContent>
    </>
  )
}

export default observer(TransportationScreen)

import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContent, AppHeader, Button, QrCodeModal } from '../Components'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'
import { getMapsLink } from '../Utils/map'
import { calculateDistance } from '../Utils/jana'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0rem 3rem 20rem'
  },
  photo: {
    height: '56.25vw',
    maxHeight: 'calc(67.5rem * 0.5625)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '3.75rem',
    fontWeight: 800,
    color: Colors.white,
    textTransform: 'uppercase',
    margin: 0,
    marginTop: '2rem'
  },
  distance: {
    fontFamily: 'Manrope',
    fontSize: '2.5rem',
    color: Colors.white70,
    margin: 0,
    marginTop: '0.5rem',
    marginBottom: '2rem'
  },
  description: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70,
    marginBottom: '2.5rem'
  },
  smallTitle: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white,
    fontWeight: 800,
    marginTop: '2.5rem',
    marginBottom: '1rem'
  },
  text: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70
  },
  footer: {
    maxWidth: 'calc(67.5rem - 6rem)',
    position: 'absolute',
    bottom: '2.5rem',
    left: '3rem',
    right: '3rem',
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto'
  },
  leftButton: {
    flex: 1,
    marginRight: '1.5rem'
  },
  rightButton: {
    flex: 1,
    marginLeft: '1.5rem'
  }
}))

function SightScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { cultureStore, appStore } = useStore()
  const { location } = appStore

  const navigate = useNavigate()
  const [routeModalVisible, setRouteModalVisible] = useState(false)
  const toggleRouteModal = () => setRouteModalVisible(!routeModalVisible)

  const toMap = () => navigate('/sights-map')

  const getLocalized = (field) => {
    const locale = appStore.locale
    if (locale === 'en') {
      if (field && field.en) {
        return field.en
      }
    }
    if (field && field.fi) {
      return field.fi
    }
    return null
  }

  const renderPhoto = () => {
    const photo = cultureStore?.sight?.image
    if (photo) {
      return (
        <div
          className={classes.photo}
          style={{ backgroundImage: `url(${photo})` }}
        />
      )
    }
  }

  const renderDistance = () => {
    if (location) {
      const coords = {
        latitude: cultureStore?.sight?.coordinates?.latitude,
        longitude: cultureStore?.sight?.coordinates?.longitude
      }
      if (coords) {
        const distance: any = calculateDistance(location, coords)
        if (distance || distance === 0) {
          return (
            <p className={classes.distance}>
              {t('distance')} {distance > 0 ? distance.toFixed(1).replace('.', ',') : 0}km
            </p>
          )
        }
      }
    }
    return null
  }

  const renderAddress = () => {
    const address = cultureStore?.sight?.address
    if (address) {
      return (
        <div>
          <div className={classes.smallTitle}>{t('address')}</div>
          <div className={classes.text}>{address}</div>
        </div>
      )
    }
    return null
  }

  const renderContent = () => {
    const title = getLocalized(cultureStore?.sight?.title)
    const description = getLocalized(cultureStore?.sight?.description)

    return (
      <div>
        {renderPhoto()}
        <div className={classes.content}>
          <h1 className={classes.title}>{title}</h1>
          {renderDistance()}
          <div className={classes.description}>{description}</div>
          {renderAddress()}
        </div>
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <div className={classes.footer}>
        <div className={classes.leftButton}>
          <Button
            text={t('download_route')}
            onClick={toggleRouteModal}
            outlined
            fullWidth
          />
        </div>
        <div className={classes.rightButton}>
          <Button
            text={t('view_on_map')}
            onClick={toMap}
            outlined
            fullWidth
          />
        </div>
      </div>
    )
  }

  const renderRouteModal = () => {
    const latitude = cultureStore?.sight?.coordinates?.latitude
    const longitude = cultureStore?.sight?.coordinates?.longitude
    const code = getMapsLink(latitude, longitude)
    if (code) {
      return (
        <QrCodeModal
          title={t('download_route_to_your_phone')}
          onCancel={toggleRouteModal}
          open={routeModalVisible}
          code={code}
        />
      )
    }
    return null
  }

  return (
    <div>
      <AppHeader title={t('sights')} />
      <AppContent>
        {renderContent()}
        {renderFooter()}
        {renderRouteModal()}
      </AppContent>
    </div>
  )
}

export default observer(SightScreen)

import haversine from 'haversine'
import _ from 'lodash'

export const getRecyclesDistance = (recycle, location) => {
  const start = { latitude: location.lat, longitude: location.lng }
  const end = {
    latitude: parseFloat(recycle.geometry.coordinates[1]),
    longitude: parseFloat(recycle.geometry.coordinates[0])
  }
  return haversine(start, end)
}

export const getRecyclesInDistance = (recycles, location) => {
  const options = { threshold: 2, unit: 'km' }
  return recycles.filter(recycle => {
    const coordinates = {
      latitude: parseFloat(recycle.geometry.coordinates[1]),
      longitude: parseFloat(recycle.geometry.coordinates[0])
    }
    return haversine(location, coordinates, options)
  })
}

export const sortRecyclesByDistance = (recycles, location) => {
  return [...recycles].sort((recycleA, recycleB) => {
    let distanceA = getRecyclesDistance(recycleA, location)
    let distanceB = getRecyclesDistance(recycleB, location)

    // Sort null values at the end of list.
    // Earth circumference is 40075 km so 25000 km is more than enougt when comparing distances
    if (distanceA === null) distanceA = 25000
    if (distanceB === null) distanceB = 25000

    return distanceA - distanceB
  })
}

// Get recycle spots by search word
export const getSearchedRecycles = (recycles, search) => {
  const fields = ['name', 'operator', 'address', 'municipality']
  return findFromObject(recycles, search, fields)
}

const findFromObject = (object, search, searchFields) =>
  object.filter(key =>
    // If some of the object's fields include search-string include it
    searchFields.some(field =>
      key[field].toLowerCase().includes(search.trim().toLowerCase())))

const filterCategories = (recycles, filters) => {
  return recycles.filter(recycle => {
    return _.some(recycle.materials.map(material => material.name), (material) => filters.tag.includes(material))
  })
}

// Apply filters to recycle spots
export const filteredList = (recycles, filters) => {
  // No recycles
  if (!recycles) return []
  // No filters --> return all
  if (!filters.tag) return recycles
  // Filter recycles with selected filters
  return filterCategories(recycles, filters)
}

// Keep only attributes listed in dataModel
export const clearRecycleData = (data) => {
  const dataModel = [
    'spot_id',
    'name',
    'operator',
    'address',
    'contact_info',
    'geometry.coordinates',
    'materials',
    'municipality',
    'opening_hours',
    'description',
    'postal_code',
    'occupied'
  ]
  // Create a object with keys included in dataModel and pick only them
  // from input data
  const clearData = (item, dataModel) => _.pick(item, _.keys(
    dataModel.reduce((obj, key) => ({ ...obj, [key]: null }), {}))
  )
  // Clear each item from data
  return data.results.map((item) => clearData(item, dataModel))
}

// Recycle material name-icon mappings
export const RecycleIconsMapping = {
  Lasi: 'glass-waste',
  Metalli: 'small-metal-waste',
  Kartonki: 'carton-waste',
  Paperi: 'paper-waste',
  Tekstiili: 'textile-waste',
  Paristot: 'battery-cell-waste',
  Lamput: 'lightbulb-waste',
  Muovi: 'plastic-waste',
  Pahvi: 'cardboard-waste',
  Puu: 'wood-waste',
  Akut: 'battery-waste',
  Sekajäte: 'mixed-waste',
  Energiajäte: 'energy-waste',
  'Rakennus- ja purkujäte': 'construction-waste',
  'Kyllästetty puu': 'light-wood-waste',
  'Vaarallinen jäte': 'dangerous-waste',
  'Sähkölaitteet (SER)': 'electric-waste',
  'Bio- ja puutarhajäte': 'bio-waste',
  'Muu jäte': 'trash'
}

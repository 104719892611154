import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import get from 'lodash/get'
import { AppContent, AppHeader } from '../Components'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0rem 3rem 20rem'
  },
  photo: {
    height: '56.25vw',
    maxHeight: 'calc(67.5rem * 0.5625)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '3.75rem',
    fontWeight: 800,
    color: Colors.white,
    textTransform: 'uppercase',
    margin: 0,
    marginTop: '2rem'
  },
  subtitle: {
    fontFamily: 'Manrope',
    fontSize: '2.5rem',
    color: Colors.white70,
    margin: 0,
    marginTop: '0.5rem',
    marginBottom: '2rem'
  },
  description: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70,
    marginBottom: '2.5rem'
  },
  smallTitle: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white,
    fontWeight: 800,
    marginTop: '2.5rem',
    marginBottom: '1rem'
  },
  text: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70
  },
  category: {
    display: 'inline-block',
    backgroundColor: Colors.appGrey,
    color: Colors.white,
    fontSize: '1.875rem',
    borderRadius: '.625rem',
    padding: '.75rem 1.125rem',
    marginTop: '0.5rem'
  }
}))

function EventScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { eventStore, appStore } = useStore()

  const renderPhoto = () => {
    const photo = eventStore?.event?.image
    if (photo) {
      return (
        <div
          className={classes.photo}
          style={{ backgroundImage: `url(${photo})` }}
        />
      )
    }
  }

  const renderDates = () => {
    const event = eventStore.event
    if (!event) return null
    let datesStr = ''
    if (moment(event.start_time).format('YYYY-MM-DD') === moment(event.end_time).format('YYYY-MM-DD')) {
      datesStr = `${moment(event.start_time).format('DD.MM.YYYY HH:mm')} - ${moment(event.end_time).format('HH:mm')}`
    } else {
      // Multi day event
      datesStr = `${moment(event.start_time).format('DD.MM.YYYY')} - ${moment(event.end_time).format('DD.MM.YYYY')}`
    }

    return <div className={classes.subtitle}>{datesStr}</div>
  }

  const renderPlace = () => {
    const place = eventStore?.event?.place
    if (place) {
      return (
        <div>
          <div className={classes.smallTitle}>{t('place')}</div>
          <div className={classes.text}>{place}</div>
        </div>
      )
    }
    return null
  }

  const renderCategory = () => {
    const category = get(eventStore?.event, 'categories[0].label')
    if (category) {
      return (
        <div>
          <div className={classes.smallTitle}>{t('category')}</div>
          <div className={classes.category}>{category}</div>
        </div>
      )
    }
    return null
  }

  const renderContent = () => {
    const title = eventStore?.event?.name
    const description = eventStore?.event?.description
    return (
      <div>
        {renderPhoto()}
        <div className={classes.content}>
          <h1 className={classes.title}>{title}</h1>
          {renderDates()}
          <div className={classes.description}>{description}</div>
          {renderPlace()}
          {renderCategory()}
        </div>
      </div>
    )
  }

  return (
    <div>
      <AppHeader title={t('event_calendar')} />
      <AppContent>
        {renderContent()}
      </AppContent>
    </div>
  )
}

export default observer(EventScreen)

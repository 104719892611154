import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  currentTab: {
    textTransform: 'uppercase',
    flex: 1,
    padding: '.5rem',
    fontFamily: 'Manrope',
    fontSize: '1.5rem',
    fontWeight: 800,
    color: Colors.white,
    background: Colors.appGrey,
    border: `.25rem solid ${Colors.appGrey}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.3125rem'
  },
  tab: {
    textTransform: 'uppercase',
    // cursor: 'pointer',
    flex: 1,
    padding: '.5rem',
    fontFamily: 'Manrope',
    fontSize: '1.5rem',
    fontWeight: 800,
    color: Colors.white,
    background: 'transparent',
    border: `.25rem solid ${Colors.appGrey}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.3125rem'
  },
  spacer: {
    width: '4rem',
    height: '1rem'
  }
}))

export default function ToggleTabs(props) {
  const classes = useStyles()

  const renderTabs = () => {
    return props.items.map((item, index) => {
      return (
        <React.Fragment key={item.value}>
          <div
            onClick={() => props.onChange(item.value)}
            className={item.value === props.value ? classes.currentTab : classes.tab}
          >
            {item.name}
          </div>
          {(index < props.items.length - 1) && <div className={classes.spacer} />}
        </React.Fragment>
      )
    })
  }

  return (
    <div className={classes.container}>
      {renderTabs()}
    </div>
  )
}

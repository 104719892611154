import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import Modal from './Modal'
import ScreenshotImage from '../../Assets/Icons/app-screenshot.png'
import QRCode from 'qrcode.react'

const URL_IOS = 'https://apps.apple.com/fi/app/tampere-finland/id1451052639'
const URL_ANDROID = 'https://play.google.com/store/apps/details?id=fi.visittampere.tamperefinland'

const useStyles = makeStyles(() => ({
  title: {
    margin: 0,
    fontSize: '2.25rem',
    fontWeight: 800,
    color: Colors.white,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  footerSpacer: {
    height: '.5rem'
  },
  row: {
    padding: '1.5rem 2rem 1.5rem 2rem',
    display: 'flex',
    flexDirection: 'row'
  },
  leftColumn: {
    flex: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rightColumn: {
    paddingTop: '2rem',
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  appScreenshot: {
    width: '70%'
  },
  subTitle: {
    color: Colors.white,
    fontSize: '2rem',
    margin: '1rem 0 2.5rem'
  },
  qrCode: {
    display: 'inline-block',
    padding: '.875rem',
    backgroundColor: Colors.white
  },
  qrCodeContainer: {
    display: 'inline-block',
    padding: '.875rem',
    backgroundColor: Colors.appGreyDark
  }
}))

export default function DownloadAppModal(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Modal
      title={t('download_tampere_finland_app')}
      description={props.coupon ? t('tampere_pass_app_info') : t('tampere_finland_app_info')}
      onOk={props.onClose}
      handleClose={props.onCLose}
      onCancel={props.onClose}
      okText={t('ok')}
      open={props.open}
      hideCancel
    >
      <div>
        <div className={classes.row}>
          <div className={classes.leftColumn}>
            <img
              className={classes.appScreenshot}
              src={ScreenshotImage}
            />
          </div>
          <div className={classes.rightColumn}>
            <div className={classes.qrCodeContainer}>
              <div className={classes.qrCode}>
                <QRCode
                  renderAs='canvas'
                  value={URL_IOS}
                  size={Math.round(67.5 * 16 * 0.15)}
                />
              </div>
            </div>
            <div className={classes.subTitle}>{t('apple_app_store')}</div>
            <div className={classes.qrCodeContainer}>
              <div className={classes.qrCode}>
                <QRCode
                  renderAs='canvas'
                  value={URL_ANDROID}
                  size={Math.round(67.5 * 16 * 0.15)}
                />
              </div>
            </div>
            <div className={classes.subTitle}>{t('google_play_store')}</div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import upperFirst from 'lodash/upperFirst'
import moment from 'moment'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import ButtonBase from '@mui/material/ButtonBase'
import { Colors } from '../../Utils/theme'

const InfoTypes = {
  Description: 'description',
  OpenHours: 'open_hours',
  Price: 'price',
  ConditionReport: 'condition_report',
  SportFieldList: 'sport_field_list',
  ServicesContactInformation: 'services_contact_information',
  ServiceOpenHours: 'service_open_hours',
  Links: 'links',
  AmusementParkEquipment: 'amusement_park_equipment',
  TourPricing: 'tour_pricing',
  EquipmentAdditionalInformation: 'equipment_additional_info',
  ExceptionSchedules: 'exception_schedules',
  Faculties: 'faculties',
  Campuses: 'campuses',
  ServicePhones: 'service_phones',
  ServiceUrls: 'service_urls',
  EventServicesContactInformation: 'event_services_contact_information'
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    backgroundColor: 'rgba(0,0,0,0)',
    maxWidth: 'calc(67.5rem - 6rem)'
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: 'blur(10px)',
    background: 'rgba(38, 50, 56, .4)',
    zIndex: 999
  },
  outerContainer: {
    position: 'relative',
    alignSelf: 'center',
    padding: '4.5rem 0rem',
    zIndex: 999999
  },
  dialogContent: {
    alignSelf: 'center',
    width: '90vw',
    background: Colors.black,
    maxWidth: 'calc(67.5rem - 6rem)'
  },
  title: {
    margin: 0,
    fontSize: '2.25rem',
    fontWeight: 800,
    color: Colors.white,
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: '2rem 0rem'
  },
  description: {
    color: Colors.white70,
    fontSize: '2rem',
    textAlign: 'center',
    padding: '0 1rem 1rem'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '4.5rem',
    width: '10rem',
    backgroundColor: Colors.black,
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeButtonText: {
    fontFamily: 'Manrope',
    fontSize: '2rem',
    fontWeight: 700,
    color: Colors.white
  },
  buttonsContainer: {
    padding: '1rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  buttonSpacer: {
    height: '1rem'
  },
  footerSpacer: {
    height: '.5rem'
  },
  eventSubtitle: {
    fontFamily: 'Manrope',
    fontSize: '0.75rem',
    color: Colors.white,
    marginTop: 15,
    marginBottom: 7,
    textTransform: 'uppercase'
  },
  contentContainer: {
    flexGrow: 1,
    backgroundColor: Colors.appGreyDark50,
    borderRadius: 5,
    padding: '2rem',
    margin: '0rem 1.5rem 1.5rem 1.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5
  },
  day: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    fontWeight: 800,
    color: Colors.white
  },
  repeaterDay: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    fontWeight: 800,
    color: Colors.white
  },
  hours: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    color: Colors.white70,
    maxWidth: '75vw',
    textAlign: 'right'
  },
  repeaterHours: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    color: Colors.white70,
    maxWidth: '75vw',
    textAlign: 'right'
  },
  text: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    color: Colors.white70
  },
  repeaterKey: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    fontWeight: 800,
    color: Colors.white,
    marginBottom: '0.5rem'
  },
  repeaterText: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    color: Colors.white70,
    maxWidth: '75vw'
  },
  fieldListTitle: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    fontWeight: 800,
    color: Colors.white,
    marginBottom: '0.25rem',
    textTransform: 'uppercase'
  },
  spacer: {
    height: '1.5rem'
  },
  link: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    color: Colors.white,
    textDecorationLine: 'underline',
    textDecorationColor: Colors.white
  }
}))

export default function JanaInfoModal(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { visible, onClose, type, fields, locale } = props

  const renderHours = (hours) => {
    const openHours = hours || fields.find((field) => field.fieldKey === InfoTypes.OpenHours).value

    if (!openHours) return null

    const weekdayHours = [
      get(openHours, 'monday', [null, null]) || [null, null],
      get(openHours, 'tuesday', [null, null]) || [null, null],
      get(openHours, 'wednesday', [null, null]) || [null, null],
      get(openHours, 'thursday', [null, null]) || [null, null],
      get(openHours, 'friday', [null, null]) || [null, null]
    ]
    const weekendHours = [
      get(openHours, 'saturday', [null, null]) || [null, null],
      get(openHours, 'sunday', [null, null]) || [null, null]
    ]

    const allWeekdayHoursEqual = weekdayHours.every((value, index, array) => {
      return (value[0] && value[0] === array[0][0]) && (value[1] && value[1] === array[0][1])
    })

    if (allWeekdayHoursEqual) {
      const keys = ['on_weekdays', 'on_saturdays', 'on_sundays']
      const values = [[weekdayHours[0][0], weekdayHours[0][1]], ...weekendHours]
      return keys.map((key, index) => {
        const opens = values[index][0]
        const closes = values[index][1]
        return (
          <div key={key} className={classes.row}>
            <p className={hours ? classes.repeaterDay : classes.day}>{t(key)}</p>
            <p className={hours ? classes.repeaterHours : classes.hours}>
              {opens && closes ? `${opens.split(':').join('.')} - ${closes.split(':').join('.')}` : t('closed_alt')}
            </p>
          </div>
        )
      })
    } else {
      const keys = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      const values = [...weekdayHours, ...weekendHours]
      return keys.map((key, index) => {
        const opens = values[index][0]
        const closes = values[index][1]
        return (
          <div key={key} className={classes.row}>
            <p className={hours ? classes.repeaterDay : classes.day}>{t(key)}</p>
            <p className={hours ? classes.repeaterHours : classes.hours}>
              {opens && closes ? `${opens.split(':').join('.')} - ${closes.split(':').join('.')}` : t('closed_alt')}
            </p>
          </div>
        )
      })
    }
  }

  const renderPrices = () => {
    const price = fields.find((field) => field.fieldKey === InfoTypes.Price)
    if (price && price.value) {
      return <p className={classes.text}>{price.value}</p>
    }
    return null
  }

  const renderConditionReport = () => {
    const report = fields.find((field) => field.fieldKey === InfoTypes.ConditionReport)
    if (report && report.value) {
      return <p className={classes.text}>{report.value}</p>
    }
    return null
  }

  const renderSportFieldList = () => {
    const fieldList = fields.find((field) => field.fieldKey === InfoTypes.SportFieldList)
    if (fieldList && fieldList.value) {
      if (fieldList.value.length) {
        return fieldList.value.map((item, index) => (
          <React.Fragment key={index}>
            <p className={classes.fieldListTitle}>{t('field')} {item.number}</p>
            <p className={classes.text}>{t('material')}: {item.material ?? item.materialFi}</p>
            <p className={classes.text}>{t('lit')}: {item.hasLights ? t('yes') : t('no')}</p>
            {index < (fieldList.value.length - 1) ? <div className={classes.spacer} /> : null}
          </React.Fragment>
        ))
      }
    }
    return null
  }

  const renderServiceContactInformationList = () => {
    const fieldList = fields.find((field) => field.fieldKey === InfoTypes.ServicesContactInformation)
    if (fieldList && fieldList.value) {
      if (fieldList.value.length) {
        return fieldList.value.map((item, index) => (
          <React.Fragment key={index}>
            <p className={classes.fieldListTitle}>{item.service ?? ''}</p>
            {
              item.phone
                ? <p className={classes.text}>{t('phone')}: {item.phone}</p>
                : null
            }
            {
              item.email
                ? <p className={classes.text}>{t('email')}: {item.email}</p>
                : null
            }
            {
              item.www
                ? <p className={classes.text}>{t('www')}: {item.www}</p>
                : null
            }
            {item.serviceHours ? <p className={classes.text}>{t('service_hours')}:{'\n'}{item.serviceHours}</p> : null}
            {index < (fieldList.value.length - 1) ? <div className={classes.spacer} /> : null}
          </React.Fragment>
        ))
      }
    }
    return null
  }

  const renderServiceOpenHoursList = () => {
    const fieldList = fields.find((field) => field.fieldKey === InfoTypes.ServiceOpenHours)
    if (fieldList && fieldList.value) {
      if (fieldList.value.length) {
        return fieldList.value.map((item, index) => (
          <React.Fragment key={index}>
            <p className={classes.fieldListTitle}>{item.service ?? ''}</p>
            {item.openHours ? renderHours(item.openHours) : null}
            {
              item.exceptionSchedules
                ? (
                  <>
                    <p className={classes.repeaterKey}>{t('exception_schedules')}:</p>
                    <p className={classes.repeaterText}>{item.exceptionSchedules}</p>
                  </>
                  )
                : null
            }
            {index < (fieldList.value.length - 1) ? <div className={classes.spacer} /> : null}
          </React.Fragment>
        ))
      }
    }
    return null
  }

  const renderAmusementParkEquipment = () => {
    const equipment = fields.find((field) => field.fieldKey === InfoTypes.AmusementParkEquipment)
    if (equipment && equipment.value) {
      return <div className={classes.description}>{equipment.value}</div>
    }
    return null
  }

  const renderEquipmentAdditionalInfo = () => {
    const info = fields.find((field) => field.fieldKey === InfoTypes.EquipmentAdditionalInformation)
    if (info && info.value) {
      return <div className={classes.description}>{info.value}</div>
    }
    return null
  }

  const renderTourPricing = () => {
    const price = fields.find((field) => field.fieldKey === InfoTypes.TourPricing)
    if (price && price.value) {
      return <p className={classes.text}>{price.value}</p>
    }
    return null
  }

  const renderExceptionSchedules = () => {
    const exceptionSchedules = fields.find((field) => field.fieldKey === InfoTypes.ExceptionSchedules)
    if (exceptionSchedules && exceptionSchedules.value) {
      return <p className={classes.text}>{exceptionSchedules.value}</p>
    }
    return null
  }

  const renderLinks = () => {
    const fieldList = fields.find((field) => field.fieldKey === InfoTypes.Links)
    if (fieldList && fieldList.value) {
      if (fieldList.value.length) {
        return fieldList.value.map((item, index) => <p className={classes.link}>{item.linkText ?? ''}</p>)
      }
    }
    return null
  }

  const renderFaculties = () => {
    const fieldList = fields.find((field) => field.fieldKey === InfoTypes.Faculties)
    if (fieldList && fieldList.value) {
      if (fieldList.value.length) {
        return fieldList.value.map((item, index) => <p className={classes.link}>{item.name ?? ''}</p>)
      }
    }
    return null
  }

  const renderCampuses = () => {
    const fieldList = fields.find((field) => field.fieldKey === InfoTypes.Campuses)
    if (fieldList && fieldList.value) {
      if (fieldList.value.length) {
        return fieldList.value.map((item, index) => <p className={classes.link}>{item.name ?? ''}</p>)
      }
    }
    return null
  }

  const renderServicePhones = () => {
    const fieldList = fields.find((field) => field.fieldKey === InfoTypes.ServicePhones)
    if (fieldList && fieldList.value) {
      if (fieldList.value.length) {
        return fieldList.value.map((item, index) => <p className={classes.link}>{item.name ?? ''}</p>)
      }
    }
    return null
  }

  const renderServiceUrls = () => {
    const fieldList = fields.find((field) => field.fieldKey === InfoTypes.ServiceUrls)
    if (fieldList && fieldList.value) {
      if (fieldList.value.length) {
        return fieldList.value.map((item, index) => <p className={classes.link}>{item.name ?? ''}</p>)
      }
    }
    return null
  }

  const renderEventOpenTimes = () => {
    if (type?.times?.length) {
      const times = orderBy((type?.times ?? []), 'startDate')
      return times.map((item, index) => {
        const opens = item?.startDate ? moment(item.startDate) : null
        const closes = item?.endDate ? moment(item.endDate) : null
        if (opens && closes) {
          return (
            <div key={index} className={classes.row}>
              <p className={classes.repeaterDay}>
                {upperFirst(opens.locale(locale).format(`dd DD.MM.YYYY`))}
              </p>
              <p className={classes.repeaterHours}>
                {opens.format('HH.mm')} - {closes.format('HH.mm')}
              </p>
            </div>
          )
        }
        return null
      })
    }
    return null
  }

  const renderContent = () => {
    if (type === InfoTypes.Description) {
      return (
        <>
          <p className={classes.title}>{t('description')}</p>
          <div className={classes.contentContainer}>
            <div className={classes.description}>{props?.description}</div>
          </div>
        </>
      )
    } else if (type?.type && type.type === InfoTypes.EventServicesContactInformation) {
      return (
        <>
          <p className={classes.title}>{type?.title ?? ''}</p>
          <div className={classes.contentContainer}>
            <div className={classes.description}>{type?.description}</div>
            {
              type?.times && type.times?.length ? (
                <>
                  <p className={classes.eventSubtitle}>{t('open_times')}</p>
                  {renderEventOpenTimes()}
                </>
              ) : null
            }
          </div>
        </>
      )
    } else if (type === InfoTypes.ConditionReport) {
      return (
        <>
          <p className={classes.title}>{t('condition_report')}</p>
          <div className={classes.contentContainer}>
            {renderConditionReport()}
          </div>
        </>
      )
    } else if (type === InfoTypes.OpenHours) {
      return (
        <>
          <p className={classes.title}>{t('open_times')}</p>
          <div className={classes.contentContainer}>
            {renderHours()}
          </div>
        </>
      )
    } else if (type === InfoTypes.Price) {
      return (
        <>
          <p className={classes.title}>{t('price_information')}</p>
          <div className={classes.contentContainer}>
            {renderPrices()}
          </div>
        </>
      )
    } else if (type === InfoTypes.SportFieldList) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.SportFieldList)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderSportFieldList()}
          </div>
        </>
      )
    } else if (type === InfoTypes.ServicesContactInformation) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.ServicesContactInformation)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderServiceContactInformationList()}
          </div>
        </>
      )
    } else if (type === InfoTypes.ServiceOpenHours) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.ServiceOpenHours)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderServiceOpenHoursList()}
          </div>
        </>
      )
    } else if (type === InfoTypes.Links) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.Links)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderLinks()}
          </div>
        </>
      )
    } else if (type === InfoTypes.AmusementParkEquipment) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.AmusementParkEquipment)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderAmusementParkEquipment()}
          </div>
        </>
      )
    } else if (type === InfoTypes.TourPricing) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.TourPricing)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderTourPricing()}
          </div>
        </>
      )
    } else if (type === InfoTypes.EquipmentAdditionalInformation) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.EquipmentAdditionalInformation)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderEquipmentAdditionalInfo()}
          </div>
        </>
      )
    } else if (type === InfoTypes.ExceptionSchedules) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.ExceptionSchedules)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderExceptionSchedules()}
          </div>
        </>
      )
    } else if (type === InfoTypes.Faculties) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.Faculties)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderFaculties()}
          </div>
        </>
      )
    } else if (type === InfoTypes.Campuses) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.Campuses)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderCampuses()}
          </div>
        </>
      )
    } else if (type === InfoTypes.ServicePhones) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.ServicePhones)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderServicePhones()}
          </div>
        </>
      )
    } else if (type === InfoTypes.ServiceUrls) {
      const fieldList = fields.find((field) => field.fieldKey === InfoTypes.ServiceUrls)
      return (
        <>
          <p className={classes.title}>{fieldList.label ?? ''}</p>
          <div className={classes.contentContainer}>
            {renderServiceUrls()}
          </div>
        </>
      )
    }
    return null
  }

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      BackdropComponent={() => <div className={classes.backdrop} />}
    >
      <div className={classes.outerContainer}>
        <DialogContent className={classes.dialogContent}>
          {renderContent()}
        </DialogContent>
        <ButtonBase className={classes.closeButton} onClick={onClose}>
          <p className={classes.closeButtonText}>{t('close')}</p>
        </ButtonBase>
      </div>
    </Dialog>
  )
}

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  closeButton: {
    // cursor: 'pointer',
    backgroundColor: Colors.black,
    color: Colors.white,
    padding: '.25rem 1rem',
    fontWeight: 700,
    fontSize: '1.875rem'
  },
  content: {
    borderTopLeftRadius: '.625rem',
    borderBottomLeftRadius: '.625rem',
    borderBottomRightRadius: '.625rem',
    backgroundColor: Colors.black,
    padding: '3.125rem'
  }
}))

export default function MapInfoCard(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div
      className={classes.container}
    >
      {props.onClose && (
        <div className={classes.header}>
          <div
            className={classes.closeButton}
            onClick={props.onClose}
          >
            {t('close')}
          </div>
        </div>
      )}
      <div className={classes.content}>
        {props.children}
      </div>
    </div>
  )
}

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import WalkerIcon from '../../Assets/Icons/walker.svg'
import TripArrowIcon from '../../Assets/Icons/trip-arrow.svg'
import TramCircleIcon from '../../Assets/Icons/tram-circle.svg'
import BusCircleIcon from '../../Assets/Icons/bus-circle.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '.625rem',
    padding: '1.5rem 2.5rem 2.5rem',
    backgroundColor: Colors.appGreyDark
  },
  route: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  trip: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  time: {
    fontWeight: 700,
    fontSize: '2.25rem',
    color: Colors.white,
    marginRight: '1.5rem',
    marginBottom: '.45rem',
    marginTop: '.45rem'
  },
  address: {
    // 1 row only
    color: Colors.white70,
    fontSize: '2.25rem'
  },
  pathContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '1rem',
    marginRight: '1.5rem'
  },
  tripArrow: {
    height: '6.5rem'
  },
  summary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
    marginLeft: 5
  },
  timeRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  fullTime: {
    fontSize: '2.25rem',
    color: Colors.white70,
    marginRight: '1rem'
  },
  fullTimeAmount: {
    fontSize: '2.25rem',
    color: Colors.white70
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.appGrey,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '3.125rem',
    minHeight: '3.125rem',
    marginRight: '1.25rem',
    borderRadius: '.3125rem'
  },
  line: {
    fontSize: '2rem',
    fontWeight: 700,
    color: Colors.white
  },
  plus: {
    marginRight: '1.25rem',
    fontSize: '2rem',
    fontWeight: 700,
    color: Colors.white
  },
  walkContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.appGrey,
    alignItems: 'center',
    justifyContent: 'center',
    width: '3.125rem',
    height: '3.125rem',
    borderRadius: '.3125rem'
  },
  walker: {
    // Walk icon
    height: '2.25rem'
  },
  walk: {
    marginLeft: '1rem',
    fontSize: '2rem',
    color: Colors.white
  },
  lineRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2rem'
  },
  hr: {
    margin: '.5rem 0',
    height: '1px',
    width: '5px',
    backgroundColor: Colors.white
  },
  dashed: {
    marginTop: '1rem',
    marginBottom: '2rem',
    height: 0,
    borderTop: '2px dashed rgba(255, 255, 255, .3)'
  },
  typeIcons: {
    display: 'flex',
    flexDirection: 'row'
  },
  typeIcon: {
    marginLeft: '1rem',
    height: '6.25rem'
  },
  flexSpacer: {
    flex: 1
  }
}))

export default function RouteGuideItem(props) {
  const { item } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const onClick = () => props.onClick(item)

  // Render start point (time and name)
  const renderStart = () => {
    const start = item.legs[0]
    const departureTime = moment(start.startTime).format('HH:mm')
    const name = props?.start?.name
    return (
      <div className={classes.row}>
        <div className={classes.time}>{departureTime}</div>
        <div className={classes.address}>{name}</div>
      </div>
    )
  }

  // Render destination (time and name)
  const renderEnd = () => {
    const end = item.legs[item.legs.length - 1]
    const arrivalTime = moment(end.endTime).format('HH:mm')
    const name = props?.end?.name
    return (
      <div className={classes.row}>
        <div className={classes.time}>{arrivalTime}</div>
        <div className={classes.address}>{name}</div>
      </div>
    )
  }

  // Render summary row
  const renderSummary = () => {
    // Format in minutes
    const duration = Math.round(item.walkTime / 60)
    const lines: any = []
    // Add individual bus codes
    for (const leg of item.legs) {
      if (leg.trip && leg.trip.routeShortName) {
        lines.push(leg.trip)
      }
    }
    return (
      <div className={classes.row}>
        {lines.map((line, index) => (
          <div
            key={index}
            className={classes.lineContainer}
          >
            <div className={classes.line}>
              {line.routeShortName}
            </div>
          </div>
        ))}
        {lines.length ? <div className={classes.plus}>+</div> : null}
        <div className={classes.walkContainer}>
          <img
            src={WalkerIcon}
            className={classes.walker}
          />
        </div>
        <div className={classes.walk}>{duration} {t('min')}</div>
      </div>
    )
  }

  const renderTypeIcons = () => {
    const lines: any = []
    // Add individual bus codes
    for (const leg of item.legs) {
      if (leg.trip && leg.trip.routeShortName) {
        lines.push(leg.trip)
      }
    }

    const hasTram = lines.find(line => line.route.mode !== 'BUS')
    const hasBus = lines.find(line => line.route.mode === 'BUS')
    return (
      <>
        {hasTram && (<img src={TramCircleIcon} className={classes.typeIcon} />)}
        {hasBus && (<img src={BusCircleIcon} className={classes.typeIcon} />)}
      </>
    )
  }

  return (
    <div className={classes.container} onClick={onClick}>
      <div className={classes.route}>
        <div className={classes.pathContainer}>
          <img src={TripArrowIcon} className={classes.tripArrow} />
        </div>
        <div className={classes.trip}>
          {renderStart()}
          {renderEnd()}
        </div>
        <div className={classes.flexSpacer} />
        <div className={classes.typeIcons}>
          {renderTypeIcons()}
        </div>
      </div>
      <div>
        <div className={classes.dashed} />
      </div>
      <div className={classes.summary}>
        {renderSummary()}
        <div className={classes.timeRow}>
          <div className={classes.fullTime}>{t('duration')}</div>
          <div className={classes.fullTimeAmount}>
            {Math.round(item.duration / 60)} {t('min')}
          </div>
        </div>
      </div>
    </div>
  )
}

import makeStyles from '@mui/styles/makeStyles'
import { t } from 'i18next'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppHeader, AppContent, Button, Weather, DownloadAppModal, StartButton } from '../Components'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'
import TouchIcon from '../Assets/Icons/touch.svg'
import Menu1Image from '../Assets/Icons/menu1.jpg'
import Menu2Image from '../Assets/Icons/menu2.jpg'
import Menu3Image from '../Assets/Icons/menu3.jpg'
import Menu4Image from '../Assets/Icons/menu4.jpg'
import MenuTramIcon from '../Assets/Icons/menu-tram.svg'
import MenuCalendarIcon from '../Assets/Icons/menu-calendar.svg'
import MenuTicketIcon from '../Assets/Icons/menu-ticket.svg'
import MenuGymIcon from '../Assets/Icons/menu-gym.svg'
import MenuFlightIcon from '../Assets/Icons/menu-flight.svg'
import MenuBinocularsIcon from '../Assets/Icons/menu-binoculars.svg'
import MenuSaunaIcon from '../Assets/Icons/menu-sauna.svg'
import MenuRecycleIcon from '../Assets/Icons/menu-recycle.svg'
import TamperePassiLogo from '../Assets/Icons/tampere-passi.svg'
import VisitTampereLogo from '../Assets/Icons/visit-tampere.png'
import ScreenshotImage from '../Assets/Icons/app-screenshot.png'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh'
  },
  innerRoot: {
    //backdropFilter: 'blur(4px)',
    background: 'linear-gradient(rgba(38, 50, 56, 0), rgba(38, 50, 56, 1))',
    padding: '3.5rem 5rem',
    minHeight: '100vh',
    boxSizing: 'border-box'
  },
  content: {
    padding: '0 1rem 4rem'
  },
  menuContainer: {
    borderRadius: '.625rem',
    backgroundColor: Colors.black,
    padding: '1.25rem 2.5rem 1.25rem',
    marginTop: '2.75rem',
    marginBottom: '3.25rem',
    boxShadow: '0 0 30px rgba(0, 0, 0, .7)'
  },
  groupRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1.25rem 0'
  },
  bigMenuButton: {
    background: Colors.black,
    position: 'relative',
    '&:active': {
      opacity: 0.9
    }
  },
  bigMenuButtonContent: {
    padding: '1rem',
    // cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '.3333rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(38, 50, 56, .5)',
    borderRadius: '.875rem'
  },
  bigMenuButtonText: {
    fontSize: '2rem',
    fontWeight: 800,
    lineHeight: 1.25
  },
  bigMenuButtonTextCenter: {
    fontSize: '2rem',
    fontWeight: 800,
    lineHeight: 1.25,
    textAlign: 'center'
  },
  tamperePassiLogo: {
    height: '12rem',
    marginBottom: '.125rem'
  },
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  visitTampereLogo: {
    height: '5.25rem',
    marginBottom: '.5rem'
  },
  horizontalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  screenshotImage: {
    height: '12rem',
    marginRight: '2rem'
  },
  menuImage: {
    borderRadius: '.875rem',
    height: '17.5rem',
    width: '18.75rem'
  },
  smallButtonsContainer: {
    paddingLeft: '3rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  buttonSpacer: {
    height: '2.5rem'
  },
  startLoader: {
    height: '100vh',
    background: Colors.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  langSelectContainer:{
    marginTop: '23.5vh'
  },
  langButton: {
    // cursor: 'pointer',
    margin: '2.8125rem 0',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.appGreyDark,
    borderRadius: '10rem',
    padding: '2.5rem 5rem',
    fontWeight: 700,
    fontSize: '2.625rem',
    lineHeight: 1.366,
    '&:active': {
      backgroundColor: Colors.appGrey
    }
  },
  touchIcon: {
    height: '6.95rem',
    marginRight: '3rem'
  }
}))


function StartScreen() {
  const { appStore, moveStore, mapStore } = useStore()
  const classes = useStyles()
  const navigate = useNavigate()

  const [downloadModalVisible, setDownloadModalVisible] = useState(false)

  useEffect(() => {
    appStore.getWeather()
    // Reset store states
    moveStore.resetState()
    mapStore.setDirections(null)
  }, [])

  // Language selecton
  const selectFi = () => appStore.setLocale('fi')
  const selectEn = () => appStore.setLocale('en')

  const toggleDownloadModal = () => setDownloadModalVisible(!downloadModalVisible)

  const toRotueGuide = () => navigate('/route-guide')
  const toEvents = () => navigate('/events')
  const toMajorEvents = () => navigate('/major-events')
  const toSportsVenues = () => navigate('/sports')
  const toTransportation = () => navigate('/transportation')
  const toSights = () => navigate('/attractions')
  const toSaunas = () => navigate('/saunas')
  const toRecycle = () => navigate('/recycle')
  const toTouristInfo = () => navigate('/tourist-info')
  const toOffers = () => navigate('/coupons')
  const toRestaurants = () => navigate('/restaurants')
  const toFeedback = () => navigate('/feedback')

  const getLocationName = () => {
    if (!appStore?.screen) return null
    const screenName = (appStore?.locale === 'en' ? appStore?.screen?.nameEn : appStore?.screen.name) || appStore?.screen?.name
    return screenName
  }

  if (appStore.configLoading) {
    return (
      <div className={classes.startLoader}>
        <CircularProgress color='error' size={71} />
      </div>
    )
  }

  const renderMenuContainer = () => {
    return (
      <>
        <div className={classes.menuContainer}>
          <div className={classes.groupRow}>
            <div className={classes.bigMenuButton}>
              <img src={Menu1Image} className={classes.menuImage} />
              <div className={classes.bigMenuButtonContent} onClick={toTouristInfo}>
                <div className={classes.verticalContainer}>
                  <img src={VisitTampereLogo} className={classes.visitTampereLogo} />
                  <div className={classes.bigMenuButtonTextCenter}>
                    {t('start_screen_tourist_information')}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.smallButtonsContainer}>
              <StartButton
                leftIcon={MenuTramIcon}
                text={t('route_guide')}
                onClick={toRotueGuide}
              />
              <div className={classes.buttonSpacer} />
              <StartButton
                leftIcon={MenuCalendarIcon}
                text={t('events')}
                onClick={toEvents}
              />
            </div>
          </div>
          <div className={classes.groupRow}>
            <div className={classes.bigMenuButton} onClick={toOffers}>
              <img src={Menu2Image} className={classes.menuImage} />
              <div className={classes.bigMenuButtonContent}>
                <div className={classes.verticalContainer}>
                  <img src={TamperePassiLogo} className={classes.tamperePassiLogo} />
                  <div className={classes.bigMenuButtonText}>
                    {t('offers')}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.smallButtonsContainer}>
              <StartButton
                leftIcon={MenuTicketIcon}
                text={t('major_events')}
                onClick={toMajorEvents}
              />
              <div className={classes.buttonSpacer} />
              <StartButton
                leftIcon={MenuGymIcon}
                text={t('sports_venues')}
                onClick={toSportsVenues}
              />
            </div>
          </div>
          <div className={classes.groupRow}>
            <div className={classes.bigMenuButton} onClick={toRestaurants}>
              <img src={Menu3Image} className={classes.menuImage} />
              <div className={classes.bigMenuButtonContent}>
                <div className={classes.bigMenuButtonText}>
                  {t('eat_and_drink')}
                </div>
              </div>
            </div>
            <div className={classes.smallButtonsContainer}>
              <StartButton
                leftIcon={MenuFlightIcon}
                text={t('transportation')}
                onClick={toTransportation}
              />
              <div className={classes.buttonSpacer} />
              <StartButton
                leftIcon={MenuBinocularsIcon}
                text={t('sights')}
                onClick={toSights}
              />
            </div>
          </div>
          <div className={classes.groupRow}>
            <div className={classes.bigMenuButton} onClick={toggleDownloadModal}>
              <img src={Menu4Image} className={classes.menuImage} />
              <div className={classes.bigMenuButtonContent}>
                <div className={classes.horizontalContainer}>
                  <img src={ScreenshotImage} className={classes.screenshotImage} />
                  <div className={classes.bigMenuButtonText}>
                    {t('download_app')}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.smallButtonsContainer}>
              <StartButton
                leftIcon={MenuSaunaIcon}
                text={t('sauna_capital')}
                onClick={toSaunas}
              />
              <div className={classes.buttonSpacer} />
              <StartButton
                leftIcon={MenuRecycleIcon}
                text={t('tre_recycles')}
                onClick={toRecycle}
              />
            </div>
          </div>
        </div>
        <Button
          text={t('give_feedback')}
          onClick={toFeedback}
          outlinedOpacity
          fullWidth
        />
      </>
    )
  }

  const renderLangSelect = () => {
    return (
      <div className={classes.langSelectContainer}>
        <div className={classes.langButton} onClick={selectFi}>
          <img src={TouchIcon} className={classes.touchIcon} />
          <div className={classes.langSelectText}>
            {"Paina tästä käyttääksesi näyttöä suomeksi."}
          </div>
        </div>
        <div className={classes.langButton} onClick={selectEn}>
          <img src={TouchIcon} className={classes.touchIcon} />
          <div className={classes.langSelectText}>
            {"Press here to use the screen in English."}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: 'url(/background.jpg)'
      }}
      key={appStore.locale}
    >
      <div className={classes.innerRoot}>
        <AppHeader useLogo />
        <AppContent>
          <div className={classes.content}>
            <Weather
              weather={appStore.weather}
              locationName={getLocationName()}
            />
            {!appStore.localeInitialized ? renderLangSelect() : renderMenuContainer()}
          </div>
          <DownloadAppModal
            open={downloadModalVisible}
            onClose={toggleDownloadModal}
          />
        </AppContent>
      </div>
    </div>
  )
}

export default observer(StartScreen)

import React, { useEffect } from 'react'
import { MemoryRouter } from 'react-router-dom'
import { useStore } from './Models/RootStore'
import RootContainer from './Containers'
import { observer } from 'mobx-react'

// 1 minute idle time
const IDLE_TIME = 1 * 60 * 1000 // ms
let lastClickedAt = 0

const App = () => {
  const { appStore }: any = useStore()

  useEffect(() => {
    // Detect www. in url and redirect without it
    if (window.location.hostname.indexOf('www.') === 0) {
      window.location = window.location.href.replace('www.', '')
    }
    const path = window.location.pathname
    const pathParts = path.split('/')
    if (pathParts.length > 1) {
      // Load screen config
      appStore.getConfig(pathParts[1])
    }
  }, [])

  const checkLastClickedAt = () => {
    if (lastClickedAt && Date.now() > lastClickedAt + IDLE_TIME) {
      window.location.reload()
    }
  }

  useEffect(() => {
    const timerId = setInterval(checkLastClickedAt, 2000)
    return function cleanup() {
      clearInterval(timerId)
    }
  }, [])

  // Catch all clicks and update last clicked at value for idle detection
  const handleRootClick = () => {
    lastClickedAt = Date.now()
  }

  return (
    <div onClick={handleRootClick}>
      <MemoryRouter>
        <RootContainer />
      </MemoryRouter>
    </div>
  )
}

export default observer(App)

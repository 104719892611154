import haversine from 'haversine'
import { getEntityCenterCoords } from './jana'

export const getMapsLink = (latitude, longitude) => {
  if (latitude && longitude) {
    return `http://www.google.com/maps/place/${latitude},${longitude}`
  }
  return null
}

// Decode Google Maps overview polyline points to [latitude, longitude] array
export const decodeOverviewPolylinePoints = (t, e = null) => {
  const d: any = []
  for (var n, o, u = 0, l = 0, r = 0, h = 0, i = 0, a = null, c = Math.pow(10, e || 5); u < t.length;) {
    let a = null
    let h = 0
    let i = 0

    do {
      a = t.charCodeAt(u++) - 63
      i |= (31 & a) << h
      h += 5
    } while (a >= 32);

    n = 1 & i ? ~(i >> 1) : i >> 1
    h = 0
    i = 0
    do {
      a = t.charCodeAt(u++) - 63
      i |= (31 & a) << h
      h += 5
    } while (a >= 32);

    let o = 1 & i ? ~(i >> 1) : i >> 1;
    l += n;
    r += o;
    d.push([(l / c), (r / c)])
  }
  return d.map(function(t) {
    return {
      latitude: t[0],
      longitude: t[1]
    }
  })
}


export const getDistance = (a, b) => {
  if (a && b) {
    const aLat = a?.latitude
    const aLng = a?.longitude
    const bLat = b?.latitude
    const bLng = b?.longitude
    if (aLat && aLng && bLat && bLng) {
      const distance = haversine(a, b, { unit: 'meter' })
      if (distance || distance === 0) {
        return distance
      }
    }
  }

  return null
}

export const getDistanceFormatted = (a, b) => {
  const distance = getDistance(a, b)
  if (distance !== null) {
    if (distance >= 1000) {
      // Format in kilometers
      const distanceKm = distance / 1000
      return `${distanceKm.toFixed(1).replace('.', ',')} km`
    } else {
      // Format in meters
      return `${Math.floor(distance)} m`
    }
  }
  return null
}

const getAnyItemCoordinates = (anyItem: any) => {
  let item = anyItem
  if (item.mainEntityType) {
    // Is jana item
    return getEntityCenterCoords(item)
  }

  // Coupons
  if (!item.latitude && !item.longitude && item.organization) item = item.organization

  if (item.coordinates) item = item.coordinates
  if (item.coords) item = item.coords


  if (item.lat && item.lng) {
    return {
      latitude: Number(item.lat),
      longitude: Number(item.lng)
    }
  }
  if (item.latitude && item.longitude) {
    return {
      latitude: Number(item.latitude),
      longitude: Number(item.longitude)
    }
  }
  return null
}

export const compareItemDistances = (currentLocation, itemA, itemB) => {
  if (!currentLocation) return 0
  const coordsA = getAnyItemCoordinates(itemA)
  const coordsB = getAnyItemCoordinates(itemB)
  const BIG_DISTANCE = 10000000

  const distanceA = coordsA ? haversine(currentLocation, coordsA) : BIG_DISTANCE
  const distanceB = coordsB ? haversine(currentLocation, coordsB) : BIG_DISTANCE

  return distanceA - distanceB

}

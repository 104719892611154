import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    background: Colors.appGreyDark50,
    maxHeight: '40vh',
    overflowY: 'scroll',
    padding: '1rem',
    marginTop: '0.25rem',
    marginBottom: '2rem'
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem',
    alignItems: 'center',
    // cursor: 'pointer'
  },
  activeLabel: {
    flex: 1,
    fontSize: '2.5rem',
    color: Colors.white
  },
  label: {
    flex: 1,
    fontSize: '2.5rem',
    color: Colors.white,
    opacity: 0.7
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '3rem',
    width: '3rem',
    background: Colors.appGreyDark,
    borderRadius: '.625rem'
  },
  check: {
    background: Colors.white,
    height: '1.5625rem',
    width: '1.5625rem',
    borderRadius: '.3125rem',
    opacity: 0.7
  }
}))

export default function SelectList(props) {
  const classes = useStyles()

  const toggleItem = (id) => {
    const newValues = props.values.filter(val => val !== id)
    if (newValues.length  === props.values.length) {
      newValues.push(id)
    }
    props.onChange(newValues)
  }

  const renderList = () => {
    return props.items.map((item, index) => {
      const isChecked = props.values.includes(item.value)
      return (
        <div
          onClick={() => toggleItem(item.value)}
          className={classes.listItem}
          key={item.value}
        >
          <div className={isChecked ? classes.activeLabel : classes.label}>
            {item.name}
          </div>
          <div className={classes.checkbox}>
            {isChecked && <div className={classes.check} />}
          </div>
        </div>
      )
    })
  }

  return (
    <div className={classes.container}>
      {renderList()}
    </div>
  )
}

import apisauce from 'apisauce'
import moment from 'moment'

const API_URLS = {
  Local: 'http://127.0.0.1:1337/v2/',
  Stage: 'https://treapp.stage.geniem.io/v2/',
  Production: 'https://treapp.production.geniem.io/v2/'
}

let currentApi = API_URLS.Production
if (window.location.hostname.includes('.stage.geniem.io')) currentApi = API_URLS.Stage
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) currentApi = API_URLS.Local

// const auth = (token) => ({ headers: { Authorization: `Bearer ${token}` } })

const create = (baseURL = currentApi) => {
  const api = apisauce.create({
    withCredentials: true,
    baseURL,
    timeout: 20000
  })

  const digitransitApi = apisauce.create({
    baseURL: 'https://api.digitransit.fi/routing/v1/routers/waltti/index/graphql',
    headers: {
      'digitransit-subscription-key': '09e9376cf2d9431aa7c723079151cfef'
    },
    timeout: 15000
  })

  return {
    getScreens: () => api.get('screen/screen'),
    getScreen: (id) => api.get(`screen/screen/${id}`),

    // Coupons
    getCoupons: () => api.get('screen/coupon/coupon'),
    getCouponOrganizations: () => api.get('screen/coupon/organization'),

    // Weahter
    getWeather: () => api.get('weather'),

    getRecycles: () => api.get('screen/recycle/spots'),

    // Datahub items
    getRestaurants: () => api.get('screen/datahub/items/restaurants'),
    getAttractions: () => api.get('screen/datahub/items/attractions'),

    // Transportation
    getTrains: (type) => api.get('screen/transportation/train', { type }),
    getBuses: () => api.get('screen/transportation/buses'),
    getFlights: (type) => api.get('screen/transportation/flights', { type }),

    // Visit tampere / Events
    getEvents: (locale) => api.get(`screen/event?locale=${locale}`),
    getEvent: (eventId, locale) => api.get(`screen/event/${eventId}?locale=${locale}`),
    getTips: (locale) => api.get(`screen/recommended?locale=${locale}`),

    // Sights
    getSights: () => api.get('screen/culture/sight'),
    getCultureRoutes: () => api.get('screen/culture/culture-route'),

    // Jana
    getMainEntities: (params = {}, locale = 'fi') => api.get('screen/jana/entity', { ...params, locale }),
    getMainEntity: (id, locale = 'fi') => api.get(`screen/jana/entity/${id}?lang=${locale}`),
    getMainEntityInfos: (params = {}) => api.get('screen/jana/info-page', params),
    getEntityInfos: (id, params = {}) => api.get(`screen/jana/entity/${id}/info-page`, params),
    getEntityMaintenanceLogs: (id) => api.get(`screen/jana/entiy/${id}/note`),

    // Move
    getVehiclePositions: (data) => api.post('screen/move/vehicle-positions', data),

    // Send feedback
    sendFeedback: (feedback) => api.post('influence/app-feedback', feedback),

    getNearbyStops: (location) => digitransitApi.post(
      '',
      {
        query: `
          query {
            stopsByRadius(lat: ${location.lat}, lon: ${location.lng}, radius: 1000, first: 50) {
              edges {
                node {
                  stop {
                    gtfsId
                    name
                    code
                    lat
                    lon
                    zoneId
                    vehicleMode
                  }
                  distance
                }
              }
            }
          }
        `
      }
    ),
    getStop: (id) => digitransitApi.post(
      '',
      {
        query: `
          query {
            stop(id: "${id}") {
              stoptimesWithoutPatterns(numberOfDepartures: 25) {
                realtimeDeparture
                headsign
                trip {
                  routeShortName
                }
              }
              stoptimesForServiceDate(date: "${moment().format('YYYYMMDD')}", omitNonPickups: true) {
                stoptimes {
                  realtimeArrival
                  headsign
                  trip {
                    routeShortName
                    route {
                      mode
                    }
                  }
                }
              }
            }
          }
        `
      }
    ),
    getLines: () => digitransitApi.post(
      '',
      {
        query: `
          query {
            routes(feeds: "tampere", transportModes: [BUS, TRAM]) {
              gtfsId
              shortName
              longName
              mode
              stops {
                zoneId
              }
            }
          }
        `
      }
    ),
    getRoute: (from, to, time, date, arriveBy) => digitransitApi.post(
      '',
      {
        query: `
          query {
            plan(
              from: {lat: ${from.lat}, lon: ${from.lng}}
              to: {lat: ${to.lat}, lon: ${to.lng}}
              date: "${date}"
              time: "${time}"
              numItineraries: 10
              arriveBy: ${arriveBy}
            ) {
              itineraries {
                startTime
                endTime
                waitingTime
                duration
                walkTime
                walkDistance
                legs {
                  mode
                  startTime
                  endTime
                  duration
                  distance
                  legGeometry {
                    points
                  }
                  from {
                    lat
                    lon
                    name
                    stop {
                      code
                      name
                    }
                  }
                  to {
                    lat
                    lon
                    name
                  }
                  trip {
                    routeShortName
                    route {
                      mode
                    }
                  }
                  intermediatePlaces {
                    name
                    arrivalTime
                    departureTime
                  }
                }
              }
            }
          }
        `
      },
      {}
    )
  }
}

const Api = create()

export default Api

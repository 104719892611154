import React, { useEffect, useState, useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { useNavigate} from 'react-router-dom'
import { AppHeader, AppContent, Button, SportListItem, Modal, SelectList } from '../Components'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
  },
  content: {
    padding: '0rem 3rem 20rem'
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '3.125rem',
    marginBottom: '1rem',
    textTransform: 'uppercase'
  },
  description: {
    fontFamily: 'Manrope',
    fontSize: '2rem',
    color: Colors.content,
    marginBottom: '3rem'
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '3rem'
  },
  leftButton: {
    flex: 1,
    paddingRight: '1.5rem'
  },
  rightButton: {
    flex: 1,
    paddingLeft: '1.5rem'
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: Colors.white
  }
}))

function SportsScreen() {
  const classes = useStyles()
  const { sportStore, appStore, mapStore } = useStore()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [filtersVisible, setFiltersVisible] = useState(false)
  const [activeCategories, setActiveCategories] = useState([])

  const [activeType, setActiveType] = useState(null)

  const handleSetActiveType = (typeId) => {
    const filteredItems = listItems.filter(item => item?.mainEntityType?.id === typeId)
    sportStore.setFilteredSports(filteredItems)
    setActiveType(typeId)

    // Scroll to top when main entity type is selected
    try {
      window.document.getElementById('app-main-content').scrollTop = 0
    } catch (err) {
      console.log(err)
    }
  }

  const toggleFiltersVisible = () => setFiltersVisible(!filtersVisible)

  const toMap = () => {
    mapStore.setDirections(null)
    sportStore.setSport(null)
    navigate('/sports-map')
  }

  const clearFilters = () => {
    setActiveCategories([])
    setFiltersVisible(false)
  }

  useEffect(() => {
    sportStore.getSports()
    sportStore.setSport(null)
    mapStore.setDirections(null)
  }, [])

  const openItem = (item) => {
    sportStore.setSport(item)
    navigate(`/sports/${item.id}`)
  }

  const getCategories = () => ([]).map(cat => {
    return { name: cat.label, value: cat.value }
  })

  const listItems = useMemo(() => {
    return sportStore.sports
  }, [sportStore.sports])

  const getSportTypes = () => {
    const pickedMainEntityTypes: any = []
    const types: any = []
    const listItems = sportStore.sports
    for (const item of listItems) {
      const type = item.mainEntityType.type
      const typeId = item.mainEntityType.id
      if (!pickedMainEntityTypes.includes(type)) {
        pickedMainEntityTypes.push(type)
        types.push({
          id: typeId,
          type,
          photo: item.photo || null
        })
      }
    }
    return types
  }

  const renderGridItems = () => {
    const items = listItems.filter(item => item?.mainEntityType?.id === activeType)
    return items.map((item) => (
      <Grid key={item.id} item xs={6}>
        <SportListItem
          item={item}
          onOpen={openItem}
          locale={appStore.locale}
          location={appStore.location}
        />
      </Grid>
    ))
  }

  const renderTypes = () => {
    const items = getSportTypes()
    return items.map((item) => (
      <Grid key={item.id} item xs={6}>
        <SportListItem
          item={item}
          onOpen={() => handleSetActiveType(item.id)}
          locale={appStore.locale}
          location={appStore.location}
          isTypeList
        />
      </Grid>
    ))
  }

  const renderGrid = () => {
    if (sportStore.loading && !listItems.length) {
      return <CircularProgress size='5rem' classes={{ root: classes.loader }} />
    }
    return (
      <Grid container spacing={6}>
        {!activeType ? renderTypes() : renderGridItems()}
      </Grid>
    )
  }

  const renderFilters = () => {
    return (
      <Modal
        title={t('filter_search_results')}
        onCancel={clearFilters}
        onOk={toggleFiltersVisible}
        okText={t('view_results')}
        open={filtersVisible}
      >
        <SelectList
          items={getCategories()}
          values={activeCategories}
          onChange={setActiveCategories}
        />
      </Modal>
    )
  }

  return (
    <div className={classes.container}>
      <AppHeader title={t('sports_venues')} />
      <AppContent>
        <div className={classes.content}>
          <h1 className={classes.title}>{t('sports_title')}</h1>
          <p className={classes.description}>{t('sports_info')}</p>
          {activeType && (
            <div className={classes.buttonsRow}>
              <Button
                text={t('view_on_map')}
                onClick={toMap}
                outlined
                fullWidth
              />
            </div>
          )}
          {renderGrid()}
          {renderFilters()}
        </div>
      </AppContent>
    </div>
  )
}

export default observer(SportsScreen)

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import { getDistanceFormatted } from '../../Utils/map'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    // cursor: 'pointer',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    height: '28rem',
    borderRadius: '.625rem'
  },
  content: {
    flex: 1,
    padding: '1rem'
  },
  category: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    backgroundColor: Colors.appGrey,
    color: Colors.white,
    fontSize: '1rem',
    borderRadius: '.3125rem',
    padding: '1rem'
  },
  titleContainer: {
    background: 'linear-gradient(360deg, #263238 0%, rgba(38, 50, 56, 0) 100%)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '2rem'
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '1.75rem',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  subtitle: {
    fontFamily: 'Manrope',
    fontSize: '1.25rem',
    color: Colors.white70
  }
}))

export default function CultureListItem(props) {
  const { item } = props
  const { t } = useTranslation()

  const classes = useStyles()

  const getPhotoStyle = () => {
    const photo = get(item, 'image')
    let bgStyle = {}
    if (photo) {
      bgStyle = {
        style: { backgroundImage: `url(${photo})` }
      }
    }
    return bgStyle
  }

  const onOpen = () => props.onOpen(item)

  const renderCategory = () => {
    const category = get(item, 'categories[0].label')
    if (category) {
      return <div className={classes.category}>{category}</div>
    }
    return null
  }

  const renderTitle = () => {
    const title = props.locale === 'en' ? get(item, 'title.en') : get(item, 'title.fi')
    const distance = getDistanceFormatted(props.location, props?.item?.coordinates)

    return (
      <div className={classes.titleContainer}>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{t('distance')} {distance}</div>
      </div>
    )
  }

  return (
    <div
      className={classes.container}
      onClick={onOpen}
      {...getPhotoStyle()}
    >
      {renderCategory()}
      {renderTitle()}
    </div>
  )
}

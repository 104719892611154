import { makeAutoObservable } from 'mobx'
import Api from '../Services/Api'

export default class EventStore {
  rootStore
  loading = false

  events = []
  event = null
  eventCategories = []
  tips = []
  tip = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setEvents = (events) => { this.events = events }
  setEventCategories = (eventCategories) => { this.eventCategories = eventCategories }
  setEvent = (event) => { this.event = event }
  setTips = (tips) => { this.tips = tips }
  setTip = (tip) => { this.tip = tip }
  setLoading = (loading) => { this.loading = loading  }

  async getEvents(locale) {
    this.setLoading(true)
    const response: any = await Api.getEvents(locale)
    if (response.ok) {
      this.setEvents(response.data.events)
      this.setEventCategories(response.data.categories)
    }
    this.setLoading(false)
  }

  async getEvent(locale, eventId) {
    this.setLoading(true)
    const response: any = await Api.getEvents(locale)
    if (response.ok) {
      const event = response.data.events.find(item => item.id === eventId)
      this.setEvents(response.data.events)
      this.setEventCategories(response.data.categories)
      this.setEvent(event)
    }
    this.setLoading(false)
  }

  async getTips(locale) {
    this.setLoading(true)
    const response: any = await Api.getTips(locale)
    if (response.ok) {
      this.setTips(response.data)
    }
    this.setLoading(false)
  }

  async getTip(locale, tipId) {
    this.setLoading(true)
    const response: any = await Api.getTips(locale)
    if (response.ok) {
      const tip = response.data.find(item => item.id === tipId)
      this.setTips(response.data)
      this.setTip(tip)
    }
    this.setLoading(false)
  }
}

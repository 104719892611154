import React, { useEffect, useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import makeStyles from '@mui/styles/makeStyles'
import BusStopIcon from '../../Assets/Icons/bus-stop.svg'
import TramStopIcon from '../../Assets/Icons/tram-stop.svg'
import RecyclePointIcon from '../../Assets/Icons/recycle-point.svg'
import BasketballIcon from '../../Assets/Icons/basketball.svg'
import BeautyIcon from '../../Assets/Icons/beauty.svg'
import CultureIcon from '../../Assets/Icons/culture.svg'
import DiscGolfIcon from '../../Assets/Icons/disc-golf.svg'
import EventIcon from '../../Assets/Icons/event.svg'
import GymIcon from '../../Assets/Icons/gym.svg'
import MajorEventIcon from '../../Assets/Icons/major-event.svg'
import RestaurantIcon from '../../Assets/Icons/restaurant.svg'
import SaunaIcon from '../../Assets/Icons/sauna.svg'
import ShoppingIcon from '../../Assets/Icons/shopping.svg'
import SightIcon from '../../Assets/Icons/sight.svg'
import SkateIcon from '../../Assets/Icons/skate.svg'
import SportIcon from '../../Assets/Icons/sport.svg'
import TennisIcon from '../../Assets/Icons/tennis.svg'
import CouponIcon from '../../Assets/Icons/coupon.png'
import ClothingIcon from '../../Assets/Icons/coupon.png'
import HomeIcon from '../../Assets/Icons/home.png'
import XmasIcon from '../../Assets/Icons/xmas.png'
import ActivityIcon from '../../Assets/Icons/activity.png'

import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '26px',
    background: 'transparent'
  },
  myLocationPinContainer: {
    height: '9rem',
    width: '9rem',
    marginTop: '-4.5rem',
    marginLeft: '-4.5rem',
    background: 'rgba(235, 94, 87, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%'
  },
  myLocationPinInner: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '100%',
    backgroundColor: '#EB5E57',
    border: '1px solid #fff'
  },
  markerContainer: {
    height: '9rem',
    width: '9rem',
    marginTop: '-4.5rem',
    marginLeft: '-4.5rem',
    background: '#447D5C',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%'
  },
  markerInner: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '100%',
    backgroundColor: '#ffffff'
  },
  iconMarker: {
    marginTop: '-2.1875rem',
    marginLeft: '-2.1875rem'
  },
  routePointContainer: {
    height: '1.5rem',
    width: '1.5rem',
    marginTop: '-.75rem',
    marginLeft: '-.75rem',
    background: Colors.red,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%'
  },
  routePointInner: {
    width: '.75rem',
    height: '.75rem',
    borderRadius: '100%',
    backgroundColor: '#ffffff'
  },
  stopIcon: {
    height: '4.375rem'
  }
}))


const CustomMarker = (props) => {
  const classes = useStyles()

  if (props.type === 'bus_stop') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={BusStopIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'tram_stop') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={TramStopIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'recycle_point') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={RecyclePointIcon} className={classes.stopIcon} />
      </div>
    )
  }

  /* Content types */
  if (props.type === 'basketball') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={BasketballIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'beauty') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={BeautyIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'culture') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={CultureIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'disc_golf') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={DiscGolfIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'event') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={EventIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'gym') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={GymIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'major_event') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={MajorEventIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'restaurant') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={RestaurantIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'sauna') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={SaunaIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'shopping') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={ShoppingIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'sight') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={SightIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'skate') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={SkateIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'sport') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={SportIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'tennis' || props.type === 'padel') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={TennisIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'coupon') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={CouponIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'clothing') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={ClothingIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'home') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={HomeIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'xmas') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={XmasIcon} className={classes.stopIcon} />
      </div>
    )
  }
  if (props.type === 'activity') {
    return (
      <div className={classes.iconMarker} onClick={props.onClick}>
        <img src={ActivityIcon} className={classes.stopIcon} />
      </div>
    )
  }




  if (props.type === 'route_point') {
    return (
      <div className={classes.routePointContainer} onClick={props.onClick}>
        <img src={TramStopIcon} className={classes.routePointInner} />
      </div>
    )
  }


  return (
    <div className={classes.markerContainer} onClick={props.onClick}>
      <div className={classes.markerInner} />
    </div>
  )
}

const MyLocationPin = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.myLocationPinContainer}>
      <div className={classes.myLocationPinInner} />
    </div>
  )
}

const PathMarker = (props) => {

  const polylineRef = useRef(null)

  useEffect(() => {
    // Create polyline
    const newPolyline = new props.maps.Polyline({
      path: props.path.map(point => ({
        lat: point.latitude,
        lng: point.longitude
      })),
      geodesic: true,
      strokeColor: Colors.darkRed,
      strokeOpacity: 1,
      strokeWeight: 20,
    })
    newPolyline.setMap(props.map)

    // Bind click event
    if (props.onClick) {
      props.maps.event.addListener(newPolyline, 'click', function() {
        props.onClick()
      })
    }

    polylineRef.current = newPolyline

    return function cleanup() {
      if (polylineRef.current) {
        polylineRef.current.setPath([])
        polylineRef.current.setMap(null)
      }
    }
  }, [])

  return null
}

export default function SimpleMap(props){
  const classes = useStyles()

  const [map, setMap] = useState(null)
  const [maps, setMaps] = useState(null)

  const defaultProps = {
    center: {
      lat: (props?.location?.lat || 61.498658388226396) - (props.shiftUp ? 0.0095 : 0), // Adjust map little bit upper
      lng: (props?.location?.lng || 23.771559659471162)
    },
    zoom: props.defaultZoom || 14,
    styles: [
      {
        "featureType":"administrative",
        "elementType":"labels",
        "stylers":[
          {
            "color":"#FFFFFF"
          },
          {
            "visibility":"simplified"
          }
        ]
      },
      {
        "featureType":"landscape.man_made",
        "elementType":"all",
        "stylers":[
          {
            "visibility":"off"
          },
          {
            "color":"#303030"
          }
        ]
      },
      {
        "featureType":"landscape.natural",
        "elementType":"geometry",
        "stylers":[
          {
            "color":"#000000"
          },
          {
            "visibility":"simplified"
          }
        ]
      },
      {
        "featureType":"poi",
        "elementType":"geometry",
        "stylers":[
          {
            "visibility":"off"
          }
        ]
      },
      {
        "featureType":"poi",
        "elementType":"labels",
        "stylers":[
          {
            "visibility":"off"
          }
        ]
      },
      {
        "featureType":"poi",
        "elementType":"labels.text",
        "stylers":[
          {
            "visibility":"simplified"
          },
          {
            "color":"#FFFFFF"
          }
        ]
      },
      {
        "featureType":"road",
        "elementType":"geometry",
        "stylers":[
          {
            "visibility":"simplified"
          },
          {
            "color":"#808080"
          }
        ]
      },
      {
        "featureType":"road",
        "elementType":"labels.text",
        "stylers":[
          {
            "color":"#FFFFFF"
          },
          {
            "visibility":"simplified"
          }
        ]
      },
      {
        "featureType":"road",
        "elementType":"labels.icon",
        "stylers":[
          {
            "visibility":"off"
          }
        ]
      },
      {
        "featureType":"transit",
        "elementType":"all",
        "stylers":[
          {
            "visibility":"off"
          }
        ]
      },
      {
        "featureType":"transit",
        "elementType":"labels",
        "stylers":[
          {
            "visibility":"off"
          }
        ]
      },
      {
        "featureType":"water",
        "elementType":"all",
        "stylers":[
          {
            "color":"#303030"
          }
        ]
      }
    ]
  }

  const ignoreClick = () => {
    // Do nothing
  }

  const renderMyLocation = () => {
    if (props.location) {
      return (
        <MyLocationPin
          lat={props.location.lat}
          lng={props.location.lng}
        />
      )
    }
  }

  const getBoundsPadding = () => {
    return {
      top: Math.round(window.innerHeight * 0.15),
      left: Math.round(window.innerWidth * 0.15),
      right: Math.round(window.innerWidth * 0.15),
      bottom: Math.round(window.innerHeight * 0.5)
    }
  }

  const renderMarkers = () => {
    return (props.markers || []).map((marker, index) => {
      return (
        <CustomMarker
          key={index}
          onClick={() => {
            if (marker.onClick) {
              marker.onClick()
              // Center map
              try {
                const bounds = new maps.LatLngBounds()
                bounds.extend(marker)
                bounds.extend(props.location)
                map.fitBounds(bounds, getBoundsPadding())
              } catch (err) {
                console.log(err)
              }
            }
          }}
          type={marker.type}
          lat={marker.lat}
          lng={marker.lng}
        />
      )
    })
  }

  const handleApiLoaded = (map, maps) => {
    setMap(map)
    setMaps(maps)

    const bounds = new maps.LatLngBounds()
    let triggerFocus = false

    if (props.markers && props.markers.length === 1) {
      const marker = props.markers[0]
      // Center map
      try {
        bounds.extend(marker)
        bounds.extend(props.location)
        triggerFocus = true
      } catch (err) {
        console.log(err)
      }
    }
    // Try focus with route
    if (props.routes && props.routes.length) {
      try {
        for (const route of props.routes) {
          const startPoint = { lat: route[0].latitude, lng: route[0].longitude }
          const endPoint = { lat: route[route.length - 1].latitude, lng: route[route.length - 1].longitude }
          if (route.length > 4) {
            const centerPoint = {
              lat: route[Math.floor(route.length / 2)].latitude,
              lng: route[Math.floor(route.length / 2)].longitude
            }
            bounds.extend(centerPoint)
          }
          bounds.extend(startPoint)
          bounds.extend(endPoint)
          triggerFocus = true
        }
      } catch (err) {
        console.log(err)
      }
    }

    if (triggerFocus) {
      map.fitBounds(bounds, getBoundsPadding())
    }
  }

  const renderRoutes = () => {
    if (!map || !maps) return null
    if (props.routes && props.routes.length) {
      return props.routes.map((route, index) => {
        let handleClick = undefined

        if (props.onRouteClick) {
          handleClick = () => {
            const shouldFocus = props.onRouteClick(index)
            if (shouldFocus) {
              const bounds = new maps.LatLngBounds()
              const startPoint = { lat: route[0].latitude, lng: route[0].longitude }
              const endPoint = { lat: route[route.length - 1].latitude, lng: route[route.length - 1].longitude }
              if (route.length > 4) {
                const centerPoint = {
                  lat: route[Math.floor(route.length / 2)].latitude,
                  lng: route[Math.floor(route.length / 2)].longitude
                }
                bounds.extend(centerPoint)
              }
              bounds.extend(startPoint)
              bounds.extend(endPoint)
              bounds.extend(props.location)
              map.fitBounds(bounds, getBoundsPadding())
            }
          }
        }
        return (
          <PathMarker
            key={index}
            path={route}
            map={map}
            maps={maps}
            onClick={handleClick}
          />
        )
      })
    }
    return null
  }

  const renderRouteStarts = () => {
    if (map && maps && props.routes && props.routes.length > 1) {
      return props.routes.map((route, routeIndex) => {
        if (route.length >= 2) {
          return (
            <CustomMarker
              key={`start_${routeIndex}`}
              lat={route[0].latitude}
              lng={route[0].longitude}
              type='route_point'
            />
          )
        }
        return null
      })
    }
    return null
  }

  const renderRouteEnds = () => {
    if (map && maps && props.routes && props.routes.length > 1) {
      return props.routes.map((route, routeIndex) => {
        if (route.length >= 2) {
          return (
            <CustomMarker
              key={`end_${routeIndex}`}
              lat={route[route.length - 1].latitude}
              lng={route[route.length - 1].longitude}
              type='route_point'
            />
          )
        }
        return null
      })
    }
    return null
  }

  return (
    // Important! Always set the container height explicitly
    <div className={classes.container}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAaQ9pftyJpUQ3C9tx2T-7XyBWsop4rRUc" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{ styles: defaultProps.styles }}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        yesIWantToUseGoogleMapApiInternals
      >
        {renderMyLocation()}
        {renderMarkers()}
        {renderRouteStarts()}
        {renderRouteEnds()}
        {renderRoutes()}
      </GoogleMapReact>
      <div className={classes.footer} onClick={ignoreClick} />
    </div>
  );
}

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Keyboard } from '..'
import ClearIcon from '../../Assets/Icons/clear.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '2rem'
  },
  inputContainer: {
    position: 'relative'
  },
  input: {
    // cursor: 'text',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: `.3125rem solid ${Colors.white}`,
    backgroundColor: Colors.appGreyDarkest,
    height: '7.5rem',
    boxSizing: 'border-box',
    color: Colors.white70,
    fontSize: '2.25rem',
    fontWeight: 400,
    padding: '1rem 7rem 1rem 2rem',
    borderRadius: '.625rem',
    wordBreak: 'break-word'
  },
  multilineInput: {
    // cursor: 'text',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    border: `.3125rem solid ${Colors.white}`,
    backgroundColor: Colors.appGreyDarkest,
    height: '29.125rem',
    boxSizing: 'border-box',
    color: Colors.white70,
    fontSize: '2.25rem',
    fontWeight: 400,
    padding: '2rem',
    borderRadius: '.625rem',
    wordBreak: 'break-word'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left:0,
    right: 0,
    bottom: 0,
    background: 'rgba(0,0,0,.5)',
    zIndex: 1
  },
  value: {
    color: Colors.white
  },
  placeholder: {
    color: Colors.white50
  },
  iconContainer: {
    width: '6rem',
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    height: '3.75rem',
    marginRight: '.5rem'
  },
  clearButton: {
    paddingTop: '2.125rem',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: '7rem',
    // cursor: 'pointer',
    opacity: 0.7,
    '&:hover': {
      opacity: 1
    }
  },
  clearIcon: {
    height: '3.25rem'
  }
}))

export default function Input(props) {
  const classes = useStyles()

  const toggleActive = () => {
    props.setFocused(!props.focused)
  }

  const renderValue = () => {
    if (props.value) {
      return <span className={classes.value}>{props.value}</span>
    }
    return <span className={classes.placeholder}>{props.placeholder}</span>
  }

  const renderIcon = () => {
    if (props.icon) {
      return (
        <div className={classes.iconContainer}>
          <img src={props.icon} className={classes.icon} />
        </div>
      )
    }
  }

  const handleChange = (val) => {
    props.onChange(val)
  }

  const handleClear = () => {
    props.onClear()
  }

  const renderClear = () => {
    if (props.onClear && props.value) {
      return (
        <div className={classes.clearButton} onClick={handleClear}>
          <img src={ClearIcon} className={classes.clearIcon} />
        </div>
      )
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Keyboard
          onChange={handleChange}
          input={props.value}
          open={props.focused}
          onClose={toggleActive}
          adjustUp={props.adjustUp}
        >
          <div className={classes.inputContainer}>
            <div className={props.multiline ? classes.multilineInput : classes.input} onClick={toggleActive}>
              {renderIcon()}
              {renderValue()}
            </div>
            {renderClear()}
          </div>
        </Keyboard>
      </div>
    </>
  )
}

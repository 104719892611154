import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import get from 'lodash/get'
import { AppContent, AppHeader, Button, QrCodeModal } from '../Components'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'
import { getMapsLink } from '../Utils/map'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0rem 3rem 20rem'
  },
  photo: {
    height: '56.25vw',
    maxHeight: 'calc(67.5rem * 0.5625)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  h1: {
    color: Colors.white,
    fontSize: '3.125rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    margin: 0,
    marginTop: '2rem',
    marginBottom: '1rem'
  },
  strong: {
    color: Colors.white
  },
  h2: {
    color: Colors.white,
    fontSize: '2.625rem',
    fontWeight: 800,
    marginBottom: '1rem'
  },
  paragraph: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70,
    marginBottom: '2.5rem'
  },
  description: {
    marginBottom: '1rem',
    fontSize: '1.875rem',
    color: Colors.white70
  },
  smallTitle: {
    fontSize: '1.875rem',
    fontWeight: 800,
    color: Colors.white,
    marginTop: '2rem',
    marginBottom: '1rem'
  }
}))

function TouristInfoScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { appStore } = useStore()

  const renderPhoto = () => {

    return (
      <div
        className={classes.photo}
        style={{ backgroundImage: `url(/pyynikki.jpg)` }}
      />
    )
  }

  // TODO: fix contents

  const renderFi = () => {
    return (
      <div className={classes.description}>
        <h1 className={classes.h1}>
          {'Visit Tampereen matkailuneuvonta'}
        </h1>
        <p className={classes.paragraph}>
          {'Visit Tampereen matkailuneuvonta palvelee puhelimitse ja sähköpostitse '}
          <strong className={classes.strong}>{'ma-pe 10-15.'}</strong>
        </p>
        <p className={classes.paragraph}>
          {'Tavoitat meidät numerosta 03 5656 6800 ja sähköpostitse osoitteesta visittampere@visittampere.fi. '}
        </p>
        <h2 className={classes.h2}>
          {'Kesä 2023'}
        </h2>
        <p className={classes.paragraph}>
          {'Kesän suurtapahtumissa, jääkiekon MM-kisoissa ja European Masters Games -tapahtumassa Visit Tampereen matkailuneuvonta jalkautuu keskustan tapahtumapaikkojen läheisyyteen. Tunnistat matkailuneuvojamme vihreistä Visit Tampere -huomioliiveistä.'}
        </p>
        <p className={classes.paragraph}>
          {'Lisäksi Kulttuurimyymälä Aplodin vieressä sijaitsevasta Kulttuurikioskin luukusta Tampereen Teatterin kivijalassa on kesän ajan tarjolla matkailuneuvontaa '}
          <strong className={classes.strong}>{'tiistaista lauantaihin 16.5.-31.8.2023 klo 12-18.'}</strong>
          {' Aplodin osoite on Hämeenkatu 14 B.'}
        </p>
      </div>
    )
  }

  const renderEn = () => {
    return (
      <div className={classes.description}>
        <h1 className={classes.h1}>
          {'Tourist information'}
        </h1>
        <p className={classes.paragraph}>
          {'Visit Tampere tourist information offers you information, brochures, tips and maps. We’re at your service all year round and you can reach us by email, phone, livechat and social media. You can also find maps for example from Tampere Market Hall.'}
        </p>
        <h2 className={classes.h2}>
          {'Summer 2023'}
        </h2>
        <p className={classes.paragraph}>
          {'During the 2023 Ice Hockey World Championships and European Masters Games Visit Tampere Tourist Information pop up will be at your service. Pop up moves around the city and you can recognize them by green vests with Visit Tampere logo. Feel free to ask us for maps, recommendations and advice!'}
          {'In addition you can find tourist information from Culture Shop Aplodi from Tuesday to Saturday 16.5.-31.8.2023 at 12-18. Aplodi is located in the brick and mortar of Tampereen Teatteri theatre. The address is Hämeenkatu 14 B.'}
        </p>
      </div>
    )
  }

  return (
    <div>
      <AppHeader title={t('tourist_information')} />
      <AppContent>
        {renderPhoto()}
        <div className={classes.content}>
          {appStore.locale === 'en' ? renderEn() : renderFi()}
        </div>
      </AppContent>
    </div>
  )
}

export default observer(TouristInfoScreen)

import { makeAutoObservable } from 'mobx'
import Api from '../Services/Api'
import { compareItemDistances } from '../Utils/map'

export default class CouponStore {
  rootStore
  loading = false

  coupons = []
  categories = []
  organizationCategories = []
  couponOrganizations = []
  couponOrganization = null
  coupon = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setCoupons = (coupons) => { this.coupons = coupons }
  setCategories = (categories) => { this.categories = categories }
  setCoupon = (coupon) => { this.coupon = coupon }
  setOrganizationCategories = (organizationCategories) => { this.organizationCategories = organizationCategories }
  setCouponOrganizations = (couponOrganizations) => { this.couponOrganizations = couponOrganizations }
  setCouponOrganization = (couponOrganization) => { this.couponOrganization = couponOrganization }
  setLoading = (loading) => { this.loading = loading  }

  async getCoupons() {
    this.setLoading(true)
    const response: any = await Api.getCoupons()
    if (response.ok) {
      const coupons = response.data.coupons.sort((a, b) => {
        return compareItemDistances(this.rootStore.appStore.location, a, b)
      })
      this.setCoupons(coupons)
      this.setCategories(response.data.categories)
    }
    this.setLoading(false)
  }

  async getCoupon(couponId: number) {
    this.setLoading(true)
    const response: any = await Api.getCoupons()
    if (response.ok) {
      const coupon = response.data.coupons.find(item => item.id === couponId) || null
      const coupons = response.data.coupons.sort((a, b) => {
        return compareItemDistances(this.rootStore.appStore.location, a, b)
      })
      this.setCoupons(coupons)
      this.setCoupon(coupon)
    }
    this.setLoading(false)
  }

  async getCouponOrganizations() {
    this.setLoading(true)
    const response: any = await Api.getCouponOrganizations()
    if (response.ok) {
      const organizations = response.data.organizations.sort((a, b) => {
        return compareItemDistances(this.rootStore.appStore.location, a, b)
      })
      this.setCouponOrganizations(organizations)
      this.setOrganizationCategories(response.data.categories)
    }
    this.setLoading(false)
  }

  async getCouponOrganization(organizationId) {
    this.setLoading(true)
    const response: any = await Api.getCouponOrganizations()
    if (response.ok) {
      const oganization = response.data.organizations.find(item => item.id === organizationId)
      const organizations = response.data.organizations.sort((a, b) => {
        return compareItemDistances(this.rootStore.appStore.location, a, b)
      })
      this.setCouponOrganizations(organizations)
      this.setOrganizationCategories(response.data.categories)
      this.setCouponOrganization(oganization)
    }
    this.setLoading(false)
  }
}

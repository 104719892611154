import React from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ButtonBase from '@mui/material/ButtonBase'
import makeStyles from '@mui/styles/makeStyles'
import { Button } from '../../Components'
import { Colors } from '../../Utils/theme'

import InfoLogosFi from '../../Assets/Icons/info-logos-fi.png'
import InfoLogosEn from '../../Assets/Icons/info-logos-en.png'

const useStyles = makeStyles(() => ({
  paper: {
    position: 'relative',
    background: Colors.black,
    color: Colors.white,
    maxWidth: 'calc(67.5rem - 6rem)'
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: 'blur(10px)',
    background: 'rgba(38, 50, 56, .4)',
    zIndex: 999
  },
  outerContainer: {
    position: 'relative',
    alignSelf: 'center',
    padding: '4.5rem 0rem',
    zIndex: 999999
  },
  dialogContent: {
    alignSelf: 'center',
    width: '85vw',
    background: Colors.black,
    maxWidth: 'calc(67.5rem - 6rem)',
    padding: '2rem 3rem'
  },
  title: {
    margin: 0,
    fontSize: '2.25rem',
    fontWeight: 800,
    color: Colors.white,
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: '2rem 0rem'
  },
  description: {
    color: Colors.white70,
    fontSize: '2rem',
    textAlign: 'center',
    padding: '0 1rem 1rem'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '4.5rem',
    width: '10rem',
    backgroundColor: Colors.black,
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeButtonText: {
    fontFamily: 'Manrope',
    fontSize: '2rem',
    fontWeight: 700,
    color: Colors.white
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    // padding: '1rem',
    marginTop: '0.5rem'
  },
  buttonSpacer: {
    height: '1.5rem'
  },
  logoContainer: {
    padding: '2rem 0 2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  infoLogos: {
    width: '50vw'
  }
}))

export default function InfoModal(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const { open, onClose, locale } = props

  const renderLogos = () => {
    const infoLogos = locale === 'en' ? InfoLogosEn : InfoLogosFi
    return (
      <div className={classes.logoContainer}>
        <img src={infoLogos} className={classes.infoLogos} />
      </div>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      BackdropComponent={() => <div className={classes.backdrop} />}
    >
      <div className={classes.outerContainer}>
        <DialogContent className={classes.dialogContent}>
          <DialogTitle className={classes.title}>{t('info_modal_title')}</DialogTitle>
          <DialogContentText classes={{ root: classes.description }}>
            {t('info_modal_description')}
          </DialogContentText>

          {renderLogos()}
          <DialogActions className={classes.buttonsContainer}>
            <Button onClick={onClose} text={t('ok')} fullWidth />
          </DialogActions>
        </DialogContent>
        <ButtonBase className={classes.closeButton} onClick={onClose}>
          <p className={classes.closeButtonText}>{t('close')}</p>
        </ButtonBase>
      </div>
    </Dialog>
  )
}

import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import get from 'lodash/get'
import { AppContent, AppHeader, Button, QrCodeModal } from '../Components'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'
import { getMapsLink } from '../Utils/map'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0rem 3rem 20rem'
  },
  photo: {
    height: '56.25vw',
    maxHeight: 'calc(67.5rem * 0.5625)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '3.75rem',
    fontWeight: 800,
    color: Colors.white,
    textTransform: 'uppercase',
    margin: 0,
    marginTop: '2rem'
  },
  distance: {
    fontFamily: 'Manrope',
    fontSize: '2.5rem',
    color: Colors.white70,
    margin: 0,
    marginTop: '0.5rem',
    marginBottom: '2rem'
  },
  description: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70,
    marginTop: '2rem',
    marginBottom: '2.5rem'
  },
  smallTitle: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white,
    fontWeight: 800,
    marginTop: '2.5rem',
    marginBottom: '1rem'
  },
  text: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70
  },
  category: {
    display: 'inline-block',
    backgroundColor: Colors.appGrey,
    color: Colors.white,
    fontSize: '1.875rem',
    borderRadius: '.3125rem',
    padding: '.66rem .85rem'
  },
  footer: {
    maxWidth: 'calc(67.5rem - 6rem)',
    position: 'absolute',
    bottom: '2.5rem',
    left: '3rem',
    right: '3rem',
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto'
  },
  leftButton: {
    flex: 1,
    marginRight: '1.5rem'
  },
  rightButton: {
    flex: 1,
    marginLeft: '1.5rem'
  }
}))

function RestaurantScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { restaurantStore, appStore } = useStore()
  const { locale } = appStore
  const { restaurant } = restaurantStore

  const navigate = useNavigate()

  const [routeModalVisible, setRouteModalVisible] = useState(false)

  const toggleRouteModal = () => setRouteModalVisible(!routeModalVisible)
  const toRestaurantMap = () => navigate(`/restaurant-map/${restaurant.id}`)

  const renderPhoto = () => {
    if (restaurant?.photo) {
      const photo = restaurant.photo
      return (
        <div
          className={classes.photo}
          style={{ backgroundImage: `url(${photo})` }}
        />
      )
    }
  }

  const renderPlace = () => {
    const place = restaurantStore?.restaurant?.address
    if (place) {
      return (
        <div>
          <div className={classes.smallTitle}>{t('address')}</div>
          <div className={classes.text}>{place}</div>
        </div>
      )
    }
    return null
  }

  const renderCategory = () => {
    const place = get(restaurantStore?.restaurant, 'categories[0].label')
    if (place) {
      return (
        <div>
          <div className={classes.smallTitle}>{t('category')}</div>
          <div className={classes.category}>{place}</div>
        </div>
      )
    }
    return null
  }

  const renderFooter = () => {
    return (
      <div className={classes.footer}>
        <div className={classes.leftButton}>
          <Button
            text={t('download_route')}
            onClick={toggleRouteModal}
            outlined
            fullWidth
          />
        </div>
        <div className={classes.rightButton}>
          <Button
            text={t('view_on_map')}
            onClick={toRestaurantMap}
            outlined
            fullWidth
          />
        </div>
      </div>
    )
  }

  const renderRouteModal = () => {
    const latitude = restaurantStore?.restaurant?.latitude
    const longitude = restaurantStore?.restaurant?.longitude
    const code = getMapsLink(latitude, longitude)

    if (code) {
      return (
        <QrCodeModal
          title={t('download_route_to_your_phone')}
          onCancel={toggleRouteModal}
          open={routeModalVisible}
          code={code}
        />
      )
    }
    return null
  }

  return (
    <div>
      <AppHeader title={t('eat_and_drink')} />
      <AppContent>
        {renderPhoto()}
        <div className={classes.content}>
        <h1 className={classes.title}>{(locale === 'en' ? restaurant?.productNameEn : restaurant?.productNameFi) || restaurant.name}</h1>
          <div className={classes.description}>
            {locale === 'en' ? restaurant?.contentEn : restaurant?.contentFi}
          </div>
          {renderPlace()}
          {renderCategory()}
        </div>
        {renderFooter()}
        {renderRouteModal()}
      </AppContent>
    </div>
  )
}

export default observer(RestaurantScreen)

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppLayout } from '../Layouts'
import StartScreen from './StartScreen'
import EventsScreen from './EventsScreen'
import EventScreen from './EventScreen'
import MajorEventsScreen from './MajorEventsScreen'
import MajorEventScreen from './MajorEventScreen'
import MajorEventMapScreen from './MajorEventMapScreen'
import SportsScreen from './SportsScreen'
import SportScreen from './SportScreen'
import SportsMapScreen from './SportsMapScreen'
import TransportationScreen from './TransportationScreen'
import CouponsScreen from './CouponsScreen'
import CouponsMapScreen from './CouponsMapScreen'
import CouponOrganizationsScreen from './CouponOrganizationsScreen'
import CouponOrganizationScreen from './CouponOrganizationScreen'
import CouponOrganizationsMapScreen from './CouponOrganizationsMapScreen'
import RouteGuideScreen from './RouteGuideScreen'
import RouteGuideMapScreen from './RouteGuideMapScreen'
import BusStopMapScreen from './BusStopMapScreen'
import StopScreen from './StopScreen'
import SightsScreen from './SightsScreen'
import SightScreen from './SightScreen'
import SightsMapScreen from './SightsMapScreen'
import TipsScreen from './TipsScreen'
import TipScreen from './TipScreen'
import RecycleMapScreen from './RecycleMapScreen'
import FeedbackScreen from './FeedbackScreen'
import SaunasScreen from './SaunasScreen'
import SaunasMapScreen from './SaunasMapScreen'
import SaunaScreen from './SaunaScreen'
import RestaurantsScreen from './RestaurantsScreen'
import RestaurantScreen from './RestaurantScreen'
import RestaurantMapScreen from './RestaurantMapScreen'
import AttractionsScreen from './AttractionsScreen'
import AttractionScreen from './AttractionScreen'
import AttractionMapScreen from './AttractionMapScreen'
import TouristInfoScreen from './TouristInfoScreen'

export default function RootContainer() {
  return (
    <AppLayout>
      <Routes>
        <Route path='/' element={<StartScreen />} />
        <Route path='/tourist-info' element={<TouristInfoScreen />} />
        <Route path='/restaurants' element={<RestaurantsScreen />} />
        <Route path='/restaurant-map/:restaurantId' element={<RestaurantMapScreen />} />
        <Route path='/restaurants/:restaurantId' element={<RestaurantScreen />} />
        <Route path='/attractions' element={<AttractionsScreen />} />
        <Route path='/attraction-map/:attractionId' element={<AttractionMapScreen />} />
        <Route path='/attractions/:attractionId' element={<AttractionScreen />} />
        <Route path='/events' element={<EventsScreen />} />
        <Route path='/events/:eventId' element={<EventScreen />} />
        <Route path='/major-events' element={<MajorEventsScreen />} />
        <Route path='/major-events/:eventId' element={<MajorEventScreen />} />
        <Route path='/major-event-map' element={<MajorEventMapScreen />} />
        <Route path='/sports' element={<SportsScreen />} />
        <Route path='/sports/:sportId' element={<SportScreen />} />
        <Route path='/sports-map' element={<SportsMapScreen />} />
        <Route path='/saunas' element={<SaunasScreen />} />
        <Route path='/saunas-map' element={<SaunasMapScreen />} />
        <Route path='/saunas/:saunaId' element={<SaunaScreen />} />
        <Route path='/tips' element={<TipsScreen />} />
        <Route path='/tips/:tipId' element={<TipScreen />} />
        <Route path='/transportation' element={<TransportationScreen />} />
        <Route path='/recycle' element={<RecycleMapScreen />} />
        <Route path='/coupons' element={<CouponsScreen />} />
        <Route path='/coupons-map' element={<CouponsMapScreen />} />
        <Route path='/organizations' element={<CouponOrganizationsScreen />} />
        <Route path='/organizations/:organizationId' element={<CouponOrganizationScreen />} />
        <Route path='/coupon-organizations-map' element={<CouponOrganizationsMapScreen />} />
        <Route path='/route-guide' element={<RouteGuideScreen />} />
        <Route path='/route-guide-map' element={<RouteGuideMapScreen />} />
        <Route path='/stops' element={<BusStopMapScreen />} />
        <Route path='/stops/:stopId' element={<StopScreen />} />
        <Route path='/sights' element={<SightsScreen />} />
        <Route path='/sights/:sightId' element={<SightScreen />} />
        <Route path='/sights-map' element={<SightsMapScreen />} />
        <Route path='/feedback' element={<FeedbackScreen />} />
        <Route path='*' element={<Navigate replace to='/' />} />
      </Routes>
    </AppLayout>
  )
}

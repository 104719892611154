import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppHeader, AppContent, Button } from '../Components'
import Input from '../Components/Common/Input'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'
import { useNavigate } from 'react-router-dom'
import { Alert, Snackbar } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  container: {
  },
  content: {
    padding: '0rem 3rem 20rem'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '3.125rem',
    marginBottom: '1rem'
  },
  description: {
    fontSize: '2rem',
    color: Colors.content,
    marginBottom: '2rem'
  },
  label: {
    fontSize: '1.875rem',
    fontWeight: 800,
    color: Colors.white,
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  buttonContainer: {
    paddingTop: '1rem'
  }
}))

function FeedbackScreen() {
  const classes = useStyles()
  const { feedbackStore } = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [title, setTitle] = useState('')
  const [titleFocused, setTitleFocused] = useState(false)
  const [content, setContent] = useState('')
  const [contentFocused, setContentFocused] = useState(false)
  const [feedbackSent, setFeedbackSent] = useState(false)

  const clearTitle = () => setTitle('')

  const handleSendFeedback = () => {
    feedbackStore.sendFeedback({ title, content })
  }

  useEffect(() => {
    // Detect if feedback was just sent
    if (feedbackStore.feedbackSentAt && feedbackStore.feedbackSentAt > (Date.now() - 2000)) {
      setFeedbackSent(true)
      // Reset feedback sent at
      feedbackStore.setFeedbackSentAt(0)
      // Reset fields
      setTitle('')
      setContent('')
    }

  }, [feedbackStore.feedbackSentAt])

  const renderNotification = () => {
    if (feedbackSent) {
      return (
        <Snackbar
          key={feedbackStore.feedbackSentAt}
          open={true}
          autoHideDuration={6000}
          onClose={() => setFeedbackSent(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          sx={{
            width: '100%' ,
            marginTop: '8rem'
          }}
        >
          <Alert
            onClose={() => setFeedbackSent(false)}
            severity="success"
            sx={{
              width: '90%',
              fontSize: '2.5rem',
              display: 'flex',
              paddingTop: '1.5rem',
              paddingBottom: '1.5rem',
              fontWeight: 700,
              paddingLeft: '2rem',
              backgroundColor: '#1B5C3B',
              fontFamily: 'Manrope',
              color: Colors.white
            }}
          >
            {t('thank_you_for_your_feedback')}
          </Alert>
        </Snackbar>
      )
    }
    return false
  }

  return (
    <div className={classes.container}>
      <AppHeader title={t('open_feedback')} />
      <AppContent>
        <div className={classes.content}>
          <h1 className={classes.title}>{t('feedback_title')}</h1>
          <p className={classes.description}>{t('feedback_info')}</p>
          <div className={classes.label}>{t('title')}</div>
          <Input
            value={title}
            onChange={setTitle}
            focused={titleFocused}
            setFocused={setTitleFocused}
            placeholder={t('title')}
            onClear={clearTitle}
            adjustUp={42}
          />
          <div className={classes.label}>{t('your_message')}</div>
          <Input
            value={content}
            onChange={setContent}
            focused={contentFocused}
            setFocused={setContentFocused}
            placeholder={t('your_message')}
            adjustUp={620}
            multiline
          />
          <div className={classes.buttonContainer}>
            <Button
              onClick={handleSendFeedback}
              text={t('send_feedback')}
              disabled={!content || content.length < 3 || feedbackStore.loading}
              loading={feedbackStore.loading}
              fullWidth
            />
          </div>
          {renderNotification()}
        </div>
      </AppContent>
    </div>
  )
}

export default observer(FeedbackScreen)

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  leftIcon: {
    maxHeight: '4.125rem',
    marginRight: '1.75rem',
    userSelect: 'none'
  },
  rightIcon: {
    height: '100%',
    width: '100%',
    maxHeight: '1.125rem',
    maxWidth: '1.125rem',
    marginLeft: '0.75rem',
    userSelect: 'none'
  },
  baseButton: {
    backgroundColor: Colors.darkRed,
    paddingLeft: '2rem',
    paddingRight: 0,
    height: '6.25rem',
    borderRadius: '0.3125rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: Colors.appGreyDarkest
    },
    "&:active": {
      color: Colors.white
    }
  },
  fullWidth: {
    width: '100%'
  },
  baseButtonText: {
    whiteSpace: 'nowrap',
    margin: 0,
    fontFamily: 'Manrope',
    fontSize: '2rem',
    fontWeight: 700
  },
  // Button variants
  defaultButton: {
    backgroundColor: Colors.appGreyDark,
    height: '7.5rem'
  },
  defaultButtonText: {
    color: Colors.white
  },
  disabledButton: {
    opacity: 0.4
  },
  // Loading icon variants
  colorWhite: {
    color: Colors.white
  },
  colorBlack: {
    color: Colors.black
  }
}))

export default function StartButton(props) {
  const classes = useStyles()

  const buttonClasses = [classes.baseButton]
  const buttonTextClasses = [classes.baseButtonText]
  let loadingClass = classes.colorWhite
  let loadingSize = 22

  if (props.fullWidth) {
    buttonClasses.push(classes.fullWidth)
  }

  buttonClasses.push(classes.defaultButton)
  buttonTextClasses.push(classes.defaultButtonText)

  if (props.buttonStyle) buttonClasses.push(props.buttonStyle)
  if (props.buttonTextStyle) buttonTextClasses.push(props.buttonTextStyle)

  const renderContent = () => {
    if (props.loading) return <CircularProgress className={loadingClass} size={loadingSize} />

    return (
      <>
        {props.leftIcon && <img src={props.leftIcon} className={classes.leftIcon} alt='icon' />}
        <p className={buttonTextClasses.join(' ')}>
          {props.text}
        </p>
        {props.rightIcon && <img src={props.rightIcon} className={classes.rightIcon} alt='icon' />}
      </>
    )
  }

  return (
    <Button
      className={buttonClasses.join(' ')}
      onClick={props.onClick}
      disabled={props.disabled}
      classes={{ disabled: classes.disabledButton }}
    >
      {renderContent()}
    </Button>
  )
}

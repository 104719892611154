import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import ButtonBase from '@mui/material/ButtonBase'
import { getTodaysClosingHour, getIsCurrentTimeAfterClosingTime, getPlaceOpenStatus, getTodaysOpeningHour } from '../../Utils/jana'
import { Colors } from '../../Utils/theme'

const InfoRowTypes = {
  Link: [
    'www',
    'trip_advisor_url',
    'booking_calendar_link',
    'tour_schedules_link',
    'facebook_url',
    'ticket_sales_link',
    'show_schedules_link'
  ],
  Phone: 'phone',
  Email: 'email',
  OpenHours: 'open_hours',
  Price: 'price',
  Address: 'street_address',
  ConditionReport: 'condition_report',
  SportFieldList: 'sport_field_list',
  ServicesContactInformation: 'services_contact_information',
  ServiceOpenHours: 'service_open_hours',
  Links: 'links',
  AmusementParkEquipment: 'amusement_park_equipment',
  TourPricing: 'tour_pricing',
  EquipmentAdditionalInformation: 'equipment_additional_info',
  ExceptionSchedules: 'exception_schedules',
  Faculties: 'faculties',
  Campuses: 'campuses',
  ServicePhones: 'service_phones',
  ServiceUrls: 'service_urls'
}

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '1.25rem'
  },
  columnContainer: {
    marginBottom: '0.5rem'
  },
  key: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    fontWeight: 800,
    color: Colors.white
  },
  value: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    color: Colors.white70,
    maxWidth: '75vw',
    textAlign: 'right'
  },
  linkValue: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    color: Colors.white70,
    maxWidth: '75vw',
    textAlign: 'right',
    textDecorationLine: 'underline',
    textDecorationColor: Colors.white70
  },
  columnValue: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    color: Colors.white70
  },
  buttonContainer: {
  },
  icon: {
    height: '100%',
    width: '100%',
    maxHeight: '1.125rem',
    maxWidth: '1.125rem',
    marginLeft: '0.75rem',
    userSelect: 'none'
  }
}))

export default function JanaInfoRow(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { fieldKey, label, value, column } = props

  const getContainerStyle = () => {
    const isLongValue = value && value.length > 35
    const illegalTypes = [
      ...InfoRowTypes.Link,
      InfoRowTypes.Price,
      InfoRowTypes.Address,
      InfoRowTypes.ConditionReport,
      InfoRowTypes.ServicesContactInformation,
      InfoRowTypes.ServiceOpenHours,
      InfoRowTypes.Links,
      InfoRowTypes.AmusementParkEquipment,
      InfoRowTypes.TourPricing,
      InfoRowTypes.EquipmentAdditionalInformation,
      InfoRowTypes.ExceptionSchedules,
      InfoRowTypes.Faculties,
      InfoRowTypes.Campuses,
      InfoRowTypes.ServicePhones,
      InfoRowTypes.ServiceUrls
    ]
    if (column || (isLongValue && !illegalTypes.includes(fieldKey))) {
      return classes.columnContainer
    }
    return classes.rowContainer
  }

  const getValueStyle = () => {
    const isLongValue = value && value.length > 35
    if (column || (isLongValue && !(InfoRowTypes.Link.includes(fieldKey)))) {
      return classes.columnValue
    }
    return classes.value
  }
  const openHoursModal = () => props.openModal(InfoRowTypes.OpenHours)
  const openPricingModal = () => props.openModal(InfoRowTypes.Price)
  const openConditionReportModal = () => props.openModal(InfoRowTypes.ConditionReport)
  const openSportFieldListModal = () => props.openModal(InfoRowTypes.SportFieldList)
  const openServiceContactInformationModal = () => props.openModal(InfoRowTypes.ServicesContactInformation)
  const openServiceOpenHoursModal = () => props.openModal(InfoRowTypes.ServiceOpenHours)
  const openLinksModal = () => props.openModal(InfoRowTypes.Links)
  const openTourPricingModal = () => props.openModal(InfoRowTypes.TourPricing)
  const openEquipmentAdditionalInformationModal = () => props.openModal(InfoRowTypes.EquipmentAdditionalInformation)
  const openExceptionSchedulesModal = () => props.openModal(InfoRowTypes.ExceptionSchedules)
  const openServicePhonesModal = () => props.openModal(InfoRowTypes.ServicePhones)
  const openServiceUrlsModal = () => props.openModal(InfoRowTypes.ServiceUrls)

  const renderValue = () => {
    if (fieldKey === InfoRowTypes.OpenHours) {
      const openStatus = value ? getPlaceOpenStatus(value) : 'closed_alt'
      const opens = getTodaysOpeningHour(value)
      const closes = getTodaysClosingHour(value)
      const isAfterClosingTime = getIsCurrentTimeAfterClosingTime(value)

      let text
      if (openStatus === 'open' && closes) {
        const closes = getTodaysClosingHour(value)
        text = `${t('clock')} ${closes} ${t('until')}`
      } else if (opens && !isAfterClosingTime) {
        text = `${t('opens_at')} ${t('clock_short')} ${opens}`
      } else {
        text = t('closed_alt')
      }

      return (
        <ButtonBase className={classes.buttonContainer} onClick={openHoursModal}>
          <p className={classes.linkValue}>{text}</p>
        </ButtonBase>
      )
    } else if (fieldKey === InfoRowTypes.Price) {
      if (value === 'free') {
        return <p className={classes.value}>{t('free')}</p>
      }
      return (
        <ButtonBase className={classes.buttonContainer} onClick={openPricingModal}>
          <p className={classes.linkValue}>{t('show_price_information')}</p>
        </ButtonBase>
      )
    } else if (fieldKey === InfoRowTypes.ConditionReport) {
      return (
        <ButtonBase className={classes.buttonContainer} onClick={openConditionReportModal}>
          <p className={classes.linkValue}>{t('show_report')}</p>
        </ButtonBase>
      )
    } else if (fieldKey === InfoRowTypes.SportFieldList) {
      return (
        <ButtonBase className={classes.buttonContainer} onClick={openSportFieldListModal}>
          <p className={classes.linkValue}>{t('show_sport_fields')} ({value.length ?? 0})</p>
        </ButtonBase>
      )
    } else if (fieldKey === InfoRowTypes.ServicesContactInformation) {
      return (
        <ButtonBase className={classes.buttonContainer} onClick={openServiceContactInformationModal}>
          <p className={classes.linkValue}>{t('view')}</p>
        </ButtonBase>
      )
    } else if (fieldKey === InfoRowTypes.ServiceOpenHours) {
      return (
        <ButtonBase className={classes.buttonContainer} onClick={openServiceOpenHoursModal}>
          <p className={classes.linkValue}>{t('view')}</p>
        </ButtonBase>
      )
    } else if (fieldKey === InfoRowTypes.Links) {
      return (
        <ButtonBase className={classes.buttonContainer} onClick={openLinksModal}>
          <p className={classes.linkValue}>{t('view')}</p>
        </ButtonBase>
      )
    } else if (fieldKey === InfoRowTypes.TourPricing) {
      return (
        <ButtonBase className={classes.buttonContainer} onClick={openTourPricingModal}>
          <p className={classes.linkValue}>{t('show_price_information')}</p>
        </ButtonBase>
      )
    } else if (fieldKey === InfoRowTypes.EquipmentAdditionalInformation) {
      return (
        <ButtonBase className={classes.buttonContainer} onClick={openEquipmentAdditionalInformationModal}>
          <p className={classes.linkValue}>{t('view')}</p>
        </ButtonBase>
      )
    } else if (fieldKey === InfoRowTypes.ExceptionSchedules) {
      return (
        <ButtonBase className={classes.buttonContainer} onClick={openExceptionSchedulesModal}>
          <p className={classes.linkValue}>{t('view')}</p>
        </ButtonBase>
      )
    } else if (fieldKey === InfoRowTypes.ServicePhones) {
      return (
        <ButtonBase className={classes.buttonContainer} onClick={openServicePhonesModal}>
          <p className={classes.linkValue}>{t('view')}</p>
        </ButtonBase>
      )
    } else if (fieldKey === InfoRowTypes.ServiceUrls) {
      return (
        <ButtonBase className={classes.buttonContainer} onClick={openServiceUrlsModal}>
          <p className={classes.linkValue}>{t('view')}</p>
        </ButtonBase>
      )
    }

    return <p className={getValueStyle()}>{value}</p>
  }

  return (
    <>
      <div className={getContainerStyle()}>
        <p className={classes.key}>{label}</p>
        {renderValue()}
      </div>
    </>
  )
}

import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import get from 'lodash/get'
import { formatJanaFields } from '../Utils/jana'
import { compareItemDistances } from '../Utils/map'

export default class SaunaStore {
  rootStore

  saunas = []
  sauna = null
  feedbacks = []
  savedFilters = []
  loading = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setSaunas = (saunas) => { this.saunas = saunas }
  setSauna = (sauna) => { this.sauna = sauna }
  setFeedbacks = (feedbacks) => { this.feedbacks = feedbacks }
  setSavedFilters = (savedFilters) => { this.savedFilters = savedFilters }
  setLoading = (loading) => { this.loading = loading }

  async getSaunas() {
    this.setSaunas([])
    this.setLoading(true)
    try {
      const locale = get(this.rootStore, 'appStore.locale')
      const response = await Api.getMainEntities(
        { mainEntityTypes: ['sauna'] },
        locale
      )
      if (response.ok) {
        let saunas = response.data.map((sauna) => ({ ...sauna, fields: formatJanaFields(sauna) })).sort((a, b) => {
          return compareItemDistances(this.rootStore.appStore.location, a, b)
        })
        if (saunas.length) {
          this.setSaunas(saunas)
        }
      } else {
        // showErrorMessage()
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getSauna(id) {
    this.setLoading(true)
    this.setSauna(null)
    try {
      const locale = get(this.rootStore, 'appStore.locale')
      const response = await Api.getMainEntity(id, locale)
      if (response.ok) {
        const sauna = response.data
        this.setSauna(sauna)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}

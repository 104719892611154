import { createContext, useContext } from 'react'
import AppStore from './AppStore'
import MapStore from './MapStore'
import TransportationStore from './TransportationStore'
import CouponStore from './CouponStore'
import AttractionStore from './AttractionStore'
import RestaurantStore from './RestaurantStore'
import EventStore from './EventStore'
import MoveStore from './MoveStore'
import CultureStore from './CultureStore'
import RecycleStore from './RecycleStore'
import MajorEventStore from './MajorEventStore'
import SportStore from './SportStore'
import SaunaStore from './SaunaStore'
import FeedbackStore from './FeedbackStore'

class RootStore {
  appStore
  mapStore
  transportationStore
  couponStore
  attractionStore
  restaurantStore
  eventStore
  moveStore
  cultureStore
  recycleStore
  majorEventStore
  sportStore
  saunaStore
  feedbackStore

  constructor() {
    this.appStore = new AppStore(this)
    this.mapStore = new MapStore(this)
    this.transportationStore = new TransportationStore(this)
    this.couponStore = new CouponStore(this)
    this.attractionStore = new AttractionStore(this)
    this.restaurantStore = new RestaurantStore(this)
    this.eventStore = new EventStore(this)
    this.moveStore = new MoveStore(this)
    this.cultureStore = new CultureStore(this)
    this.recycleStore = new RecycleStore(this)
    this.majorEventStore = new MajorEventStore(this)
    this.sportStore = new SportStore(this)
    this.saunaStore = new SaunaStore(this)
    this.feedbackStore = new FeedbackStore(this)
  }
}

export const createStore = () => {
  const rootStore = new RootStore()
  return rootStore
}

export const StoreContext = createContext({})
export const StoreProvider = StoreContext.Provider

export const useStore = () => useContext(StoreContext)

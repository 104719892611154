import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import fi from './Translations/fi.json'
import en from './Translations/en.json'

// Translations
const resources = {
  fi: { translation: fi },
  en: { translation: en }
}

i18n
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Init i18next
  // For all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'fi',
    fallbackLng: 'fi',
    interpolation: {
       // Not needed for react as it escapes by default
      escapeValue: false
    }
  })


export default i18n

import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, AppContent, AppHeader, Map, MapInfoCard } from '../Components'
import { useStore } from '../Models/RootStore'
import { getDistanceFormatted } from '../Utils/map'
import { Colors } from '../Utils/theme'
import { getEntityCenterCoords } from '../Utils/jana'

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    maxWidth: 'calc(67.5rem - 6rem)',
    position: 'absolute',
    bottom: '2rem',
    left: '3rem',
    right: '3rem',
    margin: 'auto'
  },
  container: {
    padding: '2rem 2rem 20rem'
  },
  photoWrapper: {
    boxSizing: 'border-box',
    resize: 'horizontal',
    overflow: 'hidden',
    maxWidth: '100%'
  },
  photo: {
    backgroundColor: 'white',
    width: '100%',
    paddingBottom: '45%', //'56.25%',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  h1: {
    color: Colors.white,
    fontSize: '3rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    margin: '1rem 0 0',
    lineHeight: 1.2
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 800
  },
  subTitle: {
    fontSize: '1.875rem',
    color: Colors.white70,
    marginBottom: '2rem'
  }
}))

function SportsMapScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { sportStore, appStore, mapStore } = useStore()
  const sports = sportStore?.filteredSports

  const [sport, setSport] = useState(null)

  const clearSport = () => setSport(null)

  useEffect(() => {
    if (sportStore.sport) {
      handleSetSport(sportStore.sport)
    }
  }, [])

  const handleSetSport = (item) => {
    setSport(item)
    const coords = getEntityCenterCoords(item)
    mapStore.getDirections(appStore.location, coords)
  }

  const toActiveSportDetails = () => {
    sportStore.setSport(sport)
    navigate(`/sports/${sport.id}`)
  }

  const renderPhoto = () => {
    const photo = sport?.photo
    if (!photo) return null
    const bgStyle = photo ? { backgroundImage: `url(${photo})` } : {}
    return (
      <div className={classes.photo} style={bgStyle} />
    )
  }

  const renderDistance = () => {
    const coords = getEntityCenterCoords(sport)
    const distance = getDistanceFormatted(appStore.location, coords)
    if (distance) {
      return (
        <div className={classes.subTitle}>{t('distance')} {distance}</div>
      )
    }
    return null
  }

  const renderInfo  = () => {
    if (!sport) return null
    return (
      <div className={classes.cardContainer}>
        <MapInfoCard onClose={clearSport}>
          {renderPhoto()}
          <h1 className={classes.h1}>{appStore.locale === 'en' ? sport?.nameEn : sport?.nameFi}</h1>
          {renderDistance()}
          <Button
            text={t('view_details')}
            onClick={toActiveSportDetails}
            fullWidth
          />
        </MapInfoCard>
      </div>
    )
  }

  const getLocation = () => {
    if (appStore.location) {
      return {
        lat: appStore.location.latitude,
        lng: appStore.location.longitude
      }
    }
    return null
  }

  const getMarkerType = (item) => {
    const mainEntityType = item?.mainEntityType?.type
    const geoType = item?.geo?.geometry?.type
    if (geoType !== 'Point') return null

    if (mainEntityType === 'basketball') return 'basketball'
    if (mainEntityType === 'outdoor_gym') return 'gym'
    if (mainEntityType === 'tennis') return 'tennis'
    if (mainEntityType === 'padel') return 'padel'
    if (mainEntityType === 'skatepark') return 'skate'
    if (mainEntityType === 'disc_golf') return 'disc_golf'
    return 'sport'
  }

  const getMarkers = () => {
    if (sport) {
      const coords = getEntityCenterCoords(sport)
      const markerType = getMarkerType(sport)
      if (!markerType) return []
      return [
        {
          type: markerType,
          lat: coords.latitude,
          lng: coords.longitude,
        }
      ]
    }
    // No sport selected, display all
    return sportStore.filteredSports.map(item => {
      const coords = getEntityCenterCoords(item)
      if (coords) {
        return {
          type: getMarkerType(item),
          lat: coords.latitude,
          lng: coords.longitude,
          onClick: () => handleSetSport(item)
        }
      }
      return null
    }).filter(val => !!val && val.lat && val.lng)
  }

  const convertGeoToRoute = (item) => {
    try {
      const coords = (item?.geo?.geometry?.coordinates || []).map(coordset => {
        return {
          latitude: coordset[1],
          longitude: coordset[0],
          onClick: () => setSport(item)
        }
      })
      return coords
    } catch (err) {
      console.log(err)
    }

    return []
  }

  const getRoutes = () => {
    const allRoutes: any = []

    const validSports = sports.filter(item => {
      const geoType = item.geo?.geometry?.type
      if (geoType === 'LineString') return true
      return false
    })

    if (validSports.length) {
      for (const item of validSports) {
        const coords = convertGeoToRoute(item)
        if (coords.length) {
          allRoutes.push(coords)
        }
      }
    }
    return allRoutes
  }

  const getDirections = () => {
    const markers = getMarkers()
    if (mapStore.directions && markers.length === 1) {
      return [
        mapStore.directions
      ]
    }
    return []
  }

  const handleRouteClick = (index) => {
    const validSports = sports.filter(item => {
      const geoType = item.geo?.geometry?.type
      if (geoType === 'LineString') return true
      return false
    })

    // Only allow route clicks when there is no markers visible
    // Direction routes are always last, so ignore those
    if (index < validSports.length) {
      const item = validSports[index] || null
      setSport({ ...item, index })
      return true // Trigger focus effect
    }
    return false // Do not trigger focus event
  }

  return (
    <div>
      <AppHeader title={t('sports_venues')} />
      <AppContent fixed>
        <Map
          location={getLocation()}
          markers={getMarkers()}
          routes={[
            ...getRoutes(),
            ...getDirections()
          ]}
          onRouteClick={handleRouteClick}
        />
        {renderInfo()}
      </AppContent>
    </div>
  )
}

export default observer(SportsMapScreen)

import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStore } from '../Models/RootStore'
import ButtonBase from '@mui/material/ButtonBase'
import { AppContent, AppHeader, JanaInfoRow, JanaInfoModal, ToggleTabs, Button, QrCodeModal } from '../Components'
import {
  getFormattedLayout,
  getFormattedJanaFieldValue,
  getEntityCenterCoords,
   calculateDistance
} from '../Utils/jana'
import { Colors } from '../Utils/theme'
import { getMapsLink } from '../Utils/map'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0rem 3rem 20rem'
  },
  photo: {
    height: '56.25vw',
    maxHeight: 'calc(67.5rem * 0.5625)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '3.75rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    color: Colors.white,
    margin: 0,
    marginTop: '2rem'
  },
  distance: {
    fontFamily: 'Manrope',
    fontSize: '2.5rem',
    color: Colors.white70,
    margin: 0,
    marginTop: '0.5rem',
    marginBottom: '1.5rem'
  },
  description: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70,
    marginBottom: '2.5rem'
  },
  subtitleContainer: {
    width: '100%',
    borderBottom: `2px solid ${Colors.white70}`,
    paddingBottom: '1rem',
    marginTop: '2.5rem',
    marginBottom: '2.5rem'
  },
  subtitle: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70,
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  tabs: {
    marginTop: '2.5rem',
    marginBottom: '2.5rem'
  },
  linkContainer: {
    marginBottom: '0.25rem'
  },
  link: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    fontWeight: 800,
    color: Colors.white
  },
  footer: {
    maxWidth: 'calc(67.5rem - 6rem)',
    position: 'absolute',
    bottom: '2.5rem',
    left: '3rem',
    right: '3rem',
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto'
  },
  leftButton: {
    flex: 1,
    marginRight: '1.5rem'
  },
  rightButton: {
    flex: 1,
    marginLeft: '1.5rem'
  }
}))

function SaunaScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { saunaStore, appStore } = useStore()
  const navigate = useNavigate()
  const { location } = appStore
  const { sauna } = saunaStore

  const [tab, setTab] = useState('general')
  const [modalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [routeModalVisible, setRouteModalVisible] = useState(false)

  const openModal = (fieldKey) => {
    setModalVisible(true)
    setModalType(fieldKey)
  }
  const closeModal = () => {
    setModalVisible(false)
    setModalType(null)
  }

  const toggleRouteModal = () => setRouteModalVisible(!routeModalVisible)
  const toMap = () => navigate('/saunas-map')

  const viewFullDescription = () => setTab('description')

  const getDescription = () => {
    if (sauna?.description?.length) {
      let description = sauna?.description.replace(/\n{2,}\s*/g, '\n')
      if (description.length > 235) {
        return `${description.substring(0, 235).trim()}...`
      }
      return description
    }
    return null
  }

  const getFullDescription = () => {
    if (sauna?.description?.length) {
      let description = sauna?.description.replace(/\n{2,}\s*/g, '\n')
      return description
    }
    return null
  }

  const getFieldValue = (field) => getFormattedJanaFieldValue(t, field)

  const renderPhoto = () => {
    if (sauna?.photo) {
      return (
        <div
          className={classes.photo}
          style={{ backgroundImage: `url(${sauna.photo})` }}
        />
      )
    }
    return null
  }

  const renderDistance = () => {
    if (location) {
      const coords = getEntityCenterCoords(sauna)
      if (coords) {
        const distance: any = calculateDistance(location, coords)
        if (distance || distance === 0) {
          return (
            <p className={classes.distance}>
              {t('distance')} {distance > 0 ? distance.toFixed(1).replace('.', ',') : 0}km
            </p>
          )
        }
      }
    }
    return null
  }

  const renderDescriptionReadMore = () => {
    if (sauna?.description?.length > 235) {
      return (
        <ButtonBase className={classes.linkContainer} onClick={viewFullDescription}>
          <p className={classes.link}>{t('read_more')}</p>
        </ButtonBase>
      )
    }
    return null
  }

  const renderDescription = () => {
    const description = getDescription()
    if (description) {
      return (
        <div className={classes.description}>
          {description} {renderDescriptionReadMore()}
        </div>
      )
    }
    return null
  }

  const renderFullDescription = () => {
    const description = getFullDescription()
    if (description) {
      return <div className={classes.description}>{description}</div>
    }
    return null
  }

  const renderGeneralInformation = () => {
    const layout = getFormattedLayout(t, sauna)

    return layout.map((field, index) => {
      if (field.title) {
        return (
          <div key={field.title} className={classes.subtitleContainer}>
            <p className={classes.subtitle}>{field.rawTitle || t(field.title)}</p>
          </div>
        )
      }
      return (
        <JanaInfoRow
          key={`${field.fieldKey}-${index}`}
          fieldKey={field.fieldKey}
          label={field.label}
          value={getFieldValue(field)}
          openModal={openModal}
        />
      )
    })
  }

  const renderTabContent = () => {
    if (tab === 'general') {
      return (
        <>
          {renderDescription()}
          {renderGeneralInformation()}
        </>
      )
    } else {
      return renderFullDescription()
    }
  }

  const renderFooter = () => {
    return (
      <div className={classes.footer}>
        <div className={classes.leftButton}>
          <Button
            text={t('download_route')}
            onClick={toggleRouteModal}
            outlined
            fullWidth
          />
        </div>
        <div className={classes.rightButton}>
          <Button
            text={t('view_on_map')}
            onClick={toMap}
            outlined
            fullWidth
          />
        </div>
      </div>
    )
  }

  const renderModal = () => {
    if (modalVisible) {
      return (
        <JanaInfoModal
          type={modalType}
          fields={sauna?.fields || []}
          onClose={closeModal}
          visible
        />
      )
    }
    return null
  }

  const renderRouteModal = () => {
    const coords = getEntityCenterCoords(sauna)
    let code: any = null

    if (coords && coords.latitude && coords.longitude) {
      code = getMapsLink(coords.latitude, coords.longitude)
    }

    if (code) {
      return (
        <QrCodeModal
          title={t('download_route_to_your_phone')}
          onCancel={toggleRouteModal}
          open={routeModalVisible}
          code={code}
        />
      )
    }
    return null
  }

  return (
    <div>
      <AppHeader title={t('public_saunas')} />
      <AppContent>
        {renderPhoto()}
        <div className={classes.content}>
          <h1 className={classes.title}>{sauna?.name ?? ''}</h1>
          {renderDistance()}
          <div className={classes.tabs}>
            <ToggleTabs
              items={[
                { name: t('general_information'), value: 'general' },
                { name: t('story'), value: 'description' }
              ]}
              value={tab}
              onChange={setTab}
            />
          </div>
          {renderTabContent()}
        </div>
        {renderFooter()}
      </AppContent>
      {renderModal()}
      {renderRouteModal()}
    </div>
  )
}

export default observer(SaunaScreen)

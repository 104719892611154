import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import Api from '../Services/Api'
import { filterRoutes } from '../Utils/route'

export default class MoveStore {
  rootStore
  loading = false

  routes = []
  route = null
  nearbyStops = []
  stop: any = null

  start = ''
  end = ''
  startPlace = null
  endPlace = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  resetState = () => {
    this.loading = false
    this.routes = []
    this.route = null
    this.nearbyStops = []
    this.stop = null
    this.start = ''
    this.end = ''
    this.startPlace = null
    this.endPlace = null
  }

  setStart = (start) => { this.start = start }
  setEnd = (end) => { this.end = end }
  setStartPlace = (startPlace) => { this.startPlace = startPlace }
  setEndPlace = (endPlace) => { this.endPlace = endPlace }


  setRoutes = (routes) => { this.routes = routes }
  setRoute = (route) => { this.route = route }
  setNearbyStops = (nearbyStops) => { this.nearbyStops = nearbyStops }
  setStop = (stop) => { this.stop = stop }
  setLoading = (loading) => { this.loading = loading }

  async getRoute(from, to) {
    this.setRoutes([])
    this.setLoading(true)
    const formattedDate = moment().format('YYYY-MM-DD')
    const time = moment('HH:mm')

    const response = await Api.getRoute(from, to, time, formattedDate, false)
    if (response.ok && response.data) {
      const data = get(response.data, 'data.plan.itineraries')
      // Filter out duplicate walk-only routes
      const routes = filterRoutes(data)
      this.setRoutes(routes)
    }
    this.setLoading(false)
  }

  async getNearbyStops() {
    try {
      let location
      const appStoreLocation = get(this.rootStore, 'appStore.location')
      if (appStoreLocation && appStoreLocation.latitude && appStoreLocation.longitude) {
        location = {
          lat: appStoreLocation.latitude,
          lng: appStoreLocation.longitude
        }
      }
      if (location) {
        const response = await Api.getNearbyStops(location)
        if (response.ok && response.data) {
          const data = get(response.data, 'data.stopsByRadius.edges', [])
          const stops = data.map((item) => ({
            ...get(item, 'node.stop'),
            type: get(item, 'node.stop')?.vehicleMode?.toLowerCase() ?? null
          }))
          this.setNearbyStops(stops)
        } else {
          // TODO: error message?
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  selectRoute(route) {
    this.setRoute(route)
  }

  async getStopTimetable(stop) {
    this.setLoading(true)
    const response = await Api.getStop(stop.gtfsId)
    if (response.ok && response.data) {
      const departures = get(response.data, 'data.stop.stoptimesWithoutPatterns', null)
      let timetable = get(response.data, 'data.stop.stoptimesForServiceDate', null)
      timetable = timetable.filter((item) => item.stoptimes.length).map((item) => item.stoptimes)
      timetable = flatten(timetable)
      /*
      if (!this.allLines.length) {
        await this.getLines()
      }
      */
      this.setStop({
        ...this.stop,
        departures: departures.map((line) => ({
          realtimeDeparture: line.realtimeDeparture,
          lineShortName: line?.trip?.routeShortName,
          // ...this.allLines.find((l) => l.shortName === line.trip.routeShortName),
          headsign: line.headsign
        })),
        timetable: orderBy(timetable, 'realtimeArrival')
      })
    } else {
      // showErrorMessage()
    }
    this.setLoading(false)
  }
}

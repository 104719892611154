import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppHeader, AppContent, Map } from '../Components'
import { useStore } from '../Models/RootStore'
import { extractRoutePartCoords } from '../Utils/route'

const useStyles = makeStyles((theme) => ({
  container: {
  }
}))

function RouteGuideMapScreen() {
  const classes = useStyles()
  const { moveStore, appStore } = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { route } = moveStore

  const toRoutes = () => navigate(-1)

  const getRoutes = () => {
    const routesCoords = extractRoutePartCoords(route)
    return routesCoords
  }

  const getLocation = () => {
    if (appStore.location) {
      return {
        lat: appStore.location.latitude,
        lng: appStore.location.longitude
      }
    }
  }

  const renderMap = () => {
    return (
      <Map
        location={getLocation()}
        routes={getRoutes()}
        defaultZoom={18}
      />
    )
  }

  return (
    <div className={classes.container}>
      <AppHeader title={t('public_transport')} />
      <AppContent fixed>
        {renderMap()}
      </AppContent>
    </div>
  )
}

export default observer(RouteGuideMapScreen)

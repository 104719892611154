import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import striptags from 'striptags'
import { AppContent, AppHeader } from '../Components'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '2rem 2rem 20rem'
  },
  photo: {
    height: '56.25vw',
    maxHeight: 'calc(67.5rem * 0.5625)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  h1: {
    fontSize: '3.75rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    margin: '1rem 0 2rem'
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 800
  },
  subTitle: {
    fontSize: '2.5rem',
    color: Colors.white70,
    marginBottom: '2rem'
  },
  description: {
    marginBottom: '1rem',
    fontSize: '1.875rem',
    color: Colors.white70
  },
  smallTitle: {
    fontSize: '1.875rem',
    fontWeight: 800,
    color: Colors.white,
    marginTop: '2rem',
    marginBottom: '1rem'
  },
  category: {
    display: 'inline-block',
    backgroundColor: Colors.appGrey,
    color: Colors.white,
    fontSize: '1.875rem',
    borderRadius: '.3125rem',
    padding: '.66rem .85rem'
  }
}))

function TipScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { eventStore, appStore } = useStore()

  const renderPhoto = () => {
    const photo = eventStore?.tip?.image
    if (photo) {
      return (
        <div
          className={classes.photo}
          style={{
            backgroundImage: `url(${photo})`
          }}
        />
      )
    }
  }

  const renderCategory = () => {
    let place = null
    const categoryInfos = get(eventStore?.tip, 'categories[0].info')
    const matchCategory = categoryInfos.find(info => info.language === appStore.locale)
    if (matchCategory) place = matchCategory.name || ''

    if (place) {
      return (
        <div>
          <div className={classes.smallTitle}>{t('category')}</div>
          <div className={classes.category}>{place}</div>
        </div>
      )
    }
    return null
  }

  const renderContent = () => {
    if (!eventStore?.tip) return null

    let title = eventStore?.tip?.title
    if (title) title = title.replace(' - Visit Tampere', '')

    const descriptions = striptags(eventStore?.tip?.content, ['p']).replace(/<\/p>/ig, '').split(/<p>/ig).map(item => {
      return item.trim()
    })
    return (
      <div>
        {renderPhoto()}
        <div className={classes.content}>

          <h1 className={classes.h1}>{title}</h1>
          {descriptions.map(description => (<div className={classes.description}>{description}</div>))}

          {renderCategory()}
        </div>
      </div>
    )
  }

  return (
    <div>
      <AppHeader title={t('tips')} />
      <AppContent>
        {renderContent()}
      </AppContent>
    </div>
  )
}

export default observer(TipScreen)

import { makeAutoObservable } from 'mobx'
import Api from '../Services/Api'
import { compareItemDistances } from '../Utils/map'

export default class AttractionStore {
  rootStore
  loading = false

  attractions = []
  attraction = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading  }
  setAttractions = (attractions) => { this.attractions = attractions }
  setAttraction = (attraction) => { this.attraction = attraction }

  async getAttractions() {
    this.setLoading(true)
    const response = await Api.getAttractions()
    if (response.ok) {
      const attractions = response.data.sort((a, b) => {
        return compareItemDistances(this.rootStore.appStore.location, a, b)
      })
      this.setAttractions(attractions)
    }
    this.setLoading(false)
  }
}

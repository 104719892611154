import React from 'react'
import moment from 'moment'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) =>({
  root: {
    marginBottom: '5rem'
  },
  row: {
    backgroundColor: Colors.appGreyDark,
    border: 'none'
  },
  evenRow: {
    backgroundColor: Colors.appGreyDarkest,
    border: 'none'
  },
  firstCell: {
    fontFamily: 'Manrope',
    color: Colors.white70,
    fontSize: '1.625rem',
    fontWeight: 600,
    height: '5rem',
    borderBottom: 'none'
  },
  lastCell: {
    fontFamily: 'Manrope',
    color: Colors.white70,
    fontSize: '1.625rem',
    fontWeight: 600,
    height: '5rem',
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontFamily: 'Manrope',
    color: Colors.white70,
    fontSize: '1.625rem',
    fontWeight: 600,
    height: '5rem',
    borderBottom: 'none'
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: Colors.white
  }
}))

function FlightTableRow (props: any) {
  const { item, odd, locale } = props
  const classes = useStyles()

  const getRoute = () => {
    if (locale === 'fi') {
      if (item.routesFi && item.routesFi.length) {
        return item.routesFi.join(' - ')
      }
    }
    return item.routesEn.join(' - ')
  }

  return (
    <TableRow classes={{ root: odd ? classes.row : classes.evenRow }}>
      <TableCell className={classes.firstCell} size='small'>
        {getRoute()}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {item.flightNumber}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {item.checkInArea || '-'}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {item.gate || '-'}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {moment(item.date).format('DD.MM.YYYY')}
      </TableCell>
      <TableCell className={classes.lastCell} size='small'>
        {moment(item.date).format('HH:mm')}
      </TableCell>
    </TableRow>
  )
}

function FlightTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item, index) => (
        <React.Fragment key={`${item.id}-${index}`}>
          <FlightTableRow
            item={item}
            locale={props.locale}
            type={props.type}
            odd={!(index % 2)}
          />
        </React.Fragment>
      ))
    }
    return null
  }

  const getHeaderColumns = () => {
    if (props.type === 'dep') {
      return [t('destination'), t('flight_number'), t('terminal'), t('gate'), t('date'), t('departure_time')]
    }
    if (props.type === 'arr') {
      return [t('origin'), t('flight_number'), t('terminal'), t('gate'), t('date'), t('arrival_time')]
    }
  }

  const renderMessage = () => {
    if (!props.loading) {
      return <DataMessage data={props.items} message={t('no_info')} />
    }
    return <CircularProgress size='5rem' classes={{ root: classes.loader }} />
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader
          columnNames={getHeaderColumns()}
        />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {renderMessage()}
    </>
  )

}

export default FlightTable

import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { get } from 'lodash'
import { AppHeader, AppContent, Button, GooglePlacesAutocomplete, RouteGuideItem } from '../Components'
import Input from '../Components/Common/Input'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'
import CrosshairIcon from '../Assets/Icons/crosshair.svg'
import SwapIcon from '../Assets/Icons/swap.svg'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles((theme) => ({
  container: {
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: Colors.white
  },
  row: {
    padding: '0 1rem 1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    padding: '1rem',
    flex: 1
  },
  header: {
    padding: '1rem 3rem 2rem'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '3.125rem'
  },
  description: {
    fontSize: '2rem',
    color: Colors.content
  },
  buttonsRow: {
    paddingTop: '2rem',
    display: 'flex',
    flexDirection: 'row'
  },
  leftButton: {
    flex: 1,
    paddingRight: '1rem'
  },
  rightButton: {
    flex: 1,
    paddingLeft: '1rem'
  },
  swapper: {
    position: 'absolute',
    top: '-4.5rem',
    right: '6rem',
    backgroundColor: Colors.appGrey,
    height: '7rem',
    width: '7rem',
    borderRadius: '7.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:active': {
      backgroundColor: Colors.appGreyDark
    }
  },
  swapIcon: {
    height: '3rem'
  },
  inputContainer: {
    position: 'relative'
  },
  autocompleteContainer: {
    zIndex: 2,
    position: 'absolute',
    top: '7.5rem',
    left: 0,
    right: 0
  },
  routesContainer: {
    padding: '2rem 0 0'
  },
  routeContainer: {
    marginTop: '2rem',
  },
  headerSpacer: {
    height: '4rem'
  },
  smallSpacer: {
    height: '1.75rem'
  }
}))

function RouteGuideScreen() {
  const classes = useStyles()
  const { moveStore, appStore } = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    start,
    setStart,
    end,
    setEnd,
    startPlace,
    setStartPlace,
    endPlace,
    setEndPlace,
    route,
    setRoute
  } = moveStore

  const [startFocused, setStartFocused] = useState(false)
  const [endFocused, setEndFocused] = useState(false)

  useEffect(() => {
    setDefaultStartPlace()
  }, [])

  const setDefaultStartPlace = () => {
    if (appStore.location && appStore.screen && !start && !end) {
      const screenName = (appStore?.locale === 'en' ? appStore?.screen?.nameEn : appStore?.screen.name) || appStore?.screen?.name
      const place = {
        coords: {
          lat: appStore.location?.latitude,
          lng: appStore.location?.longitude
        },
        longName: screenName,
        name: screenName
      }
      setStart(screenName)
      setStartPlace(place)
    }
  }

  const clearStart = () => {
    moveStore.setRoutes([])
    setStartPlace(null)
    setStart('')
  }

  const clearEnd = () => {
    moveStore.setRoutes([])
    setEndPlace(null)
    setEnd('')
  }

  const handleSwap = () => {
    const oldStart = start
    const oldStartPlace = startPlace
    const oldEnd = end
    const oldEndPlace = endPlace

    // Clear old route
    moveStore.setRoutes([])

    // Handle search
    const searchStart = oldEndPlace
    const searchEnd = oldStartPlace
    // Load route
    if (get(searchStart, 'coords') && get(searchEnd, 'coords')) {
      moveStore.getRoute(
        get(searchStart, 'coords'),
        get(searchEnd, 'coords'),
      )
    }

    setStart(oldEnd)
    setStartPlace(oldEndPlace)
    setEnd(oldStart)
    setEndPlace(oldStartPlace)

  }

  const selectRoute = (selectedRoute) => {
    const route = { ...selectedRoute, start, end }
    setRoute(route)
    navigate('/route-guide-map')
  }

  const handleSetStartPlace = (place) => {
    setStart('')
    setStartPlace(place)
    setStartFocused(false)
    if (place && endPlace) {
      handleSearch(place, endPlace)
    }
  }

  const handleSetEndPlace = (place) => {
    setEnd('')
    setEndPlace(place)
    setEndFocused(false)
    if (place && startPlace) {
      handleSearch(startPlace, place)
    }
  }

  const toMap = () => navigate('/stops')

  const handleSearch = (inputStart = null, inputEnd = null) => {
    const searchStart = inputStart || startPlace
    const searchEnd = inputEnd || endPlace
    // Load route
    if (get(searchStart, 'coords') && get(searchEnd, 'coords')) {
      moveStore.getRoute(
        get(searchStart, 'coords'),
        get(searchEnd, 'coords'),
      )
    }
  }

  const getStartValue  = () => {
    if (start) return start
    if (startPlace) return startPlace?.name || startPlace?.longName || ''
    return ''
  }

  const getEndValue  = () => {
    if (end) return end
    if (endPlace) return endPlace?.name || endPlace?.longName || ''
    return ''
  }

  const renderRoutes = () => {
    if (moveStore.loading) {
      return <CircularProgress size='5rem' classes={{ root: classes.loader }} />
    }

    return moveStore.routes.map((item, index) => {
      return (
        <div key={index} className={classes.routeContainer}>
          <RouteGuideItem
            item={item}
            type='bus'
            start={startPlace}
            end={endPlace}
            onClick={selectRoute}
          />

        </div>
      )
    })
  }

  const renderSwapper = () => {
    if (!start && !end) return null
    return (
      <div className={classes.swapper} onClick={handleSwap}>
        <img src={SwapIcon} className={classes.swapIcon} />
      </div>
    )
  }

  const isAirport = () => {
    const screen = appStore.screen
    if (
      screen &&
      screen.name &&
      (
        screen.name.toLowerCase().includes('lentoasema') ||
        screen.name.toLowerCase().includes('lentokenttä')
      )
    ) {
      return true
    }
    return false
  }

  // TODO: show different info if screen is at the airport
  const renderForm = () => {
    return (
      <div className={classes.header}>
        <h1 className={classes.title}>{t('public_transport_title')}</h1>
        <p className={classes.description}>{t('public_transport_pre_info')}</p>
        <div className={classes.smallSpacer} />
        <p className={classes.description}>{(isAirport() ? t('public_transport_airport_info') : t('public_transport_info'))}</p>
        <div className={classes.headerSpacer} />
        <div className={classes.inputContainer}>
          <Input
            value={getStartValue()}
            onChange={setStart}
            placeholder={t('where_from')}
            icon={CrosshairIcon}
            focused={startFocused}
            setFocused={setStartFocused}
            onClear={clearStart}
            adjustUp={50}
          />
          <div className={classes.autocompleteContainer}>
            <GooglePlacesAutocomplete
              text={start}
              open={startFocused}
              onSelect={handleSetStartPlace}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <Input
            value={getEndValue()}
            onChange={setEnd}
            placeholder={t('where_to')}
            icon={CrosshairIcon}
            focused={endFocused}
            setFocused={setEndFocused}
            onClear={clearEnd}
            adjustUp={200}
          />
          {renderSwapper()}
          <div className={classes.autocompleteContainer}>
            <GooglePlacesAutocomplete
              text={end}
              open={endFocused}
              onSelect={handleSetEndPlace}
            />
          </div>
        </div>
        <div className={classes.buttonsRow}>
          <div className={classes.leftButton}>
            <Button
              text={t('search')}
              onClick={handleSearch}
              outlined
              fullWidth
            />
          </div>
          <div className={classes.rightButton}>
            <Button
              text={t('open_map')}
              onClick={toMap}
              outlined
              fullWidth
            />
          </div>
        </div>
        <div className={classes.routesContainer}>
          {renderRoutes()}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return renderForm()
  }

  return (
    <div className={classes.container}>
      <AppHeader title={t('public_transport')} />
      <AppContent fixed={!!route}>
        {renderContent()}
      </AppContent>
    </div>
  )
}

export default observer(RouteGuideScreen)

import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, AppContent, AppHeader, Map, MapInfoCard, DownloadAppModal } from '../Components'
import { useStore } from '../Models/RootStore'
import { getDistanceFormatted } from '../Utils/map'
import { Colors } from '../Utils/theme'

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    maxWidth: 'calc(67.5rem - 6rem)',
    position: 'absolute',
    bottom: '2rem',
    left: '3rem',
    right: '3rem',
    margin: 'auto'
  },
  container: {
    padding: '2rem 2rem 20rem'
  },
  photoWrapper: {
    boxSizing: 'border-box',
    resize: 'horizontal',
    overflow: 'hidden',
    maxWidth: '100%'
  },
  photo: {
    backgroundColor: 'white',
    width: '100%',
    paddingBottom: '45%', //'56.25%',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  h1: {
    color: Colors.white,
    fontSize: '3rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    margin: '1rem 0 0',
    lineHeight: 1.2
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 800
  },
  subTitle: {
    fontSize: '1.875rem',
    color: Colors.white70,
    marginBottom: '2rem'
  }
}))

function SightsMapScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { cultureStore, mapStore, appStore } = useStore()

  const [sight, setSight] = useState(null)

  const handleSetSight = (sight) => {
    setSight(sight)
    mapStore.getDirections(appStore.location, sight.coordinates)
  }

  useEffect(() => {
    if (cultureStore.sight) {
      handleSetSight(cultureStore.sight)
    }
  }, [])

  const toSight = (item) => {
    cultureStore.setSight(item)
    navigate(`/sights/${item.id}`)
  }

  const renderPhoto = () => {
    const photo = sight?.image
    const bgStyle = photo ? { backgroundImage: `url(${photo})` } : {}
    return (
      <div className={classes.photo} style={bgStyle} />
    )
  }



  const renderDistance = () => {
    const distance = getDistanceFormatted(appStore.location, sight.coordinates)
    if (distance) {
      return (
        <div className={classes.subTitle}>{t('distance')} {distance}</div>
      )
    }
    return null
  }

  const renderInfo  = () => {
    if (!sight) return null
    return (
      <div className={classes.cardContainer}>
        <MapInfoCard onClose={() => setSight(null)}>
          {renderPhoto()}
          <h1 className={classes.h1}>{appStore.locale === 'en' ? sight.title.en : sight.title.fi}</h1>
          {renderDistance()}
          <Button
            text={t('view_details')}
            onClick={() => toSight(sight)}
            fullWidth
          />
        </MapInfoCard>
      </div>
    )
  }

  const getLocation = () => {
    if (appStore.location) {
      return {
        lat: appStore.location.latitude,
        lng: appStore.location.longitude
      }
    }
  }

  const getMarkers = () => {
    if (sight) {
      return [
        {
          type: 'sight',
          lat: sight?.coordinates?.latitude,
          lng: sight?.coordinates?.longitude
        }
      ]
    }
    return cultureStore.sights.map(item => {
      if (item && item?.coordinates?.latitude && item?.coordinates?.longitude) {
        return {
          type: 'sight',
          lat: item?.coordinates?.latitude,
          lng: item?.coordinates?.longitude,
          onClick: () => handleSetSight(item)
        }
      }
      return null
    }).filter(v => !!v)
  }

  const getDirections = () => {
    const markers = getMarkers()
    if (mapStore.directions && markers.length === 1) {
      return [
        mapStore.directions
      ]
    }
    return []
  }

  return (
    <div>
      <AppHeader title={t('sights')} />
      <AppContent fixed>
        <Map
          location={getLocation()}
          markers={getMarkers()}
          defaultZoom={16}
          routes={getDirections()}
        />
        {renderInfo()}
      </AppContent>
    </div>
  )
}

export default observer(SightsMapScreen)

import React from 'react'
import moment from 'moment'
import { get } from 'lodash'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) =>({
  root: {
    marginBottom: '5rem'
  },
  row: {
    backgroundColor: Colors.appGreyDark,
    border: 'none'
  },
  evenRow: {
    backgroundColor: Colors.appGreyDarkest,
    border: 'none'
  },
  firstCell: {
    fontFamily: 'Manrope',
    color: Colors.white70,
    fontSize: '1.625rem',
    fontWeight: 600,
    height: '5rem',
    borderBottom: 'none'
  },
  lastCell: {
    fontFamily: 'Manrope',
    color: Colors.white70,
    fontSize: '1.625rem',
    fontWeight: 600,
    height: '5rem',
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontFamily: 'Manrope',
    color: Colors.white70,
    fontSize: '1.625rem',
    fontWeight: 600,
    height: '5rem',
    borderBottom: 'none'
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: Colors.white
  }
}))

function TrainTableRow (props: any) {
  const { item, type, odd } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const getFrom = () => {
    if (type === 'DEPARTURE') {
      return 'Tampere'
    }

    const from = get(item, 'startStation', '-') || '-'

    if (from.includes('Tampere')) return '-'

    if (from !== '-' && from.includes('asema')) {
      return from.split('asema')[0].trim()
    }

    if (from === 'Haapamäki') return 'Keuruu'

    if (from === '-') {
      const id = getTrainId()
      if (id === 'IC 265') return 'Helsinki'
      if (id === 'IC 266') return 'Rovaniemi'
      if (id === 'IC 273') return 'Helsinki'
      if (id === 'IC 274') return 'Kemijärvi'
      if (id === 'P 269') return 'Helsinki'
      if (id === 'P 272') return 'Kolari'
      if (id === 'P 276') return 'Kolari'
    }

    return from
  }

  const getTo = () => {
    if (type === 'ARRIVAL') {
      return 'Tampere'
    }
    const to = get(item, 'endStation', '-') || '-'

    if (to.includes('Tampere')) return '-'

    if (to !== '-' && to.includes('asema')) {
      return to.split('asema')[0].trim()
    }

    if (to === 'Haapamäki') return 'Keuruu'

    if (to === '-') {
      const id = getTrainId()
      if (id === 'IC 265') return 'Kemijärvi'
      if (id === 'IC 266') return 'Helsinki'
      if (id === 'IC 273') return 'Rovaniemi'
      if (id === 'IC 274') return 'Helsinki'
      if (id === 'P 269') return 'Kolari'
      if (id === 'P 272') return 'Helsinki'
      if (id === 'P 276') return 'Helsinki'
    }

    return to
  }

  const getTrainId = () => {
    const id = `${item.trainType} ${item.trainNumber}`
    if (type === 'DEPARTURE') {
      const to = get(item, 'endStation', '-') || '-'
      if (id.includes('HL')) {
        if (to === 'Nokia') return 'M'
        if (to === 'Toijala') return 'M'
        if (to.includes('Riihimäki')) return 'R'
        if (to.includes('Helsinki')) return 'R'
      }
    } else {
      const from = get(item, 'startStation', '-') || '-'
      if (id.includes('HL')) {
        if (from === 'Nokia') return 'M'
        if (from === 'Toijala') return 'M'
        if (from.includes('Riihimäki')) return 'R'
        if (from.includes('Helsinki')) return 'R'
      }
    }

    if (id.includes('HDM')) return `H ${item.trainNumber}`
    if (id.includes('PYO')) return `P ${item.trainNumber}`

    return id
  }

  const getEstimatedTime = () => {
    if (item.liveEstimateTime) {
      return moment(item.liveEstimateTime).format('HH:mm')
    }
    return moment(item.scheduledTime).format('HH:mm')
  }

  const getScheduledTime = () => {
    return moment(item.scheduledTime).format('HH:mm')
  }

  const getDuration = () => {
    let duration
    if (item.liveEstimateTime) {
      duration = moment(item.liveEstimateTime).diff(moment(), 'minutes')
    } else {
      duration = moment(item.scheduledTime).diff(moment(), 'minutes')
    }

    if (duration < 0) return t('now').toUpperCase()

    if (duration > 59) {
      const hours = Math.floor(duration / 60)
      const minutes = duration - (hours * 60)

      return `${hours} h ${minutes} min`
    }

    return `${duration} min`
  }

  return (
    <TableRow classes={{ root: odd ? classes.row : classes.evenRow }}>
      <TableCell className={classes.firstCell} size='small'>
        {getDuration()}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {type === 'DEPARTURE' ? getTo() : getFrom()}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {getTrainId()}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {getScheduledTime()}
      </TableCell>
      <TableCell className={classes.lastCell} size='small'>
        {getEstimatedTime()}
      </TableCell>
    </TableRow>
  )
}

function TrainTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items && props.items.length) {
      return props.items.map((item, index) => (
        <React.Fragment key={`${item.id}-${index}`}>
          <TrainTableRow
            item={item}
            type={props.type}
            odd={!(index % 2)}
          />
        </React.Fragment>
      ))
    }
    return null
  }

  const getHeaderColumns = () => {
    if (props.type === 'DEPARTURE') {
      return [t('leaves_in'), t('destination'), t('train_code'), t('departure_time'), t('estimated_time')]
    }
    if (props.type === 'ARRIVAL') {
      return [t('arrives_in'), t('from_where'), t('train_code'), t('arrival_time'), t('estimated_time')]
    }
  }

  const renderMessage = () => {
    if (!props.loading) {
      return <DataMessage data={props.items} message={t('no_info')} />
    }
    return <CircularProgress size='5rem' classes={{ root: classes.loader }} />
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader columnNames={getHeaderColumns()} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {renderMessage()}
    </>
  )
}

export default TrainTable

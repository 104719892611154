import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '7.875rem'
  },
  fixedContainer: {
    position: 'relative',
    paddingTop: '7.875rem',
    height: '100vh',
    overflow: 'hidden'
  }
}))

export default function AppContent(props) {
  const classes = useStyles()

  return (
    <div id='the-app-content-container' className={props.fixed ? classes.fixedContainer : classes.container}>
      {props.children}
    </div>
  )
}

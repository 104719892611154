import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, AppContent, AppHeader, QrCodeModal } from '../Components'
import { useStore } from '../Models/RootStore'
import { getDistanceFormatted, getMapsLink } from '../Utils/map'
import { Colors } from '../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0rem 3rem 20rem'
  },
  photo: {
    height: '56.25vw',
    maxHeight: 'calc(67.5rem * 0.5625)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '3.75rem',
    fontWeight: 800,
    color: Colors.white,
    textTransform: 'uppercase',
    margin: 0,
    marginTop: '2rem'
  },
  subtitle: {
    fontSize: '2.5rem',
    color: Colors.white70,
    marginBottom: '2rem'
  },
  description: {
    marginBottom: '1rem',
    fontSize: '1.875rem',
    color: Colors.white70
  },
  smallTitle: {
    fontSize: '1.875rem',
    fontWeight: 800,
    color: Colors.white,
    marginTop: '2rem',
    marginBottom: '1rem'
  },
  row: {
    padding: '.5rem 0',
    display: 'flex',
    flexDirection: 'row'
  },
  columnLeft: {
    flex: 1,
    fontSize: '1.875rem',
    fontWeight: 800,
    color: Colors.white
  },
  columnRight: {
    textAlign: 'right',
    flex: 1,
    fontSize: '1.875rem',
    color: Colors.white70
  },
  footer: {
    maxWidth: 'calc(67.5rem - 6rem)',
    position: 'absolute',
    bottom: '2.5rem',
    left: '3rem',
    right: '3rem',
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto'
  },
  leftButton: {
    flex: 1,
    marginRight: '1.5rem'
  },
  rightButton: {
    flex: 1,
    marginLeft: '1.5rem'
  }
}))

function CouponOrganizationScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { couponStore, appStore } = useStore()
  const navigate = useNavigate()

  const organization = couponStore.couponOrganization

  const [routeModalVisible, setRouteModalVisible] = useState(false)
  const toggleRouteModal = () => setRouteModalVisible(!routeModalVisible)

  const openMap = () => {
    if (organization && organization.latitude && organization.longitude) {
      navigate('/coupon-organizations-map')
    }
  }

  const renderPhoto = () => {
    const bgStyle = organization?.photo ? { backgroundImage: `url(${organization.photo})` } : {}
    return <div className={classes.photo} style={bgStyle} />
  }

  const renderDistance = () => {
    const distance = getDistanceFormatted(appStore.location, organization)
    if (distance) {
      return <div className={classes.subtitle}>{t('distance')} {distance}</div>
    }
    return null
  }

  const renderDescription = () => {
    const description = appStore.locale === 'en' ? couponStore?.couponOrganization?.contentEn : couponStore?.couponOrganization?.contentFi
    if (description) {
      return <div className={classes.description}>{description}</div>
    }
    return null
  }

  const renderPhone = () => {
    const phone = couponStore?.couponOrganization?.phone
    if (phone) {
      return (
        <div className={classes.row}>
          <div className={classes.columnLeft}>{t('phone')}</div>
          <div className={classes.columnRight}>{phone}</div>
        </div>
      )
    }
    return null
  }

  const renderUrl = () => {
    const url = couponStore?.couponOrganization?.url
    if (url) {
      return (
        <div className={classes.row}>
          <div className={classes.columnLeft}>{t('website')}</div>
          <div className={classes.columnRight}>{url}</div>
        </div>
      )
    }
    return null
  }

  const renderAddress = () => {
    const address = couponStore?.couponOrganization?.address
    if (address) {
      return (
        <div className={classes.row}>
          <div className={classes.columnLeft}>{t('address')}</div>
          <div className={classes.columnRight}>{address}</div>
        </div>
      )
    }
    return null
  }

  const renderFooter = () => {
    if (!(organization && organization.latitude && organization.longitude)) {
      return null
    }

    return (
      <div className={classes.footer}>
        <div className={classes.leftButton}>
          <Button
            text={t('download_route')}
            onClick={toggleRouteModal}
            outlined
            fullWidth
          />
        </div>
        <div className={classes.rightButton}>
          <Button
            text={t('view_on_map')}
            onClick={openMap}
            outlined
            fullWidth
          />
        </div>
      </div>
    )
  }

  const renderRouteModal = () => {
    const latitude = couponStore?.couponOrganization?.latitude
    const longitude = couponStore?.couponOrganization?.longitude
    const code = getMapsLink(latitude, longitude)

    if (code) {
      return (
        <QrCodeModal
          title={t('download_route_to_your_phone')}
          onCancel={toggleRouteModal}
          open={routeModalVisible}
          code={code}
        />
      )
    }
    return null
  }

  const renderContent = () => {
    if (organization) {
      return (
        <>
          {renderPhoto()}
          <div className={classes.container}>
            <h1 className={classes.title}>{organization.name}</h1>
            {renderDistance()}
            {renderDescription()}
            {renderPhone()}
            {renderUrl()}
            {renderAddress()}
          </div>
          {renderRouteModal()}
          {renderFooter()}
        </>
      )
    }
  }

  return (
    <div>
      <AppHeader title={t('tampere_tunnetuksi_pass')} />
      <AppContent>
        {renderContent()}
      </AppContent>
    </div>
  )
}

export default observer(CouponOrganizationScreen)

import apisauce from 'apisauce'

const LANG = 'language=fi'
const LOCATION = 'location=61.49770309,23.7599138&radius=20000&strictbounds=true'

const API_URLS = {
  Local: 'http://127.0.0.1:1337/v2/screen/googlemaps/',
  Stage: 'https://treapp.stage.geniem.io/v2/screen/googlemaps/',
  Production: 'https://treapp.production.geniem.io/v2/screen/googlemaps/'
}

let currentApi = API_URLS.Production
if (window.location.hostname.includes('.stage.geniem.io')) currentApi = API_URLS.Stage
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) currentApi = API_URLS.Local

const create = () => {
  const api = apisauce.create({
    baseURL: currentApi,
    headers: {
    },
    timeout: 10000
  })

  const getPlaces = (text, sessiontoken, cancelToken) => api.get(
    `place/autocomplete/json?&input=${encodeURIComponent(text)}&${LOCATION}&${LANG}&sessiontoken=${sessiontoken}`,
    {},
    { cancelToken }
  )

  const getDetails = (id, sessiontoken) => api.get(
    `place/details/json?placeid=${id}&${LANG}&sessiontoken=${sessiontoken}`,
    {},
    {}
  )

  const getPlaceByCoords = (lat, lng) => api.get(
    `geocode/json?latlng=${lat},${lng}&${LANG}`,
    {},
    {}
  )

  const getDirections = (origin, destination, mode) => api.get(
    `directions/json?origin=${origin}&destination=${destination}&mode=${mode}`,
    {},
    {}
  )

  return {
    getPlaces,
    getDetails,
    getPlaceByCoords,
    getDirections
  }
}

const MapsApi = create()

export default MapsApi

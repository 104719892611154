import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, AppContent, AppHeader, Map, MapInfoCard } from '../Components'
import { useStore } from '../Models/RootStore'
import { getDistanceFormatted } from '../Utils/map'
import { Colors } from '../Utils/theme'

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    maxWidth: 'calc(67.5rem - 6rem)',
    position: 'absolute',
    bottom: '2rem',
    left: '3rem',
    right: '3rem',
    margin: 'auto'
  },
  container: {
    padding: '2rem 2rem 20rem'
  },
  photoWrapper: {
    boxSizing: 'border-box',
    resize: 'horizontal',
    overflow: 'hidden',
    maxWidth: '100%'
  },
  photo: {
    backgroundColor: 'white',
    width: '100%',
    paddingBottom: '40%', //'56.25%',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  h1: {
    color: Colors.white,
    fontSize: '3rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    margin: '1rem 0 0'
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 800
  },
  subTitle: {
    fontSize: '1.875rem',
    color: Colors.white70,
    marginBottom: '2rem'
  }
}))

function CouponOrganizationsMapScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { couponStore, appStore, mapStore } = useStore()

  const [organization, setOrganization] = useState(null)

  const toOrganization = () => {
    navigate(`/organizations/${organization?.id}`)
  }

  const handleSetOrganization = (organization) => {
    setOrganization(organization)
    mapStore.getDirections(appStore.location, organization)
  }

  useEffect(() => {
    if (couponStore.couponOrganization) {
      handleSetOrganization(couponStore.couponOrganization)
    }
  }, [])

  const renderPhoto = () => {
    const bgStyle = organization?.photo ? { backgroundImage: `url(${organization.photo})` } : {}
    return (
      <div className={classes.photo} style={bgStyle} />
    )
  }

  const renderDistance = () => {
    const distance = getDistanceFormatted(appStore.location, organization)
    if (distance) {
      return (
        <div className={classes.subTitle}>{t('distance')} {distance}</div>
      )
    }
    return null
  }

  const renderInfo  = () => {
    if (organization) {
      return (
        <div className={classes.cardContainer}>
          <MapInfoCard onClose={() => setOrganization(null)}>
            {renderPhoto()}
            <h1 className={classes.h1}>{organization?.name}</h1>
            {renderDistance()}
            <Button
              text={t('view_details')}
              onClick={toOrganization}
              fullWidth
            />
          </MapInfoCard>
        </div>
      )
    }
    return null
  }

  const getLocation = () => {
    if (appStore.location) {
      return {
        lat: appStore.location.latitude,
        lng: appStore.location.longitude
      }
    }
  }

  const getMarkerType = (item) => {
    const catFi = (item?.category?.titleFi || '').toLowerCase() || ''

    if (catFi.includes('ravintolat')) return 'restaurant'
    if (catFi.includes('kauneus')) return 'beauty'
    if (catFi.includes('kulttuuri')) return 'culture'
    if (catFi.includes('sisustus')) return 'home'
    if (catFi.includes('terveys')) return 'beauty'
    if (catFi.includes('ruoka')) return 'shopping'
    if (catFi.includes('vapaa-aika')) return 'activity'
    if (catFi.includes('muoti')) return 'clothing'
    if (catFi.includes('joulu')) return 'xmas'
    // Fallback
    return 'coupon'
  }

  const getMarkers = () => {
    if (organization) {
      if (organization?.latitude && organization?.longitude) {
        return [
          {
            type: getMarkerType(organization),
            lat: Number(organization?.latitude),
            lng: Number(organization?.longitude)
          }
        ]
      }
      return []
    }
    return couponStore.couponOrganizations.filter(item => item.latitude && item.longitude).map(item => {
      if (item && item?.coordinates?.latitude && item?.coordinates?.longitude) {
        return {
          type: getMarkerType(item),
          lat: Number(item?.coordinates?.latitude),
          lng: Number(item?.coordinates?.longitude),
          onClick: () => handleSetOrganization(item)
        }
      }
      return null
    }).filter(v => !!v)
  }

  const getDirections = () => {
    const markers = getMarkers()
    if (mapStore.directions && markers.length === 1) {
      return [
        mapStore.directions
      ]
    }
    return []
  }

  return (
    <div>
      <AppHeader title={t('tampere_tunnetuksi_pass')} />
      <AppContent fixed>
        <Map
          location={getLocation()}
          markers={getMarkers()}
          routes={getDirections()}
        />
        {renderInfo()}
      </AppContent>
    </div>
  )
}

export default observer(CouponOrganizationsMapScreen)

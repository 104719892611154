import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { Button, AppContent, AppHeader, Map, MapInfoCard, QrCodeModal } from '../Components'
import { useStore } from '../Models/RootStore'
import { getDistanceFormatted, getMapsLink } from '../Utils/map'
import { Colors } from '../Utils/theme'

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    maxWidth: 'calc(67.5rem - 6rem)',
    position: 'absolute',
    bottom: '2rem',
    left: '3rem',
    right: '3rem',
    margin: 'auto'
  },
  container: {
    padding: '2rem 2rem 20rem'
  },
  h1: {
    color: Colors.white,
    fontSize: '3rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    margin: '1rem 0 0'
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 800
  },
  subTitle: {
    fontSize: '1.875rem',
    color: Colors.white70,
    marginBottom: '2rem'
  },
  materialList: {
    paddingLeft: '3rem',
    paddingBottom: '2.25rem'
  },
  materialListItem: {
    margin: 0,
    padding: 0,
    fontSize: '1.875rem',
    color: Colors.white
  }
}))

function RecycleMapScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { recycleStore, appStore, mapStore } = useStore()

  const [recyclePoint, setRecyclePoint] = useState(null)
  const [routeModalVisible, setRouteModalVisible] = useState(false)

  const clearRecyclePoint = () => setRecyclePoint(null)
  const toggleRouteModal = () => setRouteModalVisible(!routeModalVisible)

  const handleSetRecyclePoint = (item) => {
    setRecyclePoint(item)
    const latitude = get(item, 'geometry.coordinates[1]')
    const longitude = get(item, 'geometry.coordinates[0]')
    mapStore.getDirections(appStore.location, { latitude, longitude })
  }

  useEffect(() => {
    recycleStore.getRecycles()
  }, [])

  const renderDistance = () => {
    const latitude = get(recyclePoint, 'geometry.coordinates[1]')
    const longitude = get(recyclePoint, 'geometry.coordinates[0]')
    const pointLocation = { latitude, longitude }

    const distance = getDistanceFormatted(appStore.location, pointLocation)
    if (distance) {
      return (
        <div className={classes.subTitle}>{t('distance')} {distance}</div>
      )
    }
    return null
  }

  const renderMaterials = () => {
    if (!recyclePoint?.materials) return null
    return (
      <ul className={classes.materialList}>
        {(recyclePoint.materials || []).map((row, index) => {
          return <li key={index} className={classes.materialListItem}>{row.name}</li>
        })}
      </ul>
    )

  }

  const renderInfo  = () => {
    if (!recyclePoint) return null
    return (
      <div className={classes.cardContainer}>
        <MapInfoCard onClose={clearRecyclePoint}>
          <h1 className={classes.h1}>{recyclePoint?.name}, {recyclePoint?.address}</h1>
          {renderDistance()}
          {renderMaterials()}
          <Button
            text={t('download_route')}
            onClick={toggleRouteModal}
            fullWidth
          />
        </MapInfoCard>
      </div>
    )
  }

  const getLocation = () => {
    if (appStore.location) {
      return {
        lat: appStore.location.latitude,
        lng: appStore.location.longitude
      }
    }
  }

  const getMarkers = () => {
    if (recyclePoint) {
      return [
        {
          type: 'recycle_point',
          lat: get(recyclePoint, 'geometry.coordinates[1]'),
          lng: get(recyclePoint, 'geometry.coordinates[0]'),
        }
      ]
    }

    if (recycleStore?.recycles?.length) {
      return recycleStore.recycles.map(item => {
        return {
          type: 'recycle_point',
          lat: get(item, 'geometry.coordinates[1]'),
          lng: get(item, 'geometry.coordinates[0]'),
          onClick: () => {
            handleSetRecyclePoint(item)
          }
        }
      }).filter(val => val && val.lat && val.lng)
    }
    return []
  }

  const renderRouteModal = () => {
    const latitude = get(recyclePoint, 'geometry.coordinates[1]')
    const longitude = get(recyclePoint, 'geometry.coordinates[0]')
    const code = getMapsLink(latitude, longitude)

    if (code) {
      return (
        <QrCodeModal
          title={t('download_route_to_your_phone')}
          onCancel={toggleRouteModal}
          open={routeModalVisible}
          code={code}
        />
      )
    }
    return null
  }

  const getDirections = () => {
    const markers = getMarkers()
    if (mapStore.directions && markers.length === 1) {
      return [
        mapStore.directions
      ]
    }
    return []
  }

  return (
    <div>
      <AppHeader title={t('tre_recycles')} />
      <AppContent fixed>
        <Map
          location={getLocation()}
          markers={getMarkers()}
          routes={getDirections()}
        />
        {renderInfo()}
        {renderRouteModal()}
      </AppContent>
    </div>
  )
}

export default observer(RecycleMapScreen)

import get from 'lodash/get'
import haversine from 'haversine'
import moment from 'moment'
import { t } from 'i18next'
import { Colors } from '../Utils/theme'

export const GeometryTypes = {
  Point: 'Point',
  Polygon: 'Polygon',
  LineString: 'LineString'
}

export const JanaFieldType = {
  Boolean: 'boolean',
  Text: 'text',
  TextArea: 'textarea',
  Select: 'select',
  MultiSelect: 'multiselect',
  SportFieldRepeater: 'sport_field_repeater'
}

const JanaIconMappings = {
  basketball: 'basketball-court',
  beach: 'beach-alt-2',
  beach_volley: 'beach-volley',
  // boat_storage: 'boat-storage',
  bus_stop: 'bus-alt',
  campfire: 'campfire-alt',
  campsite: 'campsite',
  cloakroom_or_storage: 'cloakroom',
  // coffee: 'kiosk',
  disc_golf: 'disc-golf-course',
  // dressing_room: 'dressing-room',
  entrance: 'entrance',
  // ev_station: 'charging-station',
  // fairway: 'fairway',
  fire: 'campfire-alt',
  football: 'football-field',
  // gas_station: 'charging-station',
  guide_post: 'guide-post',
  ice_skating: 'ice-skating-field',
  indoor_sport_facility: 'indoor-sport-facility',
  info: 'info-location',
  // jump_tower: 'jump-tower',
  // kiosk: 'kiosk',
  lean_to: 'lean-to',
  // lifesaver: 'lifebelt',
  multipurpose_field: 'multipurpose-field',
  other: 'location-pin',
  outdoor_gym: 'outdoor-gym-alt',
  padel: 'padel-court',
  parking: 'parking-area',
  parking_area: 'parking-area',
  // pier: 'pier',
  playground: 'playground',
  recycle: 'waste-station',
  relic: 'relic',
  restaurant: 'restaurant-alt',
  shower: 'shower',
  skatepark: 'skatepark',
  sport_field: 'sport-field',
  swimming_hall: 'swimming-hall',
  // taxi: 'taxi',
  tennis: 'tennis-court',
  tennis_court: 'tennis-court',
  // terrace: 'terrace',
  ticket_sale: 'ticket-sale',
  tram_stop: 'tram',
  vantage_point: 'vantage-point',
  warning: 'warning',
  water_point: 'water-drop',
  wc: 'wc-alt',
  workout_stairs: 'workout-stairs',
}

export const getJanaIcon = (type) => {
  if (type && JanaIconMappings?.[type]) {
    return JanaIconMappings[type]
  }
  return JanaIconMappings.other
}

export const SportMainEntityType = {
  Beach: 'beach',
  SkiTrail: 'ski_trail',
  IceSkatingField: 'ice_skating',
  OutdoorGym: 'outdoor_gym',
  TennisField: 'tennis',
  DiscGolfCourse: 'disc_golf',
  BeachVolleyCourt: 'beach_volley',
  SportField: 'sport_field',
  FootballField: 'football',
  Skatepark: 'skatepark',
  NatureTrail: 'nature_trail',
  PadelField: 'padel',
  BasketballCourt: 'basketball',
  SwimmingHall: 'swimming_hall',
  MultipurposeField: 'multipurpose_field',
  WorkoutStairs: 'workout_stairs',
  IndoorSportFacility: 'indoor_sport_facility'
}

export const SportSubEntityType = {
  BasketballCourt: 'basketball',
  Beach: 'beach',
  BusStop: 'bus_stop',
  Cafe: 'coffee',
  ChargingStation: 'ev_station',
  CloakroomOrStorage: 'cloakroom_or_storage',
  DogPark: 'dog_park',
  Entrance: 'entrance',
  EventPlace: 'event_place',
  Fairway: 'fairway',
  Campfire: 'campfire',
  Campsite: 'campsite',
  FootballField: 'football',
  GasStation: 'gas_station',
  GuidePost: 'guide_post',
  Info: 'info',
  Kiosk: 'kiosk',
  LeanTo: 'lean_to',
  Lifesaver: 'lifesaver',
  Other: 'other',
  OutdoorGym: 'outdoor_gym',
  ParkingArea: 'parking_area',
  Pier: 'pier',
  Recycle: 'recycle',
  Relic: 'relic',
  Restaurant: 'restaurant',
  Shower: 'shower',
  Taxi: 'taxi',
  TennisCourt: 'tennis',
  Terrace: 'terrace',
  TicketSale: 'ticket_sale',
  TramStop: 'tram_stop',
  VantagePoint: 'vantage_point',
  Warning: 'warning',
  WaterPoint: 'water_point',
  WC: 'wc'
}

export const ServiceMainEntityType = {
  Playground: 'playground',
  Marina: 'marina',
  AmusementPark: 'amusement_park',
  Theater: 'theater',
  Museum: 'museum',
  Market: 'market',
  Station: 'station',
  University: 'university'
}

export const EventMainEntityType = {
  CultureEvent: 'culture_event',
  MusicEvent: 'music_event',
  SportEvent: 'sport_event',
  ThemeEvent: 'theme_event'
}

export const SportFilters = [
  { label: 'all', value: null },
  { label: 'ski_trails', value: SportMainEntityType.SkiTrail },
  { label: 'beaches', value: SportMainEntityType.Beach },
  { label: 'ice_skating_fields', value: SportMainEntityType.IceSkatingField },
  { label: 'outdoor_gyms', value: SportMainEntityType.OutdoorGym },
  { label: 'tennis_fields', value: SportMainEntityType.TennisField },
  { label: 'padel_fields', value: SportMainEntityType.PadelField },
  { label: 'disc_golf_courses', value: SportMainEntityType.DiscGolfCourse },
  { label: 'beach_volley_courts', value: SportMainEntityType.BeachVolleyCourt },
  { label: 'sport_fields', value: SportMainEntityType.SportField },
  { label: 'football_fields', value: SportMainEntityType.FootballField },
  { label: 'skateparks', value: SportMainEntityType.Skatepark },
  { label: 'nature_trails', value: SportMainEntityType.NatureTrail },
  { label: 'basketball_courts', value: SportMainEntityType.BasketballCourt },
  { label: 'swimming_halls', value: SportMainEntityType.SwimmingHall },
  { label: 'multipurpose_fields', value: SportMainEntityType.MultipurposeField },
  { label: 'workout_stairs', value: SportMainEntityType.WorkoutStairs },
  { label: 'indoor_sport_facilities', value: SportMainEntityType.IndoorSportFacility }
]

export const SportTypes = [
  { label: 'ski_trails', value: SportMainEntityType.SkiTrail },
  { label: 'ice_skating_fields', value: SportMainEntityType.IceSkatingField },
  { label: 'outdoor_gyms', value: SportMainEntityType.OutdoorGym },
  { label: 'tennis_fields', value: SportMainEntityType.TennisField },
  { label: 'padel_fields', value: SportMainEntityType.PadelField },
  { label: 'disc_golf_courses', value: SportMainEntityType.DiscGolfCourse },
  { label: 'beach_volley_courts', value: SportMainEntityType.BeachVolleyCourt },
  { label: 'sport_fields', value: SportMainEntityType.SportField },
  { label: 'football_fields', value: SportMainEntityType.FootballField },
  { label: 'skateparks', value: SportMainEntityType.Skatepark },
  { label: 'basketball_courts', value: SportMainEntityType.BasketballCourt },
  { label: 'swimming_halls', value: SportMainEntityType.SwimmingHall },
  { label: 'multipurpose_fields', value: SportMainEntityType.MultipurposeField },
  { label: 'workout_stairs', value: SportMainEntityType.WorkoutStairs },
  { label: 'indoor_sport_facilities', value: SportMainEntityType.IndoorSportFacility }
]

export const LeisureTypes = [
  { label: 'beaches', value: SportMainEntityType.Beach },
  { label: 'nature_trails', value: SportMainEntityType.NatureTrail }
]

export const OtherTypes = [
  // { label: 'bus_stops', value: SportSubEntityType.BusStop },
  // { label: 'cafes', value: SportSubEntityType.Cafe },
  { label: 'campfires', value: SportSubEntityType.Campfire },
  { label: 'campsites', value: SportSubEntityType.Campsite },
  { label: 'cloakrooms_or_storages', value: SportSubEntityType.CloakroomOrStorage },
  // { label: 'dog_parks', value: SportSubEntityType.DogPark },
  { label: 'entrances', value: SportSubEntityType.Entrance },
  { label: 'fairways', value: SportSubEntityType.Fairway },
  // { label: 'gas_stations', value: SportSubEntityType.GasStation },
  { label: 'guide_posts', value: SportSubEntityType.GuidePost },
  { label: 'info_locations', value: SportSubEntityType.Info },
  // { label: 'kiosks', value: SportSubEntityType.Kiosk },
  { label: 'lean_tos', value: SportSubEntityType.LeanTo },
  // { label: 'lifesavers', value: SportSubEntityType.Lifesaver },
  { label: 'other_locations', value: SportSubEntityType.Other },
  { label: 'parking_areas', value: SportSubEntityType.ParkingArea },
  // { label: 'piers', value: SportSubEntityType.Pier },
  { label: 'recycle_points', value: SportSubEntityType.Recycle },
  { label: 'relics', value: SportSubEntityType.Relic },
  { label: 'restaurants', value: SportSubEntityType.Restaurant },
  // { label: 'showers', value: SportSubEntityType.Shower },
  // { label: 'taxi_stations', value: SportSubEntityType.Taxi },
  // { label: 'terraces', value: SportSubEntityType.Terrace },
  { label: 'ticket_sales', value: SportSubEntityType.TicketSale },
  // { label: 'tram_stops', value: SportSubEntityType.TramStop },
  { label: 'vantage_points', value: SportSubEntityType.VantagePoint },
  { label: 'warnings', value: SportSubEntityType.Warning },
  { label: 'water_points', value: SportSubEntityType.WaterPoint },
  { label: 'wcs', value: SportSubEntityType.WC },
]

export const ServiceFilters = [
  { label: 'all', value: null },
  { label: 'playgrounds', value: ServiceMainEntityType.Playground },
  { label: 'marinas', value: ServiceMainEntityType.Marina },
  { label: 'amusement_parks', value: ServiceMainEntityType.AmusementPark },
  { label: 'theaters', value: ServiceMainEntityType.Theater },
  { label: 'museums', value: ServiceMainEntityType.Museum },
  { label: 'markets', value: ServiceMainEntityType.Market },
  { label: 'stations', value: ServiceMainEntityType.Station },
  { label: 'universities', value: ServiceMainEntityType.University }
]

export const getEntityLayout = (type) => {
  if (type === 'sauna') {
    return [
      { key: 'open_hours' },
      { key: 'price' },
      { key: 'unisex_sauna' },
      { title: 'sauna_rules' },
      { title: 'sauna_services' },
      { title: 'contact_information' },
      { key: 'phone' },
      { key: 'email' },
      { key: 'www' },
      { key: 'trip_advisor_url' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.Beach) {
    return [
      { key: 'water_temperature' },
      { key: 'air_temperature' },
      { key: 'algae' },
      { key: 'beach_line' },
      { title: 'beach_properties' },
      { title: 'beach_other_services' },
      { title: 'contact_information' },
      { key: 'phone' },
      { key: 'email' },
      { key: 'www' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.SkiTrail) {
    return [
      { key: 'condition' },
      { key: 'condition_report' },
      { key: 'length' },
      { key: 'has_lights' },
      { title: 'contact_information' },
      { key: 'phone' },
      { key: 'email' },
      { key: 'www' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.IceSkatingField) {
    return [
      { key: 'condition' },
      { key: 'has_lights' },
      { key: 'last_maintained' },
      { title: 'ice_skating_equipment' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' },
      { key: 'booking_calendar_link' }
    ]
  } else if (type === SportMainEntityType.OutdoorGym) {
    return [
      { key: 'condition' },
      { key: 'last_maintained' },
      { key: 'equipment' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.TennisField) {
    return [
      { key: 'condition' },
      { key: 'sport_field_list' },
      { key: 'booking_calendar_link' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.DiscGolfCourse) {
    return [
      { key: 'open_hours_type' },
      { key: 'price' },
      { key: 'maintenance_frequency' },
      { title: 'disc_golf_course_information' },
      { key: 'num_baskets' },
      { key: 'course_classification' },
      // { key: 'course_difficulty' },
      { key: 'course_type' },
      { key: 'disc_golf_basket' },
      { key: 'disc_golf_tee_areas' },
      { key: 'topography' },
      { title: 'disc_golf_other_services' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.BeachVolleyCourt) {
    return [
      { key: 'sport_field_list' },
      { key: 'condition' },
      { key: 'maintenance_frequency' },
      { key: 'last_maintained' },
      { key: 'booking_calendar_link' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.SportField) {
    return [
      { key: 'maintenance_frequency' },
      { key: 'last_maintained' },
      { key: 'open_hours' },
      { key: 'open_hours_type' },
      { key: 'booking_calendar_link' },
      { key: 'open_hours_description' },
      { title: 'sport_field_information' },
      { key: 'track_surface_material' },
      { key: 'grass_type' },
      { key: 'grass_usage' },
      { title: 'athletics' },
      { title: 'sport_field_other_services' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.FootballField) {
    return [
      { key: 'maintenance_frequency' },
      { key: 'last_maintained' },
      { key: 'sport_field_list' },
      { key: 'booking_calendar_link' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.Skatepark) {
    return [
      { key: 'condition' },
      { key: 'last_maintained' },
      { key: 'use_start_date' },
      { key: 'use_end_date' },
      { title: 'skate_park_information' },
      { key: 'skate_style' },
      { key: 'skate_surface' },
      { key: 'rain_cover' },
      { title: 'skate_obstacles' },
      { title: 'skate_equipment_rental' },
      { key: 'has_skate_equipment_rental' },
      { key: 'skate_equipment_description' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.NatureTrail) {
    return [
      { title: 'nature_trail_services' },
      { title: 'contact_information' },
      { key: 'contact_person' },
      { key: 'phone' },
      { key: 'email' },
      { key: 'www' },
      { key: 'trip_advisor_url' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.PadelField) {
    return [
      { key: 'condition' },
      { key: 'sport_field_list' },
      { key: 'booking_calendar_link' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.BasketballCourt) {
    return [
      { key: 'maintenance_frequency' },
      { key: 'last_maintained' },
      { key: 'sport_field_list' },
      { key: 'booking_calendar_link' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.SwimmingHall) {
    return [
      { key: 'open_hours' },
      { key: 'price' },
      { title: 'swimming_hall_equipment' },
      { title: 'sauna_types' },
      { title: 'swimming_hall_other_services' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.MultipurposeField) {
    return [
      { key: 'condition' },
      { key: 'last_maintained' },
      { key: 'field_in_use' },
      { key: 'use_start_date' },
      { key: 'use_end_date' },
      { title: 'sport_field_information' },
      { key: 'multipurpose_field_surface' },
      { key: 'accessible' },
      { title: 'multipurpose_field_equipment' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.WorkoutStairs) {
    return [
      { key: 'open_hours' },
      { key: 'open_hours_type' },
      { title: 'general_information' },
      { key: 'has_lights' },
      { key: 'winter_maintenance' },
      { key: 'stair_length' },
      { key: 'stair_count' },
      { title: 'workout_stairs_equipment' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === SportMainEntityType.IndoorSportFacility) {
    return [
      { key: 'open_hours' },
      { key: 'service_phones' },
      { key: 'service_urls' },
      { title: 'indoor_sport_facility_properties' },
      { title: 'indoor_sports' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === ServiceMainEntityType.Playground) {
    return [
      { key: 'winter_maintenance' },
      { title: 'playground_equipment' },
      { title: 'contact_information' },
      { key: 'phone' },
      { key: 'email' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === ServiceMainEntityType.Marina) {
    return [
      { key: 'service_hours' },
      { key: 'equipment_additional_info' },
      { title: 'berths' },
      { key: 'sales_season_start_date' },
      { key: 'sales_season_end_date' },
      { key: 'berths' },
      { key: 'guest_berths' },
      { title: 'marina_equipment' },
      { title: 'contact_information' },
      { key: 'marina_manager' },
      { key: 'phone' },
      { key: 'email' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === ServiceMainEntityType.AmusementPark) {
    return [
      { key: 'open_hours' },
      { key: 'services_contact_information' },
      { title: 'amusement_park_information' },
      { key: 'amusement_park_equipment' },
      { key: 'equipment_count' },
      { key: 'area' },
      { key: 'yearly_visitor_count' },
      { key: 'opened' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === ServiceMainEntityType.Theater) {
    return [
      { key: 'open_hours' },
      { key: 'exception_schedules' },
      { key: 'services_contact_information' },
      { title: 'theater_services' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' },
      { key: 'show_schedules_link' },
      { key: 'ticket_sales_link' }
    ]
  } else if (type === ServiceMainEntityType.Museum) {
    return [
      { key: 'open_hours' },
      { key: 'price' },
      { key: 'tour_schedules_link' },
      { title: 'museum_themes' },
      { title: 'payment_methods' },
      { title: 'guided_tour_information' },
      { key: 'guided_tour' },
      { key: 'tour_pricing' },
      { key: 'reservation_number' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === ServiceMainEntityType.Market) {
    return [
      { key: 'open_hours' },
      { title: 'general_information' },
      { key: 'sales_season_start_date' },
      { key: 'sales_season_end_date' },
      { key: 'sales_location_by_reservation' },
      { key: 'flea_market_places' },
      { title: 'contact_information' },
      { key: 'www' },
      { key: 'facebook_url' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === ServiceMainEntityType.Station) {
    return [
      { key: 'services_contact_information' },
      { key: 'service_open_hours' },
      { key: 'links' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  } else if (type === ServiceMainEntityType.University) {
    return [
      { key: 'services_contact_information' },
      { key: 'faculties' },
      { key: 'campuses' },
      { title: 'contact_information' },
      { key: 'street_address' },
      { title: 'other_information' }
    ]
  }
  return []
}

export const getEntityFieldLayoutTitles = (type) => {
  return getEntityLayout(type)
    .map((item) => item.title || null)
    .filter((item) => item && !item.fieldKey)
}
export const getEntityFieldLayoutKeys = (type) => {
  return getEntityLayout(type)
    .map((item) => item.key || null)
    .filter((item) => item)
}

// Get avg coordinates from points
export const getAvgCoordinates = (points) => {
  let longitudeSum = 0
  let latitudeSum = 0
  for (const point of points) {
    longitudeSum += point[0]
    latitudeSum += point[1]
  }
  return {
    latitude: latitudeSum / points.length,
    longitude: longitudeSum / points.length
  }
}

// Sort features for map: Polygon, LineString, Point
export const sortMapFeaturesByType = (features) => {
  const sortedFeatures = features.sort((a, b) => {
    const aType = get(a, 'geometry.type')
    const bType = get(b, 'geometry.type')

    if (aType === bType) {
      if (aType === GeometryTypes.Polygon) {
        // Sort child sub entities after parent sub entities
        const aIsParent = !!get(a, 'properties.parentSubEntityId')
        const bIsParent = !!get(b, 'properties.parentSubEntityId')
        if (aIsParent !== bIsParent) {
          return aIsParent ? -1 : 1
        }
      }
      return 0
    }
    if (aType === GeometryTypes.Point) return 1
    if (bType === GeometryTypes.Point) return -1
    if (aType === GeometryTypes.LineString) return 1
    if (bType === GeometryTypes.LineString) return -1
    return 0
  })

  return sortedFeatures
}

// Calculate center coords by avg
export const getEntityCenterCoords = (entity) => {
  try {
    const type = get(entity, 'mainEntityType.type')

    if (type === 'ski_trail') {
      const allCoords = get(entity, 'geo.geometry.coordinates')
      if (allCoords) {
        const avgCoords = getAvgCoordinates(allCoords)
        if (avgCoords && avgCoords.latitude && avgCoords.longitude) {
          return {
            longitude: avgCoords.longitude,
            latitude: avgCoords.latitude
          }
        }
      }
    } else {
      const longitude = get(entity, 'geo.geometry.coordinates[0]')
      const latitude = get(entity, 'geo.geometry.coordinates[1]')
      if (latitude && longitude) {
        return {
          longitude,
          latitude
        }
      }
    }
  } catch (err) {}

  return {}
}

// Calculate center coords by avg
export const getFeatureCenterCoords = (feature) => {
  try {
    const type = get(feature, 'geo.geometry.type')

    if (type === GeometryTypes.Point) {
      const longitude = get(feature, 'geo.geometry.coordinates[0]')
      const latitude = get(feature, 'geo.geometry.coordinates[1]')
      if (latitude && longitude) {
        return {
          longitude,
          latitude
        }
      }
    } else if (type === GeometryTypes.LineString) {
      const allCoords = get(feature, 'geo.geometry.coordinates')
      if (allCoords) {
        const avgCoords = getAvgCoordinates(allCoords)
        if (avgCoords && avgCoords.latitude && avgCoords.longitude) {
          return {
            longitude: avgCoords.longitude,
            latitude: avgCoords.latitude
          }
        }
      }
    } else if (type === GeometryTypes.Polygon) {
      const allCoords = get(feature, 'geo.geometry.coordinates[0]')
      if (allCoords) {
        const avgCoords = getAvgCoordinates(allCoords)
        if (avgCoords && avgCoords.latitude && avgCoords.longitude) {
          return {
            longitude: avgCoords.longitude,
            latitude: avgCoords.latitude
          }
        }
      }
    }
  } catch (err) {}

  return {}
}

export const getFeatureTooltipCoords = (feature) => {
  const type = get(feature, 'geometry.type')
  if (type === GeometryTypes.Point) {
    return {
      longitude: get(feature, 'geometry.coordinates[0]'),
      latitude: get(feature, 'geometry.coordinates[1]')
    }
  }
  let allCoords = null
  if (type === GeometryTypes.Polygon) {
    allCoords = get(feature, 'geometry.coordinates[0]')
  } else if (type === GeometryTypes.LineString) {
    allCoords = get(feature, 'geometry.coordinates')
  }

  if (allCoords) {
    // Find top-center coords
    const avgCoords = getAvgCoordinates(allCoords)
    let maxLatitude = 0
    for (const point of allCoords) {
      maxLatitude = Math.max(maxLatitude, point[1])
    }

    return {
      longitude: avgCoords.longitude,
      latitude: maxLatitude
    }
  }

  return null
}

export const calculateDistance = (start, end, unit = 'km') => {
  const distance = haversine(start, end, { unit })
  return distance
}

export const pickJanaField = (item, key) => {
  if (item.fields) {
    return item.fields.find((field) => field.fieldKey === key)
  }
  return null
}

export const pickJanaFieldLabel = (item, key) => {
  if (item.fields) {
    const field = item.fields.find((field) => field.fieldKey === key)
    if (field && field.label) return field.label
  }
  return null
}

export const pickJanaFieldValue = (item, key) => {
  if (item.fields) {
    const field = item.fields.find((field) => field.fieldKey === key)
    if (field && field.value) return field.value
  }
  return null
}

export const pickJanaFieldOptionLabel = (options, value) => {
  if (options && options.length) {
    const option = options.find((option) => option.value === value)
    if (option && option.label) {
      return option.label
    }
  }
  if (value) {
    return t(value)
  }
  return null
}

export const getFormattedJanaFieldValue = (t, field) => {
  const type = field.fieldType
  if (type === JanaFieldType.Boolean) {
    return field.value ? t('yes') : t('no')
  }
  if (type === JanaFieldType.MultiSelect) {
    if (field.value.length) {
      return field.value.map((value) => pickJanaFieldOptionLabel(field.options, value)).join(', ')
    }
    return pickJanaFieldOptionLabel(field.options, field.value)
  }
  if (type === JanaFieldType.Select) {
    return pickJanaFieldOptionLabel(field.options, field.value)
  }
  return field.value
}

export const formatJanaFields = (item) => {
  let fields = item.fields
  try {
    let address
    if (pickJanaField(item, 'street_address')) {
      const streetAddress = pickJanaFieldValue(item, 'street_address')
      const postalCode = pickJanaFieldValue(item, 'postal_code')
      const city = pickJanaFieldValue(item, 'city')
      if (streetAddress) {
        address = `${streetAddress}`.trim()
        if (postalCode) address = `${address}, ${postalCode}`.trim()
        if (city) address = `${address}, ${city}`.trim()
      }
    }
    if (address) {
      fields = fields.filter((item) => !['postal_code', 'city'].includes(item.fieldKey))
      fields = fields.map((item) => {
        if (item.fieldKey === 'street_address') {
          return { ...item, value: address }
        }
        return item
      })
    }
  } catch (e) {}

  try {
    if (pickJanaField(item, 'open_hours')) {
      const openHours = pickJanaFieldValue(item, 'open_hours') // .slice(0, 7)
      const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      const hours = {}
      for (const [index, value] of openHours.entries()) {
        hours[days[index]] = value
      }
      fields = fields.map((item) => {
        if (item.fieldKey === 'open_hours') {
          return { ...item, value: hours }
        }
        return item
      })
    }
  } catch (e) {}

  try {
    if (pickJanaField(item, 'service_open_hours')) {
      let serviceOpenHours = pickJanaFieldValue(item, 'service_open_hours')
      const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      serviceOpenHours = serviceOpenHours.map((item) => {
        const openHours = item?.openHours
        const hours = {}
        for (const [index, value] of openHours.slice(0, 7).entries()) {
          hours[days[index]] = value
        }
        return { ...item, openHours: hours }
      })
      fields = fields.map((item) => {
        if (item.fieldKey === 'service_open_hours') {
          return { ...item, value: serviceOpenHours }
        }
        return item
      })
    }
  } catch (e) {}

  try {
    if (pickJanaField(item, 'is_free')) {
      const isFree = pickJanaFieldValue(item, 'is_free')
      if (isFree) {
        fields = fields.map((item) => {
          if (item.fieldKey === 'price') {
            return { ...item, value: 'free' }
          }
          return item
        })
      }
    }
  } catch (e) {}

  try {
    if (pickJanaField(item, 'use_start_date')) {
      const startDate = pickJanaFieldValue(item, 'use_start_date')
      if (startDate) {
        fields = fields.map((item) => {
          if (item.fieldKey === 'use_start_date') {
            return { ...item, value: moment(startDate).format('DD.MM.YYYY') }
          }
          return item
        })
      }
    }
  } catch (e) {}

  try {
    if (pickJanaField(item, 'use_end_date')) {
      const endDate = pickJanaFieldValue(item, 'use_end_date')
      if (endDate) {
        fields = fields.map((item) => {
          if (item.fieldKey === 'use_end_date') {
            return { ...item, value: moment(endDate).format('DD.MM.YYYY') }
          }
          return item
        })
      }
    }
  } catch (e) {}

  try {
    if (pickJanaField(item, 'sales_season_start_date')) {
      const startDate = pickJanaFieldValue(item, 'sales_season_start_date')
      if (startDate) {
        fields = fields.map((item) => {
          if (item.fieldKey === 'sales_season_start_date') {
            return { ...item, value: moment(startDate).format('DD.MM.YYYY') }
          }
          return item
        })
      }
    }
  } catch (e) {}

  try {
    if (pickJanaField(item, 'sales_season_end_date')) {
      const endDate = pickJanaFieldValue(item, 'sales_season_end_date')
      if (endDate) {
        fields = fields.map((item) => {
          if (item.fieldKey === 'sales_season_end_date') {
            return { ...item, value: moment(endDate).format('DD.MM.YYYY') }
          }
          return item
        })
      }
    }
  } catch (e) {}

  try {
    if (pickJanaField(item, 'last_maintained')) {
      const lastMaintained = pickJanaFieldValue(item, 'last_maintained')
      if (lastMaintained) {
        fields = fields.map((item) => {
          if (item.fieldKey === 'last_maintained') {
            return { ...item, value: moment(lastMaintained).format('DD.MM.YYYY') }
          }
          return item
        })
      }
    }
  } catch (e) {}

  try {
    if (pickJanaField(item, 'opened')) {
      const opened = pickJanaFieldValue(item, 'opened')
      if (opened) {
        fields = fields.map((item) => {
          if (item.fieldKey === 'opened') {
            return { ...item, value: moment(opened).format('DD.MM.YYYY') }
          }
          return item
        })
      }
    }
  } catch (e) {}

  return fields
}

export const getFormattedLayout = (t, entity) => {
  // Get all fields
  const fields = get(entity, 'fields', []) || []
  const type = get(entity, 'mainEntityType.type')

  // Fill layout with field values
  let layout = getEntityLayout(type).map((item) => {
    if (item.key) {
      const field = fields.find((f) => f.fieldKey === item.key)
      if (field) {
        return { ...item, ...field }
      }
    }
    return item
  })

  // Add in missing fields
  for (const field of fields) {
    const key = field.fieldKey
    if (!getEntityFieldLayoutKeys(type).includes(key)) {
      // Item not found in predefined layout
      if (field.fieldType === JanaFieldType.MultiSelect) {
        // Convert multiselect options into fields
        const options = []
        for (const option of field.options) {
          // Create text field of each option
          options.push({
            ...field,
            fieldType: 'text',
            label: option.label,
            value: field.value && field.value.includes(option.value) ? t('yes') : t('no')
          })
        }
        if (getEntityFieldLayoutTitles(type).includes(key)) {
          // Multiselect falls under already predefined title
          // Get predefined title position
          const titleIndex = layout.findIndex((item) => item.title === key)
          layout = [
            ...layout.slice(0, titleIndex + 1),
            ...options,
            ...layout.slice(titleIndex + 1)
          ]
        } else {
          // Multiselect not found in the predefined layout
          const title = field.label
          // Append at the end
          layout = [...layout, { rawTitle: title }, ...options]
        }
      } else {
        // Field type other than multiselect --> append at the end
        layout.push(field)
      }
    }
  }

  const blacklist = ['exception_notification', 'is_free']

  // Handle open hours fields (include only one)
  const openHoursType = fields.find((item) => item.fieldKey === 'open_hours_type') ?? null
  if (openHoursType) {
    const alwaysOpen = openHoursType?.value === 'always_open'
    if (alwaysOpen) {
      blacklist.push('open_hours')
    } else {
      blacklist.push('open_hours_type')
    }
  }

  // Remove empty && blacklisted fields
  layout = layout.filter((item) => {
    if (blacklist.includes(item.fieldKey)) {
      return false
    }
    return item.title || item.value || (!item.value && item.value === false)
  })

  // Remove contact information if it does not have content
  const contactInfoIndex = layout.findIndex((item) => item.title === 'contact_information')
  if (contactInfoIndex !== -1 && (layout.length - 1) > (contactInfoIndex + 1) && layout[contactInfoIndex + 1].title) {
    layout = [
      ...layout.slice(0, contactInfoIndex),
      ...layout.slice(contactInfoIndex + 1)
    ]
  }

  // Remove equipment rental if it does not have content
  const equipmentRentalIndex = layout.findIndex((item) => item.title === 'skate_equipment_rental')
  if (equipmentRentalIndex !== -1 && (layout.length - 1) > (equipmentRentalIndex + 1) && layout[equipmentRentalIndex + 1].title) {
    layout = [
      ...layout.slice(0, equipmentRentalIndex),
      ...layout.slice(equipmentRentalIndex + 1)
    ]
  }

  // Remove sport field info if it does not have content
  const sportFieldInfo = layout.findIndex((item) => item.title === 'sport_field_information')
  if (sportFieldInfo !== -1 && (layout.length - 1) > (sportFieldInfo + 1) && layout[sportFieldInfo + 1].title) {
    layout = [
      ...layout.slice(0, sportFieldInfo),
      ...layout.slice(sportFieldInfo + 1)
    ]
  }

  // Remove last layout item if it has only title
  if (layout.length && layout[layout.length - 1].title) {
    layout = layout.slice(0, -1)
  }
  if (layout.length && layout[layout.length - 1].title) {
    layout = layout.slice(0, -1)
  }

  return layout
}

export const getConditionColor = (condition) => {
  if (condition && condition.value) {
    const value = condition.value
    if (value === 'good') return Colors.green
    if (value === 'bad') return Colors.red
  }
  return Colors.grey
}

export const getConditionText = (condition, shortForm = false) => {
  if (condition && condition.value) {
    const value = condition.value
    if (value === 'good') {
      if (shortForm) return t('good')
      return pickJanaFieldOptionLabel(condition.options, value)
    }
    if (value === 'bad') {
      if (shortForm) return t('bad')
      return pickJanaFieldOptionLabel(condition.options, value)
    }
  }
  return t('unknown')
}

export const getAlgaeColor = (algae) => {
  if (algae && algae.value) {
    const value = algae.value
    if (value === 'no_algae') return Colors.green
    if (value === 'slightly_algae') return Colors.yellow
    if (value === 'plenty_algae') return Colors.red
  }
  return Colors.grey
}

export const getAlgaeText = (algae, shortForm = false) => {
  if (algae && algae.value) {
    const value = algae.value
    if (value === 'no_algae') {
      return pickJanaFieldOptionLabel(algae.options, value)
    }
    if (value === 'slightly_algae') {
      return pickJanaFieldOptionLabel(algae.options, value)
    }
    if (value === 'plenty_algae') {
      return pickJanaFieldOptionLabel(algae.options, value)
    }
  }
  return t('unknown')
}

export const getPlaceOpenStatus = (hours) => {
  try {
    const today = moment().format('dddd').toLowerCase()
    const day = get(hours, today, null) || null
    if (day) {
      let opens = get(day, '[0]', null) || null
      let closes = get(day, '[1]', null) || null
      if (opens && closes) {
        const opensSplitted = opens.includes(':') ? opens.split(':') : null
        const closesSplitted = closes.includes(':') ? closes.split(':') : null
        if (opensSplitted && closesSplitted) {
          opens = moment().hours(opensSplitted[0]).minutes(opensSplitted[1])
          closes = moment().hours(closesSplitted[0]).minutes(closesSplitted[1])
          const currentTime = moment()
          if (opens.isSameOrBefore(currentTime) && closes.isAfter(currentTime)) {
            return 'open'
          }
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  return 'closed_alt'
}

export const getPlaceOpenStatusColor = (hours) => {
  try {
    const today = moment().format('dddd').toLowerCase()
    const day = get(hours, today, null) || null
    if (day) {
      let opens = get(day, '[0]', null) || null
      let closes = get(day, '[1]', null) || null
      if (opens && closes) {
        const opensSplitted = opens.includes(':') ? opens.split(':') : null
        const closesSplitted = closes.includes(':') ? closes.split(':') : null
        if (opensSplitted && closesSplitted) {
          opens = moment().hours(opensSplitted[0]).minutes(opensSplitted[1])
          closes = moment().hours(closesSplitted[0]).minutes(closesSplitted[1])
          const currentTime = moment()
          if (opens.isSameOrBefore(currentTime) && closes.isAfter(currentTime)) {
            if (currentTime.add(30, 'minutes').isSameOrAfter(closes)) {
              return Colors.yellow
            }
            return Colors.green
          }
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  return Colors.red
}

export const getTodaysOpeningHour = (hours) => {
  try {
    const today = moment().format('dddd').toLowerCase()
    const day = get(hours, today, null) || null
    if (day) {
      if (get(day, '[0]', null) || null) {
        return day[0].split(':').join('.')
      }
    }
  } catch (e) {
    console.log(e)
  }
  return null
}

export const getTodaysClosingHour = (hours) => {
  try {
    const today = moment().format('dddd').toLowerCase()
    const day = get(hours, today, null) || null
    if (day) {
      if (get(day, '[1]', null) || null) {
        return day[1].split(':').join('.')
      }
    }
  } catch (e) {
    console.log(e)
  }
  return null
}

export const getIsCurrentTimeAfterClosingTime = (hours) => {
  try {
    const today = moment().format('dddd').toLowerCase()
    const day = get(hours, today, null) || null
    if (day) {
      let closes = get(day, '[1]', null) || null
      if (closes) {
        const closesSplitted = closes.includes(':') ? closes.split(':') : null
        if (closesSplitted) {
          closes = moment().hours(closesSplitted[0]).minutes(closesSplitted[1])
          const currentTime = moment()
          if (closes.isAfter(currentTime)) {
            return false
          }
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  return true
}

export const getSubEntityIcon = (item) => {
  try {
    const type = get(item, 'subEntityType.type')
    if (type) {
      const icon = get(SubEntityIconMappings, type, null) || null
      if (icon) {
        return icon
      }
    }
  } catch (e) {
    console.log(e)
  }
  return 'info-alt-outlined'
}

export const getGeofences = (subEntities) => {
  if (subEntities && subEntities.length) {
    return subEntities.filter((subEntity) => subEntity.type === 'geofence')
  }
  return []
}

export const composeGeofences = (geofences) => {
  const results = geofences.map((geofence) => ({
    // Unique geofence identifier
    identifier: `${get(geofence, 'id')}`,
    // Radius of the circular geofence.
    // The minimum reliable radius is 200 meters.
    // Anything less will likely not cause a geofence to trigger.
    radius: get(geofence, 'radius'),
    // Latitude of geofence center
    latitude: get(geofence, 'geo.geometry.coordinates[1]'),
    // Longitude of geofence center
    longitude: get(geofence, 'geo.geometry.coordinates[0]'),
    // Set true to fire event when device enters this geofence.
    notifyOnEntry: !!(get(geofence, 'arrivalTitle')),
    // Set true to fire event when device exits this geofence.
    notifyOnExit: !!(get(geofence, 'departureTitle'))
    // Set true to fire event when device "loiters"
    // within this geofence for loiteringDelay milliseconds.
    // notifyOnDwell: false,
    // Minimum time in milliseconds the device must "loiter"
    // within this geofence before notifyOnDwell event fires.
    // loiteringDelay: 30000,
    // Optional arbitrary meta-data
    // extras: {
    //  activeDates: get(geofence, 'activeDates')
    // }
  })).filter(item => {
    return (
      item.identifier &&
      item.latitude &&
      item.longitude
    )
  })

  return results
}

export const getGeofencesFromEntity = (entity) => {
  // Add new ones
  let geofences = getGeofences(get(entity, 'subEntities', [])) || []
  if (geofences.length) {
    geofences = composeGeofences(geofences)
  }
  return geofences
}

export const JanaAnalyticsEventType = {
  ViewMainEntity: 'view_main_entity'
}

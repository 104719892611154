import { makeAutoObservable } from 'mobx'
import Api from '../Services/Api'

export default class EventStore {
  rootStore
  loading = false
  feedbackSentAt = 0

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setFeedbackSentAt = (feedbackSentAt) => { this.feedbackSentAt = feedbackSentAt }
  setLoading = (loading) => { this.loading = loading  }

  async sendFeedback(feedback) {
    this.setLoading(true)
    const composedFeedback: any = {
      type: 'general_app_feedback',
      title: `Infonäyttö: ${feedback.title}`.trim(),
      message: `Otsikko: ${feedback.title || '(ei otsikkoa)'}\n------------\n\nViesti:\n\n${feedback.content}\n\n---\nLähetetty infonäytöltä: ${this.rootStore.appStore?.screen?.name || ''}\n`,
      email: null,
    }

    // Add screen location details
    if (this.rootStore.appStore?.location?.latitude) {
      const screenName = `Infonäyttö: ${this.rootStore.appStore?.screen?.name || ''}`
      composedFeedback.location = {
        coords: {
          lat: this.rootStore.appStore?.location?.latitude,
          lng: this.rootStore.appStore?.location?.longitude
        },
        longName: screenName,
        name: screenName
      }
    }

    const response: any = await Api.sendFeedback(composedFeedback)
    if (response.ok) {
      this.setFeedbackSentAt(Date.now())
    }
    this.setLoading(false)
  }
}

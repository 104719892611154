import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import Api from '../Services/Api'

export default class TransportationStore {
  rootStore
  loading = false

  trains = []
  buses = []
  flights = []

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setTrains = (trains) => { this.trains = trains }
  setBuses = (buses) => { this.buses = buses }
  setFlights = (flights) => { this.flights = flights }
  setLoading = (loading) => { this.loading = loading  }

  async getTrains(type) {
    this.setLoading(true)
    this.setTrains([])
    const response = await Api.getTrains(type)
    if (response.ok) {
      // Filtering
      const trains = response.data.filter((train) => {
        return train.scheduledTime &&
          moment(train.scheduledTime).subtract(24, 'hours').isBefore(moment()) &&
          moment(train.scheduledTime).add(15, 'minutes').isAfter(moment())

      })
      this.setTrains(trains)
    }
    this.setLoading(false)
  }

  async getBuses() {
    this.setLoading(true)
    this.setBuses([])
    const response = await Api.getBuses()
    if (response.ok) {
      const buses = response.data
      this.setBuses(buses)
    }
    this.setLoading(false)
  }

  async getFlights(type) {
    this.setLoading(true)
    this.setFlights([])
    const response = await Api.getFlights(type)
    if (response.ok) {
      const flights = response.data
      this.setFlights(flights)
    }
    this.setLoading(false)
  }
}

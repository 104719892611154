import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import moment from 'moment'
import AppLogo from '../../Assets/Icons/app-logo.svg'
import InfoIcon from '../../Assets/Icons/info.svg'
import { observer } from 'mobx-react'
import { useStore } from '../../Models/RootStore'
import InfoModal from './InfoModal'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '67.5rem',
    zIndex: 2,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: '7.875rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.appGreyDark,
    color: Colors.white,
    fontWeight: 800,
    margin: 'auto'
  },
  startContainer: {
    backgroundColor: Colors.black,
    color: Colors.white
  },
  logo: {
    marginTop: '1rem',
    height: '3.75rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    width: '10rem',
    fontSize: '2.25rem',
    paddingLeft: '2rem'
  },
  centerColumn: {
    flex: 1,
    textAlign: 'center',
    fontSize: '2.25rem',
    textTransform: 'uppercase',
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '10rem',
    paddingRight: '2rem'
  },
  actionButton: {
    padding: '1rem',
    paddingLeft: '1.5rem'
  },
  actionIcon: {
    height: '3.5rem'
  }
}))

function AppHeader(props) {
  const classes = useStyles()
  const { appStore } = useStore()

  const [infoVisible, setInfoVisible] = useState(false)
  const [time, setTime] = useState(moment())

  const toggleInfo = () => setInfoVisible(!infoVisible)
  const hideInfo = () => setInfoVisible(false)

  const refreshTime = () => setTime(moment())

  useEffect(() => {
    const timerId = setInterval(refreshTime, 1000)
    return function cleanup() {
      clearInterval(timerId)
    }
  }, [])

  const renderTitleOrLogo = () => {
    if (props.title) {
      return props.title
    }
    return <img src={AppLogo} className={classes.logo} />
  }

  const renderInfoModal = () => {
    return (
      <InfoModal
        open={infoVisible}
        onClose={hideInfo}
        locale={appStore.locale}
      />
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>{time.format('HH:mm')}</div>
      <div className={classes.centerColumn}>{renderTitleOrLogo()}</div>
      <div className={classes.rightColumn}>
        <IconButton
          onClick={toggleInfo}
          className={classes.actionButton}
          size='large'
        >
          <img src={InfoIcon} className={classes.actionIcon} />
        </IconButton>
      </div>
      {renderInfoModal()}
    </div>
  )
}

export default observer(AppHeader)

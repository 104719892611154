import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { AppHeader, AppContent, Button, EventListItem, Modal, SelectList } from '../Components'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles((theme) => ({
  container: {
  },
  content: {
    padding: '0rem 3rem 20rem'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '3.125rem',
    marginBottom: '1rem'
  },
  description: {
    fontSize: '2rem',
    color: Colors.content,
    marginBottom: '3rem'
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '3rem'
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: Colors.white
  }
}))

function EventsScreen() {
  const classes = useStyles()
  const { eventStore, appStore } = useStore()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [filtersVisible, setFiltersVisible] = useState(false)
  const [activeCategories, setActiveCategories] = useState([])

  const toggleFiltersVisible = () => setFiltersVisible(!filtersVisible)

  const clearFilters = () => {
    setActiveCategories([])
    setFiltersVisible(false)
  }

  useEffect(() => {
    eventStore.getEvents(appStore.locale)
  }, [])

  const openItem = (item) => {
    eventStore.setEvent(item)
    navigate(`/events/${item.id}`)
  }

  const getCategories = () => (eventStore.eventCategories || []).map(cat => {
    return {
      name: cat.label,
      value: cat.value
    }
  })

  const listItems = useMemo(() => {
    let events = eventStore.events
    if (activeCategories.length) {
      events = events.filter(item => {
        const cats = (item.categories || []).map(c => c.value)
        for (const activeCat of activeCategories) {
          if (cats.includes(activeCat)) return true
        }
        return false
      })
    }
    return events
  }, [eventStore.events, activeCategories])

  const renderGridItems = () => {
    return listItems.map((item, index) => (
      <Grid key={item.id} item xs={6}>
        <EventListItem
          item={item}
          onOpen={openItem}
        />
      </Grid>
    ))
  }

  const renderGrid = () => {
    if (eventStore.loading && !listItems.length) {
      return <CircularProgress size='5rem' classes={{ root: classes.loader }} />
    }

    return (
      <Grid container spacing={6}>
        {renderGridItems()}
      </Grid>
    )
  }

  const renderFilters = () => {
    return (
      <Modal
        title={t('filter_search_results')}
        onCancel={clearFilters}
        onOk={toggleFiltersVisible}
        okText={t('view_results')}
        open={filtersVisible}
      >
        <SelectList
          items={getCategories()}
          values={activeCategories}
          onChange={setActiveCategories}
        />
      </Modal>
    )
  }

  const filterCountSuffix = activeCategories.length ? `(${activeCategories.length})` : ''

  return (
    <div className={classes.container}>
      <AppHeader title={t('event_calendar')} />
      <AppContent>
        <div className={classes.content}>
          <h1 className={classes.title}>{t('events_title')}</h1>
          <p className={classes.description}>{t('events_info')}</p>
          <div className={classes.buttonsRow}>
            <Button
              text={`${t('action_filter')} ${filterCountSuffix}`.trim()}
              onClick={toggleFiltersVisible}
              outlined
              fullWidth
            />
          </div>
          {renderGrid()}
          {renderFilters()}
        </div>
      </AppContent>
    </div>
  )
}

export default observer(EventsScreen)

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import {
  getIsCurrentTimeAfterClosingTime,
  getPlaceOpenStatus,
  getTodaysClosingHour,
  getTodaysOpeningHour
} from '../../Utils/jana'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    // cursor: 'pointer',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    height: '28rem',
    borderRadius: '.625rem'
  },
  content: {
    flex: 1,
    padding: '1rem'
  },
  category: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    backgroundColor: Colors.appGrey,
    color: Colors.white,
    fontSize: '1rem',
    borderRadius: '.3125rem',
    padding: '1rem'
  },
  titleContainer: {
    background: 'linear-gradient(360deg, #263238 0%, rgba(38, 50, 56, 0) 100%)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '2rem'
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '1.75rem',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  subtitle: {
    fontFamily: 'Manrope',
    fontSize: '1.25rem',
    color: Colors.white70
  }
}))

export default function SaunaListItem(props) {
  const { item } = props
  const { t } = useTranslation()

  const classes = useStyles()

  const getPhotoStyle = () => {
    const photo = get(item, 'photo')
    let bgStyle = {}
    if (photo) {
      bgStyle = {
        style: { backgroundImage: `url(${photo})` }
      }
    }
    return bgStyle
  }

  const onOpen = () => props.onOpen(item)

  const renderOpenStatus = () => {
    const openHours = item.fields.find((field) => field.fieldKey === 'open_hours')
    const statusText = openHours ? getPlaceOpenStatus(openHours.value) : 'closed_alt'
    if (statusText === 'open') {
      const closes = getTodaysClosingHour(openHours.value)
      const closesText = closes ? `${t('closes_at')} ${t('clock_short')} ${closes}` : null
      return <p className={classes.subtitle}>{t(statusText)} - {closesText}</p>
    }
    const isAfterClosingTime = getIsCurrentTimeAfterClosingTime(openHours.value)
    const opens = getTodaysOpeningHour(openHours.value)
    if (opens && !isAfterClosingTime) {
      return (
        <p className={classes.subtitle}>
          {t(statusText)} - {t('opens_at')} {t('clock_short')} {opens}
        </p>
      )
    }
    return <p className={classes.subtitle}>{t(statusText)}</p>
  }

  const renderTitle = () => {
    return (
      <div className={classes.titleContainer}>
        <div className={classes.title}>{get(item, 'name')}</div>
        {renderOpenStatus()}
      </div>
    )
  }

  return (
    <div
      className={classes.container}
      onClick={onOpen}
      {...getPhotoStyle()}
    >
      {renderTitle()}
    </div>
  )
}

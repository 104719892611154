import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import get from 'lodash/get'

import CoudIcon from '../../Assets/Icons/weather/cloud.svg'
import DrizzleIcon from '../../Assets/Icons/weather/drizzle.svg'
import FogIcon from '../../Assets/Icons/weather/fog.svg'
import HalfCloudyMoonIcon from '../../Assets/Icons/weather/half-cloudy-moon.svg'
import HalfMoonIcon from '../../Assets/Icons/weather/half-moon.svg'
import RainIcon from '../../Assets/Icons/weather/rain.svg'
import SnowIcon from '../../Assets/Icons/weather/snow.svg'
import SunIcon from '../../Assets/Icons/weather/sun.svg'
import ThunderIcon from '../../Assets/Icons/weather/thunder.svg'

import LocationPinIcon from '../../Assets/Icons/location-pin.svg'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1rem'
  },
  flexSpacer: {
    flex: 1
  },
  locationPinContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '1.75rem'
  },
  locationPin: {
    height: '6rem'
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  locationTitle: {
    fontFamily: 'Manrope',
    color: Colors.white70,
    fontSize: '2rem',
    marginBottom: '0.125rem'
  },
  locationName: {
    fontFamily: 'Manrope',
    fontWeight: 800,
    fontSize: '2.5rem',
    textTransform: 'uppercase'
  },
  weatherTemp: {
    fontFamily: 'Manrope',
    fontWeight: 800,
    fontSize: '6.375rem',
    lineHeight: 1
  },
  weatherIcon: {
    marginLeft: '1.25rem',
    color: Colors.white,
    height: '5.75rem'
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

export default function Weather(props) {
  const { weather } = props

  const { t } = useTranslation()

  const classes = useStyles()

  const getWeatherIcon = (iconCode) => {
    const WEATHER_ICONS = {
      '01d': SunIcon, // clear sky
      '01n': HalfMoonIcon,
      '02d': HalfCloudyMoonIcon, // few clouds
      '02n': HalfCloudyMoonIcon,
      '03d': CoudIcon, // scattered clouds
      '03n': CoudIcon,
      '04d': CoudIcon, // broken clouds
      '04n': CoudIcon,
      '09d': DrizzleIcon, // shower rain
      '09n': DrizzleIcon,
      '10d': RainIcon, // rain
      '10n': RainIcon,
      '11d': ThunderIcon, // thunderstorm
      '11n': ThunderIcon,
      '13d': SnowIcon, // snow
      '13n': SnowIcon,
      '50d': FogIcon, // mist
      '50n': FogIcon
    }
    return WEATHER_ICONS[iconCode] || null
  }

  const renderWeatherIcon = () => {
    const icon = getWeatherIcon(weather.icon)
    if (icon) {
      return <img src={icon} className={classes.weatherIcon} />
    }
  }

  if (!weather) return null

  const renderTemp = () => {
    const temp = Math.round(weather.temp)
    let prefix = ''
    if (temp > 0) prefix = '+'

    return `${prefix}${temp}`
  }

  return (
    <div className={classes.container} onClick={props.onOpen}>
      <div className={classes.locationPinContainer}>
        <img src={LocationPinIcon} className={classes.locationPin} />
      </div>
      <div className={classes.locationContainer}>
        <div className={classes.locationTitle}>{t('you_are_at_location')}</div>
        <div className={classes.locationName}>{props.locationName}</div>
      </div>
      <div className={classes.flexSpacer} />
      <div className={classes.rightColumn}>
        <div className={classes.weatherTemp}>{renderTemp()}</div>
        {renderWeatherIcon()}
      </div>
    </div>
  )
}

import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStore } from '../Models/RootStore'
import moment from 'moment'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import ButtonBase from '@mui/material/ButtonBase'
import Grid from '@mui/material/Grid'
import { AppContent, AppHeader, MajorEventProgramItem, JanaInfoModal, ToggleTabs, Button, QrCodeModal } from '../Components'
import {
  getEntityCenterCoords,
  getFormattedJanaFieldValue,
  pickJanaField,
  pickJanaFieldValue
} from '../Utils/jana'
import { Colors } from '../Utils/theme'
import { getMapsLink } from '../Utils/map'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0rem 3rem 20rem'
  },
  photoContainer: {
    position: 'relative'
  },
  photo: {
    height: '56.25vw',
    maxHeight: 'calc(67.5rem * 0.5625)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  categoryContainer: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    height: '4.25rem',
    padding: '0rem 1rem',
    borderRadius: '0.625rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.appGrey
  },
  category: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white,
    margin: 0
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: '3.75rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    margin: 0,
    marginTop: '2rem'
  },
  date: {
    fontFamily: 'Manrope',
    fontSize: '2.5rem',
    color: Colors.white70,
    margin: 0,
    marginTop: '0.5rem',
    marginBottom: '1.5rem'
  },
  description: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70,
    marginBottom: '2.5rem'
  },
  subtitle: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white,
    fontWeight: 800,
    marginTop: '2.5rem',
    marginBottom: '1rem'
  },
  tabs: {
    marginTop: '2.5rem',
    marginBottom: '2.5rem'
  },
  text: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70,
    marginBottom: '0.125rem'
  },
  groupText: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70
  },
  linkContainer: {
    marginBottom: '0.25rem'
  },
  link: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    fontWeight: 800,
    color: Colors.white
  },
  linkValue: {
    fontSize: '1.875rem',
    fontFamily: 'Manrope',
    color: Colors.white70,
    textDecorationLine: 'underline',
    textDecorationColor: Colors.white70,
    marginBottom: '0.375rem'
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '1rem'
  },
  tagContainer: {
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.appGrey,
    borderRadius: '2rem',
    padding: '0rem 2rem',
    marginTop: '1rem',
    marginRight: '1rem'
  },
  tag: {
    fontFamily: 'Manrope',
    fontSize: '1.75rem',
    color: Colors.white
  },
  programDate: {
    fontFamily: 'Manrope',
    fontSize: '2.5rem',
    color: Colors.white,
    fontWeight: 800,
    marginTop: '3rem'
  },
  programLocation: {
    fontFamily: 'Manrope',
    fontSize: '1.875rem',
    color: Colors.white70,
    fontWeight: 800,
    marginTop: '1.25rem',
    marginBottom: '1.75rem'
  },
  locationGridSpacer: {
    height: '0.75rem'
  },
  footer: {
    maxWidth: 'calc(67.5rem - 6rem)',
    position: 'absolute',
    bottom: '2.5rem',
    left: '3rem',
    right: '3rem',
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto'
  },
  leftButton: {
    flex: 1,
    marginRight: '1.5rem'
  },
  rightButton: {
    flex: 1,
    marginLeft: '1.5rem'
  }
}))

function MajorEventScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { majorEventStore, appStore } = useStore()
  const navigate = useNavigate()
  const { locale } = appStore
  const { event } = majorEventStore

  const [tab, setTab] = useState('general')
  const [modalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [routeModalVisible, setRouteModalVisible] = useState(false)

  const openModal = (fieldKey) => {
    setModalVisible(true)
    setModalType(fieldKey)
  }
  const closeModal = () => {
    setModalVisible(false)
    setModalType(null)
  }

  const viewFullDescription = () => openModal('description')
  const viewServiceContactInfo = (info) => openModal({
    ...info,
    type: 'event_services_contact_information'
  })
  const toggleRouteModal = () => setRouteModalVisible(!routeModalVisible)
  const toMap = () => navigate('/major-event-map')

  const getDescription = () => {
    if (event?.description?.length) {
      let description = event?.description.replace(/\n{2,}\s*/g, '\n')
      if (description.length > 235) {
        return `${description.substring(0, 235).trim()}...`
      }
      return description
    }
    return null
  }

  const getEventLocations = () => {
    return event
      .subEntities
      .filter((subEntity) => subEntity?.subEntityType?.type === 'event_place')
  }

  const program = useMemo(() => {
    try {
      const locations = getEventLocations()
      const today = moment()
      const todayStr = today.format('DD.MM.YYYY')
      const allProgram = flatten(
        locations.map((item) => {
          return (pickJanaFieldValue(item, 'program_list') ?? [])
            .map((program) => ({ ...program, id: item.id, name: item.name }))
        })
      ).filter((item) => {
        const endDate = moment(item?.endDate)
        return endDate.isSameOrAfter(today) || endDate.format('DD.MM.YYYY') === todayStr
      })
      const dailyPrograms = uniq(
        allProgram.map((item) => moment(item?.startDate).format('DD.MM.YYYY'))
      ).map((date) => {
        let locations: any = allProgram
          .filter((program) => moment(program.startDate).format('DD.MM.YYYY') === date)
        locations = locations.reduce((result: any, item: any) => ({
            ...result,
            [item['name']]: [...(result[item['name']] || []), item]
          }),
          {}
        )
        return { date, locations }
      })
      return dailyPrograms
    } catch (e) {
      console.log(e)
    }
    return []
  }, [event])

  const renderPhoto = () => {
    if (event?.photo) {
      return (
        <div className={classes.photoContainer}>
          <div
            className={classes.photo}
            style={{ backgroundImage: `url(${event.photo})` }}
          />
          {renderCategory()}
        </div>
      )
    }
    return null
  }

  const renderCategory = () => {
    const category = event?.mainEntityType?.name ?? null
    if (category) {
      return (
        <div className={classes.categoryContainer}>
          <p className={classes.category}>{category}</p>
        </div>
      )
    }
    return null
  }

  const renderDates = () => {
    const start = pickJanaFieldValue(event, 'event_start_date') ?? null
    const end = pickJanaFieldValue(event, 'event_end_date') ?? null
    if (start && end) {
      const startTime = moment(start)
      const endTime = moment(end).format('D.M.YYYY')
      if (startTime.format('D.M.YYYY') === endTime) {
        return <p className={classes.date}>{endTime}</p>
      }
      return <p className={classes.date}>{startTime.format('D.M.')}-{endTime}</p>
    }
    return null
  }

  const renderDescriptionReadMore = () => {
    if (event?.description?.length > 235) {
      return (
        <ButtonBase className={classes.linkContainer} onClick={viewFullDescription}>
          <p className={classes.link}>{t('read_more')}</p>
        </ButtonBase>
      )
    }
    return null
  }

  const renderDescription = () => {
    const description = getDescription()
    if (description) {
      return (
        <div className={classes.description}>
          {description} {renderDescriptionReadMore()}
        </div>
      )
    }
    return null
  }

  const renderTicketInfo = () => {
    const ticketSalesLink = pickJanaFieldValue(event, 'ticket_sales_link') ?? null
    if (ticketSalesLink) {
      return (
        <>
          <p className={classes.subtitle}>{t('event_ticket_sales')}</p>
          <p className={classes.text}>
            {t('buy_tickets')}: <p className={classes.link}>
              {ticketSalesLink}
            </p>
          </p>
        </>
      )
    }
    return null
  }

  const renderLocationInfo = () => {
    const place = pickJanaFieldValue(event, 'place_name')
    const address = pickJanaFieldValue(event, 'street_address')
    const postalCode = pickJanaFieldValue(event, 'postal_code')
    const city = pickJanaFieldValue(event, 'city')
    const url = pickJanaFieldValue(event, 'www')
    if (place || address || postalCode || city || url) {
      return (
        <>
          <p className={classes.subtitle}>{t('event_location')}</p>
          {place ? <p className={classes.groupText}>{place}</p> : null}
          {address ? <p className={classes.groupText}>{address}</p> : null}
          {postalCode || city ? <p className={classes.groupText}>{`${postalCode ?? ''} ${city ?? ''}`.trim()}</p> : null}
          {url ? <p className={classes.text}>{url}</p> : null}
        </>
      )
    }
    return null
  }

  const renderOrganizerInfo = () => {
    const organizer = pickJanaFieldValue(event, 'organizer')
    const email = pickJanaFieldValue(event, 'email')
    if (organizer || email) {
      return (
        <>
          <p className={classes.subtitle}>{t('organizer_contact_info')}</p>
          {organizer ? <p className={classes.groupText}>{organizer}</p> : null}
          {email ? <p className={classes.text}>{email}</p> : null}
        </>
      )
    }
    return null
  }

  const renderServicesContactInfo = () => {
    const infos = pickJanaFieldValue(event, 'event_contact_infos')
    if (infos && infos?.length) {
      return (
        <>
          <p className={classes.subtitle}>{t('services_contact_information')}</p>
          {
            infos.map((info, index) => (
              <p
                key={`${info?.title}-${index}`}
                className={classes.linkValue}
                onClick={() => viewServiceContactInfo(info)}
              >
                {info?.title ?? ''}
              </p>
            ))
          }
        </>
      )
    }
    return null
  }

  const renderTags = () => {
    const tags = []
    const ageRestriction = getFormattedJanaFieldValue(t, pickJanaField(event, 'age_restriction'))
    const accessible = pickJanaFieldValue(event, 'accessible_travel')
    if (ageRestriction) tags.push(ageRestriction)
    if (accessible) tags.push(accessible)
    if (tags?.length) {
      return (
        <>
          <p className={classes.subtitle}>{t('other_info')}</p>
          <p className={classes.tags}>
            {tags.map((tag) => (
              <p key={tag} className={classes.tagContainer}>
                <p className={classes.tag}>{tag}</p>
              </p>
            ))}
          </p>
        </>
      )
    }
    return null
  }

  const renderGeneralInformation = () => {
    return (
      <>
        {renderDescription()}
        {renderTicketInfo()}
        {renderLocationInfo()}
        {renderOrganizerInfo()}
        {renderServicesContactInfo()}
        {renderTags()}
      </>
    )
  }

  const renderProgramItemsByLocation = (items) => {
    if (items?.length) {
      return items.map((item, index) => (
        <Grid key={item.id} item xs={6}>
          <MajorEventProgramItem key={`${index}-${item?.id}`} item={item} />
        </Grid>
      ))
    }
    return null
  }

  const renderProgramGrid = (locations) => {
    const keys = Object.keys(locations)
    return keys.map((location, index) => (
      <>
        <p className={classes.programLocation}>{location ?? ''}</p>
        <Grid container spacing={6}>
          {renderProgramItemsByLocation(locations[location] ?? [])}
        </Grid>
        {index < keys.length - 1 ? <p className={classes.locationGridSpacer} /> : null}
      </>
    ))
  }

  const renderProgramByEventLocation = () => {
    return program.map((item) => {
      return (
        <>
          <p className={classes.programDate}>{item?.date ?? ''}</p>
          {renderProgramGrid(item?.locations ?? [])}
        </>
      )
    })
  }

  const renderProgram = () => {
    if (program && program?.length) {
      return (
        <>
          <p className={classes.description}>{t('major_event_program_info')}</p>
          {renderProgramByEventLocation()}
        </>
      )
    }
    return <p className={classes.description}>{t('no_program_info')}</p>
  }

  const renderTabContent = () => {
    if (tab === 'general') {
      return renderGeneralInformation()
    } else {
      return renderProgram()
    }
  }

  const renderFooter = () => {
    return (
      <div className={classes.footer}>
        <div className={classes.leftButton}>
          <Button
            text={t('download_route')}
            onClick={toggleRouteModal}
            outlined
            fullWidth
          />
        </div>
        <div className={classes.rightButton}>
          <Button
            text={t('view_on_map')}
            onClick={toMap}
            outlined
            fullWidth
          />
        </div>
      </div>
    )
  }

  const renderModal = () => {
    if (modalVisible) {
      return (
        <JanaInfoModal
          type={modalType}
          fields={[]}
          description={event?.description ?? ''}
          onClose={closeModal}
          locale={locale}
          visible
        />
      )
    }
    return null
  }

  const renderRouteModal = () => {
    const coords = getEntityCenterCoords(event)
    let code: any = null

    if (coords && coords.latitude && coords.longitude) {
      code = getMapsLink(coords.latitude, coords.longitude)
    }

    if (code) {
      return (
        <QrCodeModal
          title={t('download_route_to_your_phone')}
          onCancel={toggleRouteModal}
          open={routeModalVisible}
          code={code}
        />
      )
    }
    return null
  }

  return (
    <div>
      <AppHeader title={t('major_events')} />
      <AppContent>
        {renderPhoto()}
        <div className={classes.content}>
          <h1 className={classes.title}>{event?.name ?? ''}</h1>
          {renderDates()}
          <div className={classes.tabs}>
            <ToggleTabs
              items={[
                { name: t('general_information'), value: 'general' },
                { name: t('program'), value: 'program' }
              ]}
              value={tab}
              onChange={setTab}
            />
          </div>
          {renderTabContent()}
        </div>
        {renderFooter()}
      </AppContent>
      {renderModal()}
      {renderRouteModal()}
    </div>
  )
}

export default observer(MajorEventScreen)

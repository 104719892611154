import { makeAutoObservable } from 'mobx'
import Api from '../Services/Api'
import { compareItemDistances } from '../Utils/map'

export default class RestaurantStore {
  rootStore
  loading = false

  restaurants = []
  restaurant = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading  }
  setRestaurants = (restaurants) => { this.restaurants = restaurants }
  setRestaurant = (restaurant) => { this.restaurant = restaurant }

  async getRestaurants() {
    this.setLoading(true)
    const response = await Api.getRestaurants()
    if (response.ok) {
      const restaurants = response.data.sort((a, b) => {
        return compareItemDistances(this.rootStore.appStore.location, a, b)
      })
      this.setRestaurants(restaurants)
    }
    this.setLoading(false)
  }
}

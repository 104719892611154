import React from 'react'
import moment from 'moment'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) =>({
  root: {
    marginBottom: '5rem'
  },
  row: {
    backgroundColor: Colors.appGreyDark,
    border: 'none'
  },
  evenRow: {
    backgroundColor: Colors.appGreyDarkest,
    border: 'none'
  },
  firstCell: {
    fontFamily: 'Manrope',
    color: Colors.white70,
    fontSize: '1.625rem',
    fontWeight: 600,
    height: '5rem',
    borderBottom: 'none'
  },
  lastCell: {
    fontFamily: 'Manrope',
    color: Colors.white70,
    fontSize: '1.625rem',
    fontWeight: 600,
    height: '5rem',
    borderBottom: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontFamily: 'Manrope',
    color: Colors.white70,
    fontSize: '1.625rem',
    fontWeight: 600,
    height: '5rem',
    borderBottom: 'none'
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: Colors.white
  }
}))

function BusTableRow (props: any) {
  const { item, odd } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const getDuration = () => {
    const duration = moment(item.date).diff(moment(), 'minutes') + 1

    if (duration < 0) return t('now').toUpperCase()

    if (duration > 59) {
      const hours = Math.floor(duration / 60)
      const minutes = duration - (hours * 60)
      return `${hours} h ${minutes} min`
    }

    return `${duration} min`
  }


  return (
    <TableRow classes={{ root: odd ? classes.row : classes.evenRow }}>
      <TableCell className={classes.firstCell} size='small'>
        {getDuration()}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {item.route}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {item.company || '-'}
      </TableCell>
      <TableCell className={classes.cell} size='small'>
        {moment(item.date).format('HH:mm')}
      </TableCell>
      <TableCell className={classes.lastCell} size='small'>
        {item.platform || '-'}
      </TableCell>
    </TableRow>
  )
}

function BusTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item, index) => (
        <React.Fragment key={`${item.id}-${index}`}>
          < BusTableRow
            item={item}
            type={props.type}
            odd={!(index % 2)}
          />
        </React.Fragment>
      ))
    }
    return null
  }

  const getHeaderColumns = () => {
    return [t('leaves_in'), t('route'), t('bus_company'), t('departure_time'), t('platform')]
  }

  const renderMessage = () => {
    if (!props.loading) {
      return <DataMessage data={props.items} message={t('no_info')} />
    }
    return <CircularProgress size='5rem' classes={{ root: classes.loader }} />
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader
          columnNames={getHeaderColumns()}
        />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {renderMessage()}
    </>
  )

}

export default BusTable

import { makeAutoObservable } from 'mobx'
import { MapsApi } from '../Services'
import { decodeOverviewPolylinePoints } from '../Utils/map'
import { get } from 'lodash'

export default class MoveStore {
  rootStore
  loading = false

  directions = null
  directionsDistance = null
  lastDirections = ''


  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setDirections = (directions) => { this.directions = directions }
  setDirectionsDistance = (directionsDistance) => { this.directionsDistance = directionsDistance }
  setLastDirections = (lastDirections) => { this.lastDirections = lastDirections }


  async getDirections(from: any, to: any) {
    let start = ''
    let end = ''

    // Set start
    if (from && from.latitude && from.longitude) {
      start = `${from.latitude},${from.longitude}`
    }
    if (from && from.lat && from.lng) {
      start = `${from.lat},${from.lng}`
    }

    // Set end
    if (to && to.latitude && to.longitude) {
      end = `${to.latitude},${to.longitude}`
    }
    if (to && to.lat && to.lng) {
      end = `${to.lat},${to.lng}`
    }

    const directionsKey = `${start}_${end}`

    const mode = 'walking'
    if (start && end && this.lastDirections !== directionsKey) {
      this.setDirections(null)
      this.setDirectionsDistance(null)
      const response = await MapsApi.getDirections(start, end, mode)
      if (response.ok && response.data) {
        if (response.data.routes && response.data.routes.length) {
          this.setLastDirections(directionsKey)
          const decoded = decodeOverviewPolylinePoints(get(response.data.routes[0], 'overview_polyline.points'))
          this.setDirections(decoded)
          this.setDirectionsDistance(get(response.data.routes[0], 'legs[0].distance.value'))
        }
      }
    }
  }
}

import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import Notification from '../../Components/Common/Notification'
import { useStore } from '../../Models/RootStore'
import NavigationBar from '../../Components/Common/NavigationBar'
import { Colors } from '../../Utils/theme'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    backgroundColor: Colors.appGrey,
    // Disable text selection on all elements
    userSelect: 'none'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    maxWidth: '67.5rem',
    margin: 'auto',
    backgroundColor: Colors.black
  },
  navigationBarContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '1rem'
  }
}))

function AppLayout(props) {
  const classes = useStyles()

  const { appStore }: any = useStore()
  const { notification, clearNotification } = appStore
  const location = useLocation()

  const [isTouchDevice, setIsTouchDevice] = useState(false)

  useEffect(() => {
    // Scroll to top when location changes
    try {
      window.document.getElementById('app-main-content').scrollTop = 0
    } catch (err) {
      console.log(err)
    }
  }, [location])

  const [touchStartEvent, setTouchStartEvent] = useState(null)
  const [navigationBarVisible, setNavigationBarVisible] = useState(false)
  const [hideNavigationBarTimeout, setHideNavigationBarTimeout] = useState(null)

  const revealNavigationBar = () => {
    setNavigationBarVisible(true)
    if (hideNavigationBarTimeout) clearTimeout(hideNavigationBarTimeout)
    const newTimeout = setTimeout(() => setNavigationBarVisible(false), 5000)
    setHideNavigationBarTimeout(newTimeout)
  }

  const pythagorean = (sideA, sideB) => {
    return Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2))
  }

  const handleTouchStart = (event) => {
    const { clientX, clientY } = event.touches[0]
    if (clientX < (window.innerWidth / 2)) {
      setTouchStartEvent({
        x: clientX,
        y: clientY,
        timestamp: Date.now()
      })
    }
  }

  const handleTouchEnd = (event) => {
    if (touchStartEvent && Date.now() - touchStartEvent.timestamp < 3000) {
      const { clientX, clientY } = event.changedTouches[0]
      if (touchStartEvent.x < clientX) {
        const hypotenuse = pythagorean(
          (touchStartEvent.x - clientX),
          (touchStartEvent.y - clientY)
        )
        if (hypotenuse > 80) {
          // Calculate angle
          const angle = Math.sin(Math.abs(touchStartEvent.y - clientY) / hypotenuse)
          if (angle < 0.3) {
            revealNavigationBar()
          }
        }
      }
    }
    setTouchStartEvent(null)
  }

  return (
    <div
      className={classes.root}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <main className={classes.content} id="app-main-content">
        {props.children}
      </main>
      <div className={classes.navigationBarContainer}>
        <NavigationBar
          locale={appStore.locale}
          setLocale={appStore.setLocale}
          open={navigationBarVisible}
          onOpen={revealNavigationBar}
        />
      </div>
      <Notification notification={notification} clearNotification={clearNotification} />
    </div>
  )
}

export default observer(AppLayout)

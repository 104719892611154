import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useEffect, useState, useMemo } from 'react'
import { AppHeader, AppContent, Button, CouponOrganizationListItem, Modal, SelectList } from '../Components'
import { useStore } from '../Models/RootStore'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import { Colors } from '../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
  },
  content: {
    padding: '0rem 3rem 20rem'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '3.125rem',
    marginBottom: '1rem'
  },
  description: {
    fontSize: '2rem',
    color: Colors.content,
    marginBottom: '3rem'
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '3rem'
  },
  leftButton: {
    flex: 1,
    paddingRight: '1.5rem'
  },
  centerButton: {
    flex: 1,
    paddingLeft: '.75rem',
    paddingRight: '.75rem'
  },
  rightButton: {
    flex: 1,
    paddingLeft: '1.5rem'
  },
  rightButtonExtraStyle: {
    backgroundColor: Colors.appGreyDark,
    '&:hover': {
      backgroundColor: Colors.appGreyDark50
    }
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: Colors.white
  }
}))

function CouponOrganizationsScreen() {
  const classes = useStyles()
  const { couponStore, appStore } = useStore()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [filtersVisible, setFiltersVisible] = useState(false)
  const [activeCategories, setActiveCategories] = useState([])

  const toggleFiltersVisible = () => setFiltersVisible(!filtersVisible)

  const clearFilters = () => {
    setActiveCategories([])
    setFiltersVisible(false)
  }

  const toMap = () => navigate('/coupon-organizations-map')
  const toCoupons = () => navigate('/coupons')

  useEffect(() => {
    couponStore.getCouponOrganizations()
    couponStore.getCoupons()
    couponStore.setCouponOrganization(null)
  }, [])

  const openItem = (item) => {
    couponStore.setCouponOrganization(item)
    navigate(`/organizations/${item.id}`)
  }

  const listItems = useMemo(() => {
    let organizations = couponStore.couponOrganizations
    if (activeCategories.length) {
      organizations = organizations.filter(item => {
        const cats = [item.categoryId || -1, ...(item.categoryIds || [])]
        for (const activeCat of activeCategories) {
          if (cats.includes(activeCat)) return true
        }
        return false
      })
    }
    return organizations
  }, [couponStore.couponOrganizations, activeCategories])

  const renderGridItems = () => {
    return listItems.map((item, index) => (
      <Grid key={item.id} item xs={6}>
        <CouponOrganizationListItem
          onOpen={openItem}
          locale={appStore.locale}
          location={appStore.location}
          item={item}
        />
      </Grid>
    ))
  }

  const renderGrid = () => {
    if (couponStore.loading && !listItems.length) {
      return <CircularProgress size='5rem' classes={{ root: classes.loader }} />
    }

    return (
      <Grid container spacing={6}>
        {renderGridItems()}
      </Grid>
    )
  }

  const getCategories = () => {
    return couponStore.categories.map(item => {
      return {
        name: appStore.locale === 'en' ? item.titleEn : item.titleFi,
        value: item.id
      }
    })
  }

  const renderFilters = () => {
    return (
      <Modal
        title={t('filter_search_results')}
        onCancel={clearFilters}
        onOk={toggleFiltersVisible}
        okText={t('view_results')}
        open={filtersVisible}
      >
        <SelectList
          items={getCategories()}
          values={activeCategories}
          onChange={setActiveCategories}
        />
      </Modal>
    )
  }

  const filterCountSuffix = activeCategories.length ? `(${activeCategories.length})` : ''

  return (
    <div className={classes.container}>
      <AppHeader title={t('tampere_tunnetuksi_pass')} />
      <AppContent>
        <div className={classes.content}>
          <h1 className={classes.title}>{t('companies')}</h1>
          <p className={classes.description}>{t('lorem_ipsum')}</p>
          <div className={classes.buttonsRow}>
            <div className={classes.leftButton}>
              <Button
                text={`${t('action_filter')} ${filterCountSuffix}`.trim()}
                onClick={toggleFiltersVisible}
                outlined
                fullWidth
              />
            </div>
            <div className={classes.centerButton}>
              <Button
                text={t('on_map')}
                onClick={toMap}
                outlined
                fullWidth
              />
            </div>
            <div className={classes.rightButton}>
              <Button
                text={t('offers')}
                onClick={toCoupons}
                buttonStyle={classes.rightButtonExtraStyle}
                fullWidth
              />
            </div>
          </div>
          {renderGrid()}
          {renderFilters()}
        </div>
      </AppContent>
    </div>
  )
}

export default observer(CouponOrganizationsScreen)

import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContent, AppHeader, LineItem } from '../Components'
import { useStore } from '../Models/RootStore'
import { Colors } from '../Utils/theme'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '2rem 2rem 20rem'
  },
  photo: {
    height: '56.25vw',
    maxHeight: 'calc(67.5rem * 0.5625)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  h1: {
    fontSize: '3.75rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    margin: '1rem 0 0'
  },

  title: {
    fontSize: '1.875rem',
    fontWeight: 800
  },
  subTitle: {
    fontSize: '2.5rem',
    color: Colors.white70,
    marginBottom: '2rem'
  },
  description: {
    marginBottom: '1rem',
    fontSize: '1.875rem',
    color: Colors.white70
  },
  smallTitle: {
    fontSize: '1.875rem',
    fontWeight: 800,
    color: Colors.white,
    marginTop: '2rem',
    marginBottom: '1rem'
  },
  category: {
    display: 'inline-block',
    backgroundColor: Colors.appGrey,
    color: Colors.white,
    fontSize: '1.875rem',
    borderRadius: '.3125rem',
    padding: '.66rem .85rem'
  },
  footer: {
    position: 'fixed',
    backgroundColor: Colors.black,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '2rem'
  },
  buttonsRow: {
    paddingTop: '2rem',
    display: 'flex',
    flexDirection: 'row'
  },
  leftButton: {
    flex: 1,
    paddingRight: '1rem'
  },
  rightButton: {
    flex: 1,
    paddingLeft: '1rem'
  }
}))

function StopScreen() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { moveStore, appStore } = useStore()

  useEffect(() => {
    moveStore.getStopTimetable(moveStore.stop)
  }, [])


  const getLocalized = (field) => {
    const locale = appStore.locale
    if (locale === 'en') {
      if (field && field.en) {
        return field.en
      }
    }

    if (field && field.fi) {
      return field.fi
    }

    return null
  }

  const renderList = () => {
    return (moveStore?.stop?.departures || []).map((departure, index) => {
      return (
        <LineItem
          key={index}
          line={departure}
          stop={moveStore?.stop}
          odd={index % 2 === 0}
        />
      )
    })
  }

  return (
    <div>
      <AppHeader title={t('public_transport')} />
      <AppContent>
        <div className={classes.content}>
          <h1 className={classes.h1}>{moveStore?.stop?.name}</h1>
          <div className={classes.subTitle}>{moveStore?.stop?.code}</div>
          {renderList()}
        </div>
      </AppContent>
    </div>
  )
}

export default observer(StopScreen)

import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import { t } from '../I18n/I18n'
import { SportMainEntityType, formatJanaFields, SportTypes, LeisureTypes, OtherTypes, getEntityCenterCoords } from '../Utils/jana'
import { compareItemDistances } from '../Utils/map'

export default class SportStore {
  rootStore

  sports = []
  sport = null
  savedFilters = []
  sportGuides = []
  entityLogs = []
  loading = false
  filteredSports = []

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setSports = (sports) => { this.sports = sports }
  setSport = (sport) => { this.sport = sport }
  setSavedFilters = (savedFilters) => { this.savedFilters = savedFilters }
  setSportGuides = (sportGuides) => { this.sportGuides = sportGuides }
  setEntityLogs = (logs) => { this.entityLogs = logs }
  setLoading = (loading) => { this.loading = loading }
  setFilteredSports = (filteredSports) => { this.filteredSports = filteredSports }

  async getSports() {
    this.setLoading(true)
    try {
      const locale = get(this.rootStore, 'appStore.locale')
      const response = await Api.getMainEntities(
        { mainEntityTypes: Object.values(SportMainEntityType) },
        locale
      )
      if (response.ok) {
        const sports = response.data.map((sport) => ({
          ...sport,
          fields: formatJanaFields(sport),
          subEntities: sport.subEntities.map((item) => ({
            ...item,
            fields: formatJanaFields(item)
          }))
        })).sort((a, b) => {
          return compareItemDistances(this.rootStore.appStore.location, a, b)
        })
        this.setSports(sports.reverse())
      } else {
        // showErrorMessage()
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getSport(id) {
    this.setLoading(true)
    try {
      const locale = get(this.rootStore, 'appStore.locale')
      const response = await Api.getMainEntity(id, locale)
      if (response.ok) {
        const sport = response.data
        this.setSport(sport)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getEntityLogs(id) {
    this.setLoading(true)
    try {
      this.setEntityLogs([])
      const response = await Api.getEntityMaintenanceLogs(id)
      if (response?.ok) {
        let logs = response?.data ?? []
        logs = orderBy(logs, 'publishedAt', 'desc')
        this.setEntityLogs(logs)
      } else {
        // showErrorMessage()
      }
    } catch (e) {

    }
    this.setLoading(false)
  }

  async getSportGuides() {
    this.setLoading(true)
    try {
      const locale = get(this.rootStore, 'appStore.locale')
      const response = await Api.getMainEntityInfos(
        { mainEntityTypes: Object.values(SportMainEntityType), lang: locale },
      )
      if (response.ok) {
        const guides = response.data ?? []
        this.setSportGuides(guides)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
